import moment from "moment";
import saveDeliverable from "./saveDeliverable";
import * as Yup from 'yup'
import { parseDate } from "../../helpers/dateformatter";

const {
    formField : {
        name,
        plannedStartDate,
        plannedDueDate,
        actualStartDate,
        actualDueDate
    }
} = saveDeliverable



const saveDeliverableValidator = Yup.object()
    .shape({
        [name.name] : Yup.string().required(name.requiredErrorMsg),
        [plannedStartDate.name] : Yup.string().nullable().optional(),
        [plannedDueDate.name] : Yup.string().required(plannedDueDate.requiredErrorMsg),
        [actualDueDate.name] : Yup.string().nullable().optional(),
        [actualStartDate.name] : Yup.string().nullable().optional()
    })


export default saveDeliverableValidator