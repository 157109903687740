
import { Box, Paper, Typography } from '@mui/material'
import { useState } from 'react'
const STRATEGIC = 'strategic'
const TACTICAL = 'tactical'
const OPERATIONAL = 'operational'

export const StrategicTaskResult = ({ strategic, onClick }) => {

    return (
        <>
            <Paper
                sx={{
                    padding : '2rem',
                    width : '100%',
                    display : 'flex',
                    flexDirection : 'column',
                    marginBottom : '0.5rem',
                    ":hover" : {
                        cursor : 'pointer'
                    },
                    // border : '1px solid #f44336'
                }} 
                elevation={5}
                onClick={() => onClick({ type : STRATEGIC, task : strategic })}>
                <Box sx={{
                    display : 'flex',
                    justifyContent : 'flex-start',
                    alignItems : 'center'
                }}>
                    <Typography variant='subtitle2' component='p' fontSize={'9px'} color={'#f44336'}>STRATEGIC</Typography>
                </Box>
                <Box>
                    <Typography variant='subtitle2' component={'p'}>{strategic.task.task_desc}</Typography>
                </Box>
            </Paper>
        </>
    )
}

export const TacticalTaskResult = ({ tactical, onClick }) => {

    return (
        <>
            <Paper
                sx={{
                    padding : '2rem',
                    width : '100%',
                    display : 'flex',
                    flexDirection : 'column',
                    marginBottom : '0.5rem',
                    ":hover" : {
                        cursor : 'pointer'
                    },
                    // border : '1px solid #11A0DB'
                }} 
                elevation={5}
                onClick={() => onClick({ type : TACTICAL, task : tactical })}>
                <Box sx={{
                    display : 'flex',
                    justifyContent : 'flex-start',
                    alignItems : 'center'
                }}>
                    <Typography variant='subtitle2' component='p' fontSize={'9px'} color={'#11A0DB'}>TACTICAL</Typography>
                </Box>
                <Box>
                    <Typography variant='subtitle2' component={'p'}>{tactical.task.task_desc}</Typography>
                </Box>
            </Paper>
        </>
    )
}

export const OperationalTaskResult = ({ operational, onClick }) => {

    return (
        <>
            <Paper
                sx={{
                    padding : '2rem',
                    width : '100%',
                    display : 'flex',
                    flexDirection : 'column',
                    marginBottom : '0.5rem',
                    ":hover" : {
                        cursor : 'pointer'
                    },
                    // border : '1px solid #67AD45'
                }} 
                elevation={5}
                onClick={() => onClick({ type : OPERATIONAL, task : operational })}>
                <Box sx={{
                    display : 'flex',
                    justifyContent : 'flex-start',
                    alignItems : 'center'
                }}>
                    <Typography variant='subtitle2' component='p' fontSize={'9px'} color={'#67AD45'}>OPERATIONAL</Typography>
                </Box>
                <Box>
                    <Typography variant='subtitle2' component={'p'}>{operational.task.task_desc}</Typography>
                </Box>
            </Paper>
        </>
    )
}