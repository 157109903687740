import { Avatar, Divider, ListItem, ListItemAvatar, ListItemText, Tooltip, Typography } from '@mui/material'
import React from 'react'
import { getEntity } from '../../helpers/notificationEntityTypeConverter'
import { useNavigate } from 'react-router-dom'
import operationalService from '../../services/operationalService'
import { useDispatch } from 'react-redux'
import { removeOneNotification } from '../../features/notification/notificationSlice'
import notificationService from '../../services/notificationService'

export const NotificationContent = ({ notification }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const currentStatus = notification.notification.status

    const [data, setData] = React.useState(null)

    React.useEffect(() => {
        operationalService.getOne({ id: notification.entity_id, dispatch})
            .then(res => {
                setData(res.data)
            })
        
    }, [])

  return (
    /* .-. -- -... Notifications Content*/
    <>
        <ListItem 
            alignItems="flex-start" 
            sx={{
                backgroundColor: currentStatus === 'SN' ? 'none' : 'var(--more-gray-darker)',
                // color: currentStatus === 'SN' ? 'none' : 'var(--blue)',
                marginBottom: '1px',
                outline: '1px solid var(--gray)',
                
                ":hover" : {
                    cursor : 'pointer'
                }}}
            onClick={()=> {
                // operationalService.getOne({ id: notification.entity_id, dispatch})
                // .then((res)=>{
                //     
                //     // dispatch()
                //     const clientID = res.data.tactical_task.strategic_task.delivery.client_info.id
                //     navigate(`client-details/${clientID}?operationalId=${notification.entity_id}`)
                    
                // })
                
                operationalService.getOne({ id: notification.entity_id, dispatch})
                .then((res)=>{
                    // console.log('response: ', res.data)
                    // dispatch()
                    notificationService.updateStatus({ notificationContentId : notification.id, status : "SN" })
                        .then((resData) => {
                            dispatch(removeOneNotification({ id : notification.id }))
                            const clientID = res.data.tactical_task.strategic_task.delivery.client_info.id
                            navigate(`client-details/${clientID}?operationalId=${notification.entity_id}`)
                            window.location.reload()
                        })
                })

                
            }}
        >
            <ListItemAvatar>
                <Avatar alt={String(notification.notification.sender.username).toUpperCase()} src="/static/images/avatar/1.jpg" />
            </ListItemAvatar>
            <ListItemText
            primary={<Tooltip title={data?.task_desc}><Typography noWrap>{data?.task_desc}</Typography></Tooltip>}
            secondary={
                <React.Fragment>
                <Typography
                    sx={{ display: 'inline' }}
                    component="span"
                    variant="body2"
                    color= {currentStatus === 'SN' ? 'none' : 'var(--blue)'}
                    fontWeight={currentStatus === 'SN' ? 'none' : 'bold'}
                >
                    {notification.notification.sender.username}
                    {` - ${notification.message}`}
                </Typography>
                </React.Fragment>
            }
            />
      </ListItem>
      {/* <Divider variant="inset" component="li" /> */}
    </>
  )
}
