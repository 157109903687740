import AWS from 'aws-sdk'
import { awsAccessKeyId, awsBucket, awsRegion, awsS3, awsSecretAccessKey } from '../constants/urls'


AWS.config.update({
    accessKeyId  : awsAccessKeyId,
    secretAccessKey : awsSecretAccessKey,
    region : awsRegion
})

const s3Instance = new AWS.S3()

const uploadToS3 = async (file) => {
    try {
        const res = await s3Instance.upload({
            Bucket : awsBucket,
            Key : `${awsS3}/${file.name}`,
            Body : file,
            ContentType : file.type,
            ACL : 'public-read'
        }).promise()

        return res;
    } catch (error) {
        throw new Error('Something went wrong')
    }
}
const uploadToS3V2 = async (file, dir) => {
    try {
        const res = await s3Instance.upload({
            Bucket : awsBucket,
            Key : `${awsS3}/${dir}/${file.name}`,
            Body : file,
            ContentType : file.type,
            ACL : 'public-read'
        }).promise()

        return res;
    } catch (error) {
        throw new Error('Something went wrong')
    }
}

const reuploadToS3 = async (newfile, file, dir) => {
    try {
        const res = await s3Instance.upload({
            Bucket : awsBucket,
            Key : `${awsS3}/${dir}/${newfile}`,
            Body : file,
            ContentType : file.type,
            ACL : 'public-read'
        }).promise()

        return res;
    } catch (error) {
        throw new Error('Something went wrong')
    }
}

const checkFileExists = async (path) => {
    try {
        // const res = await s3Instance.headObject({
        //     Bucket : awsBucket,
        //     Key : `${awsS3}/${path}`
        // }).promise()
        // console.log('res, ', res)
        const res = await s3Instance.getObject({ Bucket : awsBucket, Key : `${awsS3}/${path}` }).promise()
        console.log(res)
        return true
    } catch (error) {
        console.log(error)
        return false
    }
}

const fileUploadService = {
    uploadToS3,
    uploadToS3V2,
    checkFileExists,
    reuploadToS3
}

export default fileUploadService

