export const clients = [
  {
      name : 'PCB'
  },
  {
      name : 'Selina'
  },
  {
      name : 'Zoominfo'
  }
]



export const strategicTasks = [
    {
        id : 1,
        taskDesc : 'Map all security controls',
        planDueDate : new Date().toLocaleString(),
        planStartDate : new Date().toLocaleString(),
        actualDueDate : new Date().toLocaleString(),
        actualStartDate : new Date().toLocaleString(),
        late : true,
        status : 10
    },
    {
        id : 2,
        taskDesc : 'Upgrade security level',
        planDueDate : new Date().toLocaleString(),
        planStartDate : new Date().toLocaleString(),
        actualDueDate : new Date().toLocaleString(),
        actualStartDate : new Date().toLocaleString(),
        late : false,
        status : 10
    },
    {
        id : 3,
        taskDesc : 'Secured and control information',
        planDueDate : new Date().toLocaleString(),
        planStartDate : new Date().toLocaleString(),
        actualDueDate : new Date().toLocaleString(),
        actualStartDate : new Date().toLocaleString(),
        late : false,
        status : 10
    },
    {
        id : 4,
        taskDesc : 'Encrypt client information for security purposes',
        planDueDate : new Date().toLocaleString(),
        planStartDate : new Date().toLocaleString(),
        actualDueDate : new Date().toLocaleString(),
        actualStartDate : new Date().toLocaleString(),
        late : false,
        status : 10
    },
    {
        id : 5,
        taskDesc : 'Decrypt and deliver client details',
        planDueDate : new Date().toLocaleString(),
        planStartDate : new Date().toLocaleString(),
        actualDueDate : new Date().toLocaleString(),
        actualStartDate : new Date().toLocaleString(),
        late : true,
        status : 10
    },
    {
        id : 6,
        taskDesc : 'Use SHA256 algorithm for user data encryption',
        planDueDate : new Date().toLocaleString(),
        planStartDate : new Date().toLocaleString(),
        actualDueDate : new Date().toLocaleString(),
        actualStartDate : new Date().toLocaleString(),
        late : false,
        status : 10
    },
    {
        id : 7,
        taskDesc : 'Deliver client data',
        planDueDate : new Date().toLocaleString(),
        planStartDate : new Date().toLocaleString(),
        actualDueDate : new Date().toLocaleString(),
        actualStartDate : new Date().toLocaleString(),
        late : true,
        status : 10
    },
    {
        id : 8,
        taskDesc : 'Decrypt and deliver client details',
        planDueDate : new Date().toLocaleString(),
        planStartDate : new Date().toLocaleString(),
        actualDueDate : new Date().toLocaleString(),
        actualStartDate : new Date().toLocaleString(),
        late : true,
        status : 10
    },
    {
        id : 9,
        taskDesc : 'Use SHA256 algorithm for user data encryption',
        planDueDate : new Date().toLocaleString(),
        planStartDate : new Date().toLocaleString(),
        actualDueDate : new Date().toLocaleString(),
        actualStartDate : new Date().toLocaleString(),
        late : true,
        status : 10
    },
    {
        id : 10,
        taskDesc : 'Deliver client data',
        planDueDate : new Date().toLocaleString(),
        planStartDate : new Date().toLocaleString(),
        actualDueDate : new Date().toLocaleString(),
        actualStartDate : new Date().toLocaleString(),
        late : false,
        status : 10
    }
]


export const tacticalTasks = [
    {
        id : 1,
        taskDesc : 'Map all security controls from tactical task',
        planDueDate : new Date().toLocaleString(),
        planStartDate : new Date().toLocaleString(),
        actualDueDate : new Date().toLocaleString(),
        actualStartDate : new Date().toLocaleString(),
    },
    {
        id : 2,
        taskDesc : 'Upgrade security level',
        planDueDate : new Date().toLocaleString(),
        planStartDate : new Date().toLocaleString(),
        actualDueDate : new Date().toLocaleString(),
        actualStartDate : new Date().toLocaleString(),
    },
    {
        id : 3,
        taskDesc : 'Secured and control information',
        planDueDate : new Date().toLocaleString(),
        planStartDate : new Date().toLocaleString(),
        actualDueDate : new Date().toLocaleString(),
        actualStartDate : new Date().toLocaleString(),
    },
    {
        id : 4,
        taskDesc : 'Encrypt client information for security purposes',
        planDueDate : new Date().toLocaleString(),
        planStartDate : new Date().toLocaleString(),
        actualDueDate : new Date().toLocaleString(),
        actualStartDate : new Date().toLocaleString(),
    },
    {
        id : 5,
        taskDesc : 'Decrypt and deliver client details',
        planDueDate : new Date().toLocaleString(),
        planStartDate : new Date().toLocaleString(),
        actualDueDate : new Date().toLocaleString(),
        actualStartDate : new Date().toLocaleString(),
    },
    {
        id : 6,
        taskDesc : 'Use SHA256 algorithm for user data encryption',
        planDueDate : new Date().toLocaleString(),
        planStartDate : new Date().toLocaleString(),
        actualDueDate : new Date().toLocaleString(),
        actualStartDate : new Date().toLocaleString(),
    },
    {
        id : 7,
        taskDesc : 'Deliver client data',
        planDueDate : new Date().toLocaleString(),
        planStartDate : new Date().toLocaleString(),
        actualDueDate : new Date().toLocaleString(),
        actualStartDate : new Date().toLocaleString(),
    }
]

export const deliverables = [
    {
        description : 'Control Mapping for Prevention, Detection, Response, and Recovery',
        dueBy : 'June 1, 2023'
    },
    {
        description : 'Quarterly Penetration Test, Vulnerabilty, Assessments, Ransomware Simulation',
        dueBy : 'Septemeber 1, 2023'
    },
    {
        description : 'Risk Scenario Implementation and Updates to the CSOC and Monitoring Cyber Incidents',
        dueBy : 'June 1, 2023'
    },
    {
        description : 'NIST 800-171 Compliance',
        dueBy : 'Decemeber 31, 2023'
    },
    {
        description : 'CIRP and CIRT, Quarterly Dry Drills',
        dueBy : 'Septemeber 1, 2023'
    },
    {
        description : 'Network Segmention for EOL Systems',
        dueBy : 'Septemeber 1, 2023'
    },
    {
        description : 'Ongoing CISO Support',
        dueBy : 'Decemeber 31, 2023'
    },
    {
        description : 'CIRP and CIRT, Quarterly Dry Drills',
        dueBy : 'Septemeber 1, 2023'
    },
    {
        description : 'Information Security Steering Committee',
        dueBy : 'June 1, 2023'
    }
]