export default {
    formId : 'saveOperational',
    formField : {
        name : {
            name : 'name',
            label : 'Name',
            requiredErrorMsg : 'Deliverable name is required'
        },
        plannedStartDate : {
            name : 'plannedStartDate',
            label : 'Plan Start Date',
        },
        plannedDueDate : {
            name : 'plannedDueDate',
            label : 'Plan Due Date',
            requiredErrorMsg : 'Plan due date is required'
        },
        actualStartDate : {
            name : 'actualStartDate',
            label : 'Actual Due Date',
        },
        actualDueDate : {
            name : 'actualDuetDate',
            label : 'Actual Due Date',
        },
        taskStatus : {
            name : 'taskStatus',
            label : 'Task Status',
        }
    }
}