import { Delete, MoreVert } from '@mui/icons-material'
import { Avatar, Divider, IconButton, ListItem, ListItemAvatar, ListItemText, Menu, MenuItem, Stack, Typography } from '@mui/material'
import React from 'react'
import { format } from '../../helpers/commentFormatter'
import moment from 'moment'

export const Comment = ({ comment, onDelete }) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  return (
    <>
        <ListItem alignItems="flex-start">
            <ListItemAvatar>
                <Avatar/>
            </ListItemAvatar>
            <ListItemText
                primary={
                  <Stack sx={{
                    display : 'flex',
                    flexDirection : 'row',
                    justifyContent : 'space-between',
                    alignItems : 'center'
                  }}>
                    <span style={{ display : 'flex', alignItems : 'center' }}>{(comment.user ? `${comment.user.first_name} ${comment.user.last_name}` : 'Unknown')} <span style={{ fontSize : '11px', marginLeft : '5px', marginRight : '5px' }}>•</span> <small style={{ fontWeight : '100', fontSize : '8px' }}>{moment(String(comment.createdAt)).format('LLL')}</small></span>
                    <IconButton size='small' onClick={(event) => setAnchorEl(event.currentTarget)}>
                      <MoreVert fontSize='small'/>
                    </IconButton>
                    <Menu
                        sx={{
                          zIndex : '99999'
                        }}
                        onClose={() => setAnchorEl(null)}
                        anchorEl={anchorEl}
                        open={open}>
                        <MenuItem focusRipple onClick={() => onDelete(comment)}>
                          <Delete fontSize='small'/>
                          Delete
                        </MenuItem>
                    </Menu>
                  </Stack>
                }
                secondary={
                    <React.Fragment>
                    {format(comment?.text)}
                    </React.Fragment>
            }
            />
        </ListItem>
        <Divider variant="inset" component="li" />
    </>
  )
}
