import saveDeliverable from "./saveDeliverable";

const {
    formField : {
        name,
        plannedStartDate,
        plannedDueDate,
        actualStartDate,
        actualDueDate
    }
} = saveDeliverable


export default {
    [name.name] : '',
    [plannedStartDate.name] : '',
    [plannedDueDate.name] : '',
    [actualStartDate.name] : '',
    [actualDueDate.name] : ''
}