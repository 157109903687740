
import { Routes, Route, Navigate } from  'react-router-dom'
import { AuthorizedPageIndex } from './pages/indexes/AuthorizedPageIndex';
import { NonAuthorizedIndex } from './pages/indexes/NonAuthorizedIndex'
import { Dashboard } from './pages/dashboard/Dashboard';
import { useState } from 'react';
import { EntryPoint } from './pages/dashboard/subs/EntryPoint';
import { ClientDetails } from './pages/dashboard/subs/ClientDetails';
import { ClientDetails as ClientDetailsPage } from './pages/clientdetails/ClientDetails'
import { Login } from './pages/auth/Login';
import { useSelector } from 'react-redux';
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { Assignedme } from './pages/assignee/Assignedme';
function App() {

  const { isAuthorized, payload } = useSelector(state => state.auth)

  // const token = typeof payload === 'string' ? JSON.parse(payload)?.access_token : payload?.access_token
  
  // if(typeof token !== 'undefined'){
    
  //   console.log('Time EXP', (new Date(jwtDecode(token).exp * 1000).getTime() - new Date().getTime()))
  //   setInterval(() => {
  //     console.log('Expired')
  //     localStorage.clear()
  //     window.location.reload()

  //   }, (new Date(jwtDecode(token).exp * 1000).getTime() - new Date().getTime()))

  // }
  return <>
    <Routes>
      
      {isAuthorized ? 
        <Route path='/' element={<AuthorizedPageIndex/>}>
          <Route path='*' element={<Navigate to={'/'}/>}/>
          <Route path='/' element={<Dashboard/>}>
            <Route path='/' element={<EntryPoint/>}/>
            {/* <Route path='client-details/:id' element={<ClientDetails/>}/> */}
          </Route>
          <Route path='client-details/:id' element={<ClientDetailsPage/>}/>
          <Route path='assigned-me' element={<Assignedme/>}/>
        </Route> : 
        <Route path='/' element={<NonAuthorizedIndex/>}>
          <Route path='/' element={<Navigate to={'login'}/>}/>
          <Route path='*' element={<Navigate to={'login'}/>}/>
          <Route path='login' element={<Login/>}/>
        </Route>
      }
    </Routes>
    <ToastContainer 
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
  </>
}

export default App;
