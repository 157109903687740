import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import clientInformationService from "../../services/clientInformationService";



const initialState = {
    isLoading : false,
    isSuccess  : false,
    isError : false,
    clients : [],
    client : null,
    response : null,
    showForm : false,
    showAlert : false,
    message : ''
}

export const getClient = createAsyncThunk(
    'clientInformation/get-one',
    async (params, thunk) => {
        try {
            const res = await clientInformationService.getOne({ id : params['id'], dispatch : thunk.dispatch })
            return res.data
        } catch (error) {
            return thunk.rejectWithValue(error.response.data)
        }
    }
)

export const updateClient = createAsyncThunk(
    'clientInformation/update',
    async (params, thunk) => {
        try {
            const res = await clientInformationService.update({ clientId : params.clientData.id, updatedData : params.clientData, dispatch : thunk.dispatch })
            return res.data
        } catch (error) {
            return thunk.rejectWithValue(error.response.data)
        }
    }
)

const clientInformationSlice = createSlice({
    name : 'clientInformation',
    initialState,
    reducers : {
        showFormToggle : (state) => {
            state.showForm = !state.showForm
        },
        showForm : (state) => {
            state.showForm = true
        },
        resetFormState : (state) => {
            state.showForm = false
        },
        showAlert : (state) => {
            state.showAlert = true
        },
        resetAlertState : (state) => {
            state.showAlert = false
        },
        resetClientState : (state) => {
            state.client = null
        },
        setClients : (state, action) => {
            state.clients = action.payload
        }
    },
    extraReducers : (builder) => {
        builder.addCase(getClient.pending, (state) => {
            state.client = null
            state.isError = false
            state.isLoading = true
            state.isSuccess = false
            state.response = null
        }).addCase(getClient.fulfilled, (state, action) => {
            state.client = action.payload
            state.isError = false
            state.isLoading = false
            state.isSuccess = true
        }).addCase(getClient.rejected, (state) => {
            state.isError = true
            state.isLoading = false
            state.isSuccess = false
        }).addCase(updateClient.pending, (state) => {
            state.isError = false
            // state.isLoading = true
            state.isSuccess = false
            state.response = null
            
        }).addCase(updateClient.fulfilled, (state, action) => {
            state.client = action.payload
            state.isError = false
            // state.isLoading = false
            state.isSuccess = true
            state.showForm = false
            state.showAlert = true
            console.log('Error', action.payload)
        }).addCase(updateClient.rejected, (state, action) => {
            state.client = null
            state.isError = true
            // state.isLoading = false
            state.message = action.payload
            state.isSuccess = false
            state.showForm = true
            state.showAlert = true
            console.log('Error', action.payload)
        })
    }
})

export const { resetAlertState, resetFormState, showAlert, showForm, showFormToggle, resetClientState, setClients } = clientInformationSlice.actions
export default clientInformationSlice.reducer
