import { Box, Chip, Divider, Typography } from '@mui/material'
import EventNoteIcon from '@mui/icons-material/EventNote';
import React from 'react'
import moment from 'moment';
import { getTaskStatus } from '../../helpers/taskStatusHelper';

// task property can be Strategic, Tactical and Operational task
export const TaskDetails = ({ task, title, backgroundColor = '#67AD45' }) => {
  return (
    <>
        <Box sx={{
            display : 'flex',
            flexDirection : 'column',
            alignItems : 'flex-start',
            padding : '2rem',
            backgroundColor : backgroundColor
        }}>
            <Box sx={{
                display : 'flex',
                justifyContent : 'space-between',
                alignItems : 'flex-end',
                width : '100%'
            }}>
                <Box sx={{
                    display : 'flex',
                    flexDirection : 'column',
                }}>
                    <Typography variant='subtitle1' component={'small'} fontSize={'13px'} color={'GrayText'}>{String(title).toLocaleUpperCase()}</Typography>
                    <Typography variant='subtitle2' component={'span'} fontSize={'15px'}>{task?.task_desc}</Typography>
                </Box>
                <Chip label={getTaskStatus(task?.task_status)}/>
            </Box>
            <Divider sx={{ width : '100%', marginTop : '1rem' }}/>
            <Box sx={{
                display : 'flex',
                flexDirection : 'column',
                alignItems : 'flex-start',
                marginTop : '2rem',
                width : '100%'
            }}>
                <Box sx={{
                    display : 'flex',
                    alignItems : 'flex-start',
                    width : '80%',
                    justifyContent : 'space-between'
                }}>
                    <Box sx={{
                        display : 'flex',
                        flexDirection : 'column',
                        alignItems : 'flex-start',
                        width : '100%'
                    }}>
                        <Box sx={{
                            display : 'flex',
                            alignItems : 'center'
                        }}>
                            <EventNoteIcon fontSize='small' sx={{
                                marginRight : '2px',
                                color : 'gray'
                            }}/>
                            <Typography variant='subtitle1' component={'small'} fontSize={'15px'} color={'GrayText'}>Planned Start Date</Typography>
                        </Box>
                        <Typography>{moment(task?.planned_start_date).format('DD-MM-YYYY') === '01-01-1900' ? '-' : moment(task?.planned_start_date).format('DD-MM-YYYY')}</Typography>
                    </Box>

                    <Box sx={{
                        display : 'flex',
                        flexDirection : 'column',
                        alignItems : 'flex-start',
                        width : '100%'
                    }}>
                        <Box sx={{
                            display : 'flex',
                            alignItems : 'center'
                        }}>
                            <EventNoteIcon fontSize='small' sx={{
                                marginRight : '2px',
                                color : 'gray'
                            }}/>
                            <Typography variant='subtitle1' component={'small'} fontSize={'15px'} color={'GrayText'}>Planned Due Date</Typography>
                        </Box>
                        <Typography>{moment(task?.planned_due_date).format('DD-MM-YYYY')}</Typography>
                    </Box>
                </Box>

                <Box sx={{
                    display : 'flex',
                    alignItems : 'flex-start',
                    width : '80%',
                    justifyContent : 'space-between',
                    marginTop : '1rem'
                }}>
                    <Box sx={{
                        display : 'flex',
                        flexDirection : 'column',
                        alignItems : 'flex-start',
                        width : '100%'
                    }}>
                        <Box sx={{
                            display : 'flex',
                            alignItems : 'center'
                        }}>
                            <EventNoteIcon fontSize='small' sx={{
                                marginRight : '2px',
                                color : 'gray'
                            }}/>
                            <Typography variant='subtitle1' component={'small'} fontSize={'15px'} color={'GrayText'}>Actual Start Date</Typography>
                        </Box>
                        <Typography>{moment(task?.actual_start_date).format('DD-MM-YYYY') === '01-01-1900' ? '-' : moment(task?.actual_start_date).format('DD-MM-YYYY')}</Typography>
                    </Box>

                    <Box sx={{
                        display : 'flex',
                        flexDirection : 'column',
                        alignItems : 'flex-start',
                        width : '100%'
                    }}>
                        <Box sx={{
                            display : 'flex',
                            alignItems : 'center'
                        }}>
                            <EventNoteIcon fontSize='small' sx={{
                                marginRight : '2px',
                                color : 'gray'
                            }}/>
                            <Typography variant='subtitle1' component={'small'} fontSize={'15px'} color={'GrayText'}>Actual Due Date</Typography>
                        </Box>
                        <Typography>{moment(task?.actual_due_date).format('DD-MM-YYYY') === '01-01-1900' ? '-' : moment(task?.actual_due_date).format('DD-MM-YYYY')}</Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    </>
  )
}
