import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { Navbar } from '../../components/common/Navbar'
import { useDispatch, useSelector } from 'react-redux'
import { getAllNotifications } from '../../features/notification/notificationSlice'
export const AuthorizedPageIndex = () => {

  const { payload } = useSelector(state => state.auth)
  const stakeholderId = ((typeof payload) == 'string') ? JSON.parse(payload).id : payload.id
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getAllNotifications({ stakeholderId }))
  }, [])
  return <>
    <Navbar/>
    <Outlet/>
  </>
}
