import { Box, Divider, IconButton, Tooltip, Typography } from '@mui/material'
import AttachmentIcon from '@mui/icons-material/Attachment';
import React from 'react'
import { Link } from 'react-router-dom';
import { ellipsis } from '../../helpers/stringTransform';
import { Delete } from '@mui/icons-material';

export const Attachment = ( { url = 'https://images.freeimages.com/images/large-previews/499/file-1239786.jpg' , desc = "Description test", id, onDelete }) => {
  return (
    <>
        <Box sx={{
            display : 'flex',
            justifyContent : 'flex-start',
            alignItems : 'flex-start',
            marginY : '3px'
        }}>
            <AttachmentIcon color='action' fontSize='small'/>
            <Box>
                
                <Link to={url} target='_blank'>
                    <Typography variant='subtitle2' component={'span'} sx={{ 
                        marginLeft : '2px', 
                        color : 'white'}}>
                        {ellipsis(70, url)}
                    </Typography>
                </Link>
                <Typography fontSize={'13px'}>{desc}</Typography>
                
            </Box>
            <IconButton 
                onClick={() => {onDelete(id)}}
                color='error' sx={{
                    marginX : '8px'
                }}>
                <Delete fontSize='small'/>
            </IconButton>
        </Box>
        
        <Divider/>
    </>
  )
}
