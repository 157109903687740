import httpClient from "../config/axiosConfig"


const fetchAllProjectWorking = async () => {
    try {
        const res = await httpClient.get("projectworking/")
        return res
    } catch (error) {
        return error
    }
}


const projectWorkingService = {
    fetchAllProjectWorking
}

export default projectWorkingService