const CYTECH_ADMIN = 'CyTech Admin'
const CYTECH_USER = 'CyTech User'
const CLIENT_USER = 'Client User'
const CLIENT_ADMIN = 'Client Admin'


export const userRole = {
    CYTECH_ADMIN,
    CYTECH_USER,
    CLIENT_USER,
    CLIENT_ADMIN
}