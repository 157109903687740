import { Box, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs';
import { useField, useFormikContext } from 'formik'
import { at } from 'lodash';
import React from 'react'

export const DatePickerField = (props) => {
    const { setFieldValue } = useFormikContext()
    const [field, meta] = useField(props)

    const renderHelperText = () => {
        const [touched, error] = at(meta, 'touched', 'error')
        if(touched && error){
            return error;
        }
    }
  return (
    <Box width={'100%'} sx={{ display : 'flex', flexDirection : 'column', alignItems : 'flex-start' }}>
        <DatePicker 
          {...field} 
          {...props} 
          value={(field.value && dayjs(new Date(field.value).toString())) || null}
          format='YYYY-MM-DD'
          sx={{ width : '100%' }} 
          label={props.label} 
          selected={(field.value && new Date(field)) || null} 
          onChange={(val) => setFieldValue(field.name, val)}/>
        <Typography sx={{ margin : '1.6px', marginLeft : '1rem' }} variant='subtitle2' component={'small'} color='error' fontSize={'12px'}>{renderHelperText()}</Typography>
    </Box>
  )
}
