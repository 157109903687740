import React, { useState, useEffect } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import Paper from '@mui/material/Paper';
import { Tooltip, Box, Grid, CircularProgress, IconButton, Stack, Typography } from '@mui/material';
import { OverallStatus } from './OverallStatus';
import tasksService from '../../services/tasksService';
import { DataGrid } from '@mui/x-data-grid';
import { useLocation, useNavigate } from 'react-router-dom';
import { userRole } from '../../constants/userRole';
import { useDispatch, useSelector } from 'react-redux';
import STATE_STATUS from '../../constants/status';
import { fetchTasksApproaching, fetchTasksOverdue } from '../../features/dashboard/dashboardSlice';
import queryParamHelper from '../../helpers/queryParamHelper';
import HelpIcon from '@mui/icons-material/Help';


const approachingColumns = [
    {
      field: 'project_name',
      headerName: 'Project',
      width: 220,
      editable: true,
    },
    {
      field: 'task_desc',
      headerName: 'Task',
      type: 'string',
      width: 220,
      editable: true,
    },
  ];

const tasksOverdueColumns = [
    {
        field: 'project_name',
        headerName: 'Project',
        width: 220,
        editable: true,
    },
    {
        field: 'task_desc',
        headerName: 'Task',
        type: 'string',
        width: 220,
        editable: true,
    }
];

export const ApproachingAndOverdueTask = ({ onClick }) => {

    const { approachingTasks, tasksApproachingStatus, overdueTasks, tasksOverdueStatus } = useSelector(state => state.dashboard)
    const dispatch = useDispatch()
    // const [approachingTasksIsLoading, setApproachingTasksIsLoading] = useState(false)
    // const [approachingTasks, setApproachingTasks] = useState([])

    // const [overdueTasksIsLoading, setOverdueTasksIsLoading] = useState(false)
    // const [overdueTasks, setOverdueTasks] = useState([])
    const location = useLocation()
    const query = queryParamHelper.getQueryParam('selectedClient', location.search)
    const navigate = useNavigate()

    useEffect(() => {
        loadApproachingTasks()
        loadTasksOverdue()
    }, [])

    const loadApproachingTasks = () => {
        // setApproachingTasksIsLoading(true)
        const user = JSON.parse(localStorage.getItem('auth'))

        let clientId = (user.role_name == userRole.CLIENT_USER || user.role_name == userRole.CLIENT_ADMIN) ? user.client : ''

        if(query) {
            clientId = query.value
        }

        dispatch(fetchTasksApproaching({ clientId }))
    }

    const loadTasksOverdue = () => {
        // setOverdueTasksIsLoading(true)
        const user = JSON.parse(localStorage.getItem('auth'))

        let clientId = (user.role_name == userRole.CLIENT_USER || user.role_name == userRole.CLIENT_ADMIN) ? user.client : ''

        if(query) {
            clientId = query.value
        }
        dispatch(fetchTasksOverdue({ clientId }))
    }

  return (
    <Box display={'flex'} width={'100%'} sx={{
        marginBottom : '2rem'
    }}>
        <Paper sx={{
            display : 'flex',
            flexDirection : 'column',
            alignItems : 'flex-start',
            width : '100%',
            height : '50%',
            padding : '2rem',
            minHeight : '40vh',
            borderRadius : '1rem',
        }}>
            <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} width={'100%'}>
                {/* .-. -- -... Tooltip Task Approaching*/}
                <Grid container alignItems="center" justifyContent="flex-start" sx={{paddingLeft: '5px'}}>
                    <Grid item>
                        <Typography variant={'p'} color={'#67AD45'}>Task Approaching (+14 days)</Typography>
                    </Grid>
                    <Grid item>
                        <Tooltip
                        title="Highlights the upcoming deadlines to allow stakeholders to proactively manage the workload by identifying tasks that require immediate attention."
                        placement="right-start"
                        >
                        <HelpIcon size="small" fontSize="small" sx={{paddingLeft: '5px', color: 'var(--tooltip-bg-color)'}}/>
                        </Tooltip>
                    </Grid>
                </Grid>
                <IconButton size='small' onClick={() => {
                    onClick(approachingTasks, 'approaching')
                }}>
                    <OpenInFullIcon fontSize='small'/>
                </IconButton>
            </Stack>
            {tasksApproachingStatus === STATE_STATUS.LOADING ? <>
                <Box sx={{ display : 'flex', justifyContent : 'center', alignItems : 'center', width : '100%', paddingTop : '3rem' }}>
                    <CircularProgress color='info' size={25}/>
                </Box>
            </> : <>
                <DataGrid 
                    rows={approachingTasks.map((task) => ({ id : task.id, task_desc : task.task_desc, project_name : task.tactical_task.strategic_task.delivery.client_info.name, clientId : task.tactical_task.strategic_task.delivery.client_info.id }))}
                    columns={approachingColumns}
                    autoHeight={true}
                    initialState={{
                        pagination : {
                            paginationModel : {
                                pageSize : 3
                            }
                        }
                    }}
                    pageSizeOptions={[3]}
                    disableRowSelectionOnClick
                    isCellEditable={(params) => false}
                    sx={{
                        width : '100%',
                        marginTop : '1rem',
                    }}
                    onCellClick={(event) => {
                        navigate(`client-details/${event.row.clientId}?operationalId=${event.row.id}`)
                    }}
                />
            </>}
            
        </Paper>
        <Box width={'100%'} marginRight={'1rem'} marginLeft={'1rem'}>
            <OverallStatus/>
        </Box>
        <Box display={'flex'} flexDirection={'column'} alignItems={'flex-start'} width={'100%'}>
            <Paper sx={{
                display : 'flex',
                flexDirection : 'column',
                alignItems : 'flex-start',
                width : '100%',
                height : '50%',
                padding : '2rem',
                minHeight : '40vh',
                borderRadius : '1rem'
            }}>
                <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} width={'100%'}>
                {/* .-. -- -... Tooltip Task Overdue */}
                <Grid container alignItems="center" justifyContent="flex-start" sx={{paddingLeft: '5px'}}>
                    <Grid item>
                        <Typography variant={'p'} color={'#f44336'}>Task Overdue</Typography>
                    </Grid>
                    <Grid item>
                        <Tooltip
                        title="Tasks that were supposed to be completed by a specific date and allow stakeholders to assess whether these tasks were completed on time or if there are any delays."
                        placement="right-start"
                        >
                        <HelpIcon size="small" fontSize="small" sx={{paddingLeft: '5px', color: 'var(--tooltip-bg-color)'}}/>
                        </Tooltip>
                    </Grid>
                </Grid>
                    <IconButton size='small' onClick={() => {
                        onClick(overdueTasks, 'overdue')
                    }}>
                        <OpenInFullIcon fontSize='small'/>
                    </IconButton>
                </Stack>
                {tasksOverdueStatus === STATE_STATUS.LOADING ? <>
                    <Box sx={{ display : 'flex', justifyContent : 'center', alignItems : 'center', width : '100%', paddingTop : '3rem' }}>
                        <CircularProgress color='warning' size={25}/>
                    </Box>
                </> : <>
                    <DataGrid 
                        rows={overdueTasks.map((task) => ({ id : task.id, task_desc : task.task_desc, project_name : task.tactical_task.strategic_task.delivery.client_info.name, risk : 'Test', clientId : task.tactical_task.strategic_task.delivery.client_info.id }))}
                        columns={tasksOverdueColumns}
                        autoHeight={true}
                        initialState={{
                            pagination : {
                                paginationModel : {
                                    pageSize : 3
                                }
                            }
                        }}
                        pageSizeOptions={[3]}
                        disableRowSelectionOnClick
                        isCellEditable={(params) => false}
                        sx={{
                            width : '100%',
                            marginTop : '1rem',
                        }}
                        onCellClick={(event) => {
                           /*  console.log(event) */
                            navigate(`client-details/${event.row.clientId}?operationalId=${event.row.id}`)
                        }}
                    />
                </>}
                
            </Paper>
        </Box>
    </Box>
  )
}
