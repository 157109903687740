import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import WatchLaterTwoToneIcon from '@mui/icons-material/WatchLaterTwoTone';
import WatchLaterOutlinedIcon from '@mui/icons-material/WatchLaterOutlined';
import tacticalService from '../../../services/tacticalService'
import operationalService from '../../../services/operationalService'
import { CircularProgress, Drawer, Grid, LinearProgress, ListItemIcon, ListItemText, Menu, MenuItem, Modal, Tooltip , Button, Dialog, DialogContent, DialogTitle, } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react'
import { alterStrategic, createStrategic, getAllStrategicsByDelivery, setIsSubmitting, updateStrategic } from '../../../features/strategic/strategicSlice';
import CommentIcon from '@mui/icons-material/Comment';
import moment from 'moment';
import { TaskDrawer } from '../../task/TaskDrawer';
import { TaskDetails } from '../../task/TaskDetails';
import { getOperational, setOperationalIsSubmitting } from '../../../features/operational/operationalSlice';
import { CommentsAndAttachmentsTab } from '../../task/CommentsAndAttachmentsTab';
import { createOperationalComment, getAllOperationalComments } from '../../../features/operational-task-comment/operationalTaskCommentSlice';
import taskAttachmentService from '../../../services/taskAttachmentService';
import { userRole } from '../../../constants/userRole';
import { getTaskStatus } from '../../../helpers/taskStatusHelper';
import { LinearProgressWithLabel } from '../../common/LinearProgressWithLabel';
import tacticaltaskCommentService from '../../../services/tacticaltaskCommentService';
import STATE_STATUS from '../../../constants/status';
import strategictaskCommentService from '../../../services/strategictaskCommentService';
import operationalCommentService from '../../../services/operationalCommentService';
import { Edit, MoreVert } from '@mui/icons-material';
import { TaskformWrapper } from '../../wrappers/TaskformWrapper';
import { SaveStrategicForm } from './forms/SaveStrategicForm';
import strategicService from '../../../services/strategicService';
import { SaveTacticalForm } from './forms/SaveTacticalForm';
import { alterTactical, createTactical, setTacticalIsSubmitting } from '../../../features/tactical/tacticalSlice';
import { SaveOperationalForm } from './forms/SaveOperationalForm';
import { update } from 'lodash';
import { alterDeliverable } from '../../../features/deliverable/deliverableSlice';
/* Import */
import AddIcon from '@mui/icons-material/Add';

import { SaveDeliverableForm } from './forms/SaveDeliverableForm';
import { createClientDeliverable } from '../../../features/deliverable/deliverableSlice';

function createData(name, calories, fat, carbs, protein, price) {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
    price,
    history: [
      {
        date: '2020-01-05',
        customerId: '11091700',
        amount: 3,
      },
      {
        date: '2020-01-02',
        customerId: 'Anonymous',
        amount: 1,
      },
    ],
  };
}

function OperationalRow( { operational, update } ){

  const [ancholEl, setAnchorEl] = React.useState(null)
  const open = Boolean(ancholEl)
  const [showForm, setShowForm] = React.useState(false)
  const [openDrawer, setOpenDrawer] = React.useState(false)
  const { isLoading, operational : operationalState } = useSelector(state => state.operational)
  const { status, comments } = useSelector(state => state.operationalCommentSlice)

  const [attachments, setAttachments] = React.useState([])
  const [isOnAttaching, setIsOnAttaching] = React.useState(false)
  // console.log(comments)

  const dispatch = useDispatch()
    
    return (
        <>
          <TableRow>
            <TableCell component="th" scope="row">
                { operational.task_desc }
            </TableCell>
            <TableCell>
              <LinearProgressWithLabel value={Number(operational.progress)}/>
            </TableCell>
            <TableCell component="th" scope="row">
                { getTaskStatus(operational.task_status) }
            </TableCell>
            <TableCell component="th" scope="row">
                { moment(operational.actual_start_date).format('YYYY-MM-DD') === "1900-01-01" ? "-" : moment(operational.actual_start_date).format('YYYY-MM-DD') }
            </TableCell>
            <TableCell component="th" scope="row">
                { moment(operational.actual_due_date).format('YYYY-MM-DD') === "1900-01-01" ? "-" : moment(operational.actual_due_date).format('YYYY-MM-DD') }
            </TableCell>
            <TableCell component="th" scope="row">
                { moment(operational.planned_start_date).format('YYYY-MM-DD') === "1900-01-01" ? "-" : moment(operational.planned_start_date).format('YYYY-MM-DD') }
            </TableCell>
            <TableCell component="th" scope="row">
                { moment(operational.planned_due_date).format('YYYY-MM-DD') }
            </TableCell>
            <TableCell component="th" scope="row">
              <IconButton size='small' onClick={(e) => setAnchorEl(e.currentTarget)}>
                <MoreVert fontSize='small'/>
              </IconButton>
              <Menu anchorEl={ancholEl} open={open} onClose={() => setAnchorEl(null)}>
                <MenuItem onClick={() => {
                  setAnchorEl(null)
                  setOpenDrawer(!openDrawer)
                  dispatch(getOperational({ id : operational.id }))
                  dispatch(getAllOperationalComments({ operationalId : operational.id }))

                  taskAttachmentService.getAllOperationalAttachments({ operationalId : operational.id })
                    .then((res) => {
                      setAttachments(res.data)
                      console.log(res.data)
                    })
                }}>
                  <ListItemIcon>
                    <CommentIcon fontSize='small'/>
                  </ListItemIcon>
                  <ListItemText>Comment</ListItemText>
                </MenuItem>
                <MenuItem onClick={() => {
                  setShowForm(true)
                  setAnchorEl(null)
                }}>
                  <ListItemIcon>
                    <Edit fontSize='small'/>
                  </ListItemIcon>
                  <ListItemText>Edit</ListItemText>
                </MenuItem>
              </Menu>
            </TableCell>
          </TableRow>

          <TaskDrawer 
            open={openDrawer} 
            isLoading={isLoading}
            taskComponent={<TaskDetails task={operationalState} title={'Operational'}/>} 
            components={<CommentsAndAttachmentsTab
                attachmentDir={`${operational.tactical_task.strategic_task.delivery.client_info.id}/${operational.tactical_task.strategic_task.delivery.id}/${operational.tactical_task.strategic_task.id}/${operational.tactical_task.id}/${operational.id}`}
                // attachmentDir={`${0}/${0}/${0}/${0}/${0}`} 
                status={status} 
                comments={comments?.map((comment) => ({ id : comment.id, stakeholder : (comment.operational_task.stakeholder == null) ? null : { name : comment.operational_task.stakeholder?.name,  }, text : comment.comment, user : comment.user, createdAt : comment.created_at }))} 
                onSubmitComment={(comment) => {
                  dispatch(createOperationalComment({ data : { comment, operational_task_id : operational.id } }))
                }}
                isOnAttaching={isOnAttaching}
                onAttach={(data) => {
                  setIsOnAttaching(true)
                  taskAttachmentService.createOperationalAttachment({ data : { operational_task : operational.id, attachment_url : data['file'], attachment_desc : data['desc'] } })
                    .then((res) => {
                      setIsOnAttaching(false)
                      setAttachments((prev) => [...prev, res.data])
                    })
                }}
                attachments={attachments}
                onDelete={(id) => {
                  taskAttachmentService.deleteOperationalAttachment({ id })
                    .then((res) => {
                      setAttachments((prev) => prev.filter((attachment) => attachment.id !== id))
                    })
                    .catch((err)=> {})
                }}
                onDeleteComment={(comment) => {
                  operationalCommentService.deleteComment({ id : comment.id })
                    .then((res) => {
                      dispatch(getAllOperationalComments({ operationalId : operational.id }))
                    })
                }}/>}
            onClose={() => setOpenDrawer(false)}
            />
            <Modal open={showForm} onClose={() => setShowForm(false)} sx={{
              display : 'flex',
              justifyContent : 'center',
              paddingY : '3rem',
              paddingBottom : '13rem'
            }}>
              <TaskformWrapper title={'Operational'} onClose={() => setShowForm(false)}>
                <SaveOperationalForm
                  // inputConfig={{
                  //     taskDescDisabled : (JSON.parse(localStorage.getItem('auth')).role_name === userRole.CYTECH_USER && editOperational),
                  //     plannedDueDateDisabled : (JSON.parse(localStorage.getItem('auth')).role_name === userRole.CYTECH_USER && editOperational),
                  //     plannedStartDateDisabled : (JSON.parse(localStorage.getItem('auth')).role_name === userRole.CYTECH_USER && editOperational),
                  //     actualDueDateDisabled : (JSON.parse(localStorage.getItem('auth')).role_name === userRole.CYTECH_USER && editOperational),
                  //     actualStartDateDisabled : (JSON.parse(localStorage.getItem('auth')).role_name === userRole.CYTECH_USER && editOperational)
                  // }}
                  operationalData={operational}
                  submitBtnText={'Update'}
                  showHeader={false}
                  width={'100%'}
                  toEdit={true}
                  onSubmit={(values, toEdit) => {

                      const user = JSON.parse(localStorage.getItem('auth'))
                      const data = {
                          task_desc: values['name'],
                          tactical_task_id: operational.tactical_task.id,
                          stakeholder_id: user.role_name === userRole.CYTECH_USER ? user.id : 0,
                          planned_due_date: values['plannedDueDate'],
                          planned_start_date: values['plannedStartDate'],
                          actual_due_date: values['actualDueDate'],
                          actual_start_date: values['actualStartDate'],
                          task_status: values['taskStatus']
                      }

                      // if (!toEdit) {
                      //     dispatch(createOperational({ data }))
                      // } else {
                      //     const dataToUpdate = { ...data, id: values['id'] }
                      //     // console.log(dataToUpdate)
                      //     dispatch(updateOperational({ data: dataToUpdate }))
                      // }
                      dispatch(setOperationalIsSubmitting(true))
                      const dataToUpdate = { ...data, id: values['id'] }
                      operationalService.update({ updatedData : dataToUpdate, dispatch })
                        .then((res) => {
                          update(res.data)
                          setShowForm(false)
                          dispatch(setOperationalIsSubmitting(false))                   
                        })
                  }} />
              </TaskformWrapper>
            </Modal>
        </>
    )
}

const TacticalRow = ({ tactical, update }) => {

  const [ancholEl, setAnchorEl] = React.useState(null)
  const open = Boolean(ancholEl)
  const [showForm, setShowForm] = React.useState(false)
  const dispatch = useDispatch()
  const [operationalOpenDrawer, setOperationalOpenDrawer] = React.useState(false)
  const [operationals, setOperationals] = React.useState([])
  const [operationalLoading, setOperationalLoading] = React.useState(false)
  const [openDrawer, setOpenDrawer] = React.useState(false)
  const [comments, setComments] = React.useState([])
  const [status, setStatus] = React.useState(STATE_STATUS.IDLE)

  /* .-. -- -...  State Switch Operational */
  const [showSaveOperationalFormModal, setShowSaveOperationalFormModal] = useState(false);
  const tactical_id= tactical.id;

  const fetchComments = () => {
    tacticaltaskCommentService
      .getAllComments({ tacticalId : tactical.id })
      .then((res) => {
        setOpenDrawer(true)
        setComments(res.data)
      })
  }

  useEffect(() => {
    if(status === STATE_STATUS.SUCCESS){
      fetchComments()
    }
  }, [status])

  return <>
    <TableRow sx={{
      backgroundColor  : 'GrayText'
    }}>
      <TableCell>
          <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => {

                const user = JSON.parse(localStorage.getItem('auth'))
                
                setOperationalOpenDrawer(!operationalOpenDrawer)
                setOperationalLoading(true)
                operationalService.getAll({ tacticalId : tactical.id, assigneeId : `${user.role_name === userRole.CYTECH_USER ? user.id : ''}` })
                  .then((res) => {
                    setOperationals(res.data)
                    setOperationalLoading(false)
                  })
                  .catch((err) => setOperationalLoading(false))
              }}
          >
              {operationalOpenDrawer ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
      </TableCell>
        <TableCell component="th" scope="row">
            { tactical.task_desc }
        </TableCell>
        <TableCell>
          <LinearProgressWithLabel value={tactical.progress}/>
        </TableCell>
        <TableCell component="th" scope="row">
            { getTaskStatus(tactical.task_status) }
        </TableCell>
        <TableCell component="th" scope="row">
            { moment(tactical.actual_start_date).format('YYYY-MM-DD') === "1900-01-01" ? "-" : moment(tactical.actual_start_date).format('YYYY-MM-DD') }
        </TableCell>
        <TableCell component="th" scope="row">
            { moment(tactical.actual_due_date).format('YYYY-MM-DD') === "1900-01-01" ? "-" : moment(tactical.actual_due_date).format('YYYY-MM-DD') }
        </TableCell>
        <TableCell component="th" scope="row">
            { moment(tactical.planned_start_date).format('YYYY-MM-DD') === "1900-01-01" ? "-" : moment(tactical.planned_start_date).format('YYYY-MM-DD') }
        </TableCell>
        <TableCell component="th" scope="row">
            { moment(tactical.planned_due_date).format('YYYY-MM-DD') }
        </TableCell>
        <TableCell>
            <IconButton size='small' onClick={(e) => setAnchorEl(e.currentTarget)}>
            <MoreVert fontSize='small'/>
          </IconButton>
          <Menu anchorEl={ancholEl} open={open} onClose={() => setAnchorEl(null)}>
            <MenuItem onClick={() => {
              fetchComments()
              setAnchorEl(null)
            }}>
              <ListItemIcon>
                <CommentIcon fontSize='small'/>
              </ListItemIcon>
              <ListItemText>Comment</ListItemText>
            </MenuItem>
            <MenuItem onClick={() => {
              setShowForm(true)
              setAnchorEl(null)
            }}>
              <ListItemIcon>
                <Edit fontSize='small'/>
              </ListItemIcon>
              <ListItemText>Edit</ListItemText>
            </MenuItem>
          </Menu>
        </TableCell>
    </TableRow>
    
    <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={operationalOpenDrawer} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1, backgroundColor : 'GrayText', padding : '1.2rem', borderRadius : '1rem', width : '125%' }}>

                <Typography variant="h6" gutterBottom component="div">
                    { tactical.task_desc }  
                </Typography>

                {/* .-. -- -...  Button Operational */}
                <Grid container justifyContent="flex-end"> 
                <Tooltip title='Add operational task on this tactical' >
                  <Button sx={{
                    background: "var(--blue)", 
                    color: "white" ,
                    marginBottom: "5px",
                    ":hover" : {
                        background: "var(--blue)", 
                        boxShadow: 'inset 0 0 3px 3px var(--blue-darker)',
                      },
                  }} variant='contained' startIcon={<AddIcon/>} size='small' onClick={() => {
                      /* console.log('clicked add Operational!'); */
                      setShowSaveOperationalFormModal(true);
                    }} >Add Operational</Button>
                  
                </Tooltip>
              </Grid>

                { operationalLoading ? <>
                  <Box sx={{ display : 'flex', justifyContent : 'center', alignItems : 'center', marginTop : '2rem' }}>
                    <CircularProgress color='secondary' size={30}/>
                  </Box>
                </> : operationals.length > 0 ? 
                  <Table size="small" aria-label="purchases" >
                      <TableHead>
                        <TableRow sx={{
                          backgroundColor  : '#67AD45'
                        }}>
                            <TableCell>Operational Tasks</TableCell>
                            <TableCell>Progress</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Actual Start Date</TableCell>
                            <TableCell>Actual Due Date</TableCell>
                            <TableCell>Plan Start Date</TableCell>
                            <TableCell>Plan Due Date</TableCell>
                            <TableCell>ACTIONS</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                          {operationals.map((operational) => (
                            <OperationalRow key={operational.id} operational={operational} update={(data) => {
                              const index = operationals.findIndex(x => x.id === data.id)
                              operationals[index] = data
                              setOperationals([...operationals])
                              update({...tactical, ...{ progress :  Number(operationals.map(x => x.progress).reduce((a, b) => a + b, 0) / operationals.length).toPrecision(3)}})
                            }}/>
                          ))}
                      </TableBody>
                  </Table> : <>
                    <Box sx={{ display : 'flex', justifyContent : 'center', alignItems : 'center', marginTop : '1rem' }}>
                      <Typography variant='subtitle2' component={'p'}>{JSON.parse(localStorage.getItem('auth')).role_name === userRole.CYTECH_USER ? 'No tasks assigned.' : 'No operationals data'}</Typography>
                    </Box></>}
              </Box>
          </Collapse>
        </TableCell>
    </TableRow>
    <TaskDrawer 
      open={openDrawer} 
      onClose={() => setOpenDrawer(false)}
      taskComponent={<TaskDetails task={tactical} title={'Tactical'} backgroundColor='#11A0DB'/>}
      components={<CommentsAndAttachmentsTab
        status={status}
        comments={comments?.map((comment) => ({ id : comment.id, text : comment.comment, user : comment.user, createdAt : comment.created_at }))}
        onSubmitComment={(comment) => {
          const user = JSON.parse(localStorage.getItem('auth'))
          setStatus(STATE_STATUS.LOADING)
          tacticaltaskCommentService.createComment({ data : { user : user.id, comment : comment, tactical_task : tactical.id } })
            .then((res) => {
              setStatus(STATE_STATUS.SUCCESS)
            })
        }}
        onDeleteComment={(comment) => {
          setStatus(STATE_STATUS.IDLE)
          tacticaltaskCommentService.deleteComment({ id : comment.id })
            .then((res) => {
              setStatus(STATE_STATUS.SUCCESS)
            })
        }}/>}
      />
      <Modal open={showForm} onClose={() => setShowForm(false)} sx={{
        display : 'flex',
        justifyContent : 'center',
        paddingY : '3rem',
        paddingBottom : '13rem'
      }}>
        <TaskformWrapper title={'Tactical'} onClose={() => setShowForm(false)}>
          <SaveTacticalForm
          showHeader={false}
            width={'50vh'}
            inputConfig={{
                taskDescDisabled: JSON.parse(localStorage.getItem('auth')).role_name === userRole.CYTECH_USER,
                plannedDueDateDisabled: JSON.parse(localStorage.getItem('auth')).role_name === userRole.CYTECH_USER,
                plannedStartDateDisabled: JSON.parse(localStorage.getItem('auth')).role_name === userRole.CYTECH_USER,
                actualDueDateDisabled: JSON.parse(localStorage.getItem('auth')).role_name === userRole.CYTECH_USER,
                actualStartDateDisabled: JSON.parse(localStorage.getItem('auth')).role_name === userRole.CYTECH_USER
            }}
            onSubmit={(values, toEdit) => {
                const data = {
                    task_desc: values['name'],
                    strategic_task_id: tactical.strategic_task.id,
                    planned_due_date: values['plannedDueDate'],
                    planned_start_date: values['plannedStartDate'],
                    actual_due_date: values['actualDueDate'],
                    actual_start_date: values['actualStartDate'],
                    task_status: values['taskStatus']
                }

                // if (!toEdit) {
                //     dispatch(createTactical({ data }))
                // } else {
                //     const dataToUpdate = { ...data, id: values['id'] }
                //     dispatch(updateTactical({ data: dataToUpdate }))
                // }
                const dataToUpdate = { ...data, id: values['id'] }
                // console.log(dataToUpdate)
                dispatch(setTacticalIsSubmitting(true))
                tacticalService.update({ updatedData : dataToUpdate, dispatch })
                  .then((res) => {
                    update(res.data)
                    dispatch(setTacticalIsSubmitting(false))
                    setShowForm(false)
                  })

            }} tacticalData={tactical} toEdit={true} submitBtnText={'Update'} />
        </TaskformWrapper>
      </Modal>
      
      {/* .-. -- -...  Modal Operational Form*/}
      <Dialog
          open={showSaveOperationalFormModal}
          onClose={() => setShowSaveOperationalFormModal(false)}
          maxWidth="md"
        >
          <DialogTitle>Operational</DialogTitle>
              <SaveStrategicForm
                onClose={() => setShowSaveOperationalFormModal(false)}
                clientInfoId={tactical_id}
                submitBtnText='create'  
                width={'50vh'}
                onSubmit={(values)=>{
                  const user = JSON.parse(localStorage.getItem('auth'))
                  const data = {
                    task_desc: values['name'],
                    tactical_task_id: tactical_id,
                    stakeholder_id: user.role_name === userRole.CYTECH_USER ? user.id : 0,
                    planned_due_date: values['plannedDueDate'],
                    planned_start_date: values['plannedStartDate'],
                    actual_due_date: values['actualDueDate'],
                    actual_start_date: values['actualStartDate'],
                    task_status: values['taskStatus']
                  }
                  operationalService.create({ data, dispatch })
                    .then((res) => {
                      setShowSaveOperationalFormModal(false)
                      setOperationals([...operationals, res.data])
                    })
                }}
              />
      </Dialog>
    {/* <OperationalRow openDrawer={operationalOpenDrawer} tactical={tactical}/> */}
  </>
}

const StrategicRow = ( { strategic, update } ) => {
  const [ancholEl, setAnchorEl] = React.useState(null)
  const open = Boolean(ancholEl)
  const [showForm, setShowForm] = React.useState(false)
  const dispatch = useDispatch()
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [tacticals, setTacticals] = React.useState([])
  const [tacticalLoading, setTacticalLoading] = React.useState(false)
  const [openTaskDrawer, setOpenTaskDrawer] = React.useState(false)
  const [comments, setComments] = React.useState([])
  const [status, setStatus] = React.useState(STATE_STATUS.IDLE)

  /* .-. -- -...  Switcher Tactical State */
  const [showSaveTacticalFormModal, setShowSaveTacticalFormModal] = useState(false);
  const strategic_id = strategic.id;
  /* console.log(strategic_id) */

  const fetchComments = () => {
    strategictaskCommentService
      .getAllComments({ strategicId : strategic.id })
      .then((res) => {
        setOpenTaskDrawer(true)
        setComments(res.data)
      })
  }

  useEffect(() => {
    if(status === STATE_STATUS.SUCCESS){
      fetchComments()
    }
  }, [status])

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' }, backgroundColor  : 'GrayText' }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => {
              setOpenDrawer(!openDrawer)
              setTacticalLoading(!tacticalLoading)
              tacticalService.getAll({ strategicId : strategic.id })
                .then((res) => {
                  setTacticals(res.data)
                  setTacticalLoading(false)
                  // console.log(tacticals)
                }).catch((err) => console.log(err))
            }}
          >
            {openDrawer ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          <Box sx={{
            display : 'flex',
            justifyContent : 'flex-start',
            alignItems : 'center'
          }}>
            {/* {strategic.late && <Tooltip title='LATE' sx={{
              marginRight : '1rem'
            }}>
              <WatchLaterOutlinedIcon fontSize='small' color='error'/>
            </Tooltip>} */}
            {strategic?.task_desc}
          </Box>
        </TableCell>
        <TableCell>
          <LinearProgressWithLabel value={Number(strategic.progress)}/>
        </TableCell>
        <TableCell align="right">{getTaskStatus(strategic.task_status)}</TableCell>
        <TableCell align="right">{moment(strategic.actual_start_date).format('YYYY/MM/DD') === "1900/01/01" ? "-" : moment(strategic.actual_start_date).format('YYYY/MM/DD')}</TableCell>
        <TableCell align="right">{moment(strategic.actual_due_date).format('YYYY/MM/DD') === "1900/01/01" ? "-" : moment(strategic.actual_due_date).format('YYYY/MM/DD')}</TableCell>
        <TableCell align="right">{moment(strategic.planned_start_date).format('YYYY/MM/DD') === "1900/01/01" ? "-" : moment(strategic.planned_start_date).format('YYYY/MM/DD')}</TableCell>
        <TableCell align="right">{moment(strategic.planned_due_date).format('YYYY/MM/DD')}</TableCell>
        <TableCell>
          <IconButton size='small' onClick={(e) => setAnchorEl(e.currentTarget)}>
            <MoreVert fontSize='small'/>
          </IconButton>
          <Menu anchorEl={ancholEl} open={open} onClose={() => setAnchorEl(null)}>
            <MenuItem onClick={() => {
              fetchComments()
              setAnchorEl(null)
            }}>
              <ListItemIcon>
                <CommentIcon fontSize='small'/>
              </ListItemIcon>
              <ListItemText>Comment</ListItemText>
            </MenuItem>
            <MenuItem onClick={() => {
              setShowForm(true)
              setAnchorEl(null)
            }}>
              <ListItemIcon>
                <Edit fontSize='small'/>
              </ListItemIcon>
              <ListItemText>Edit</ListItemText>
            </MenuItem>
          </Menu>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={openDrawer} timeout="auto" unmountOnExit>
            
            <Box sx={{ margin: 1, backgroundColor  : 'GrayText', borderRadius  : '1rem', padding : '1.2rem', width : '125%' }}>
              <Typography variant="h6" gutterBottom component="div" color='secondary'>
                { strategic?.task_desc }
              </Typography>

              {/* .-. -- -...  Tactical Button */}
              <Grid container justifyContent="flex-end"> 
                <Tooltip title='Add Tactical on this strategic' >
                  <Box
                    width={'7vw'}
                    marginLeft='auto'
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      border: '1px solid var(--blue)',
                      borderRadius: '5px',
                      margin:'5px 0',
                      backgroundColor: 'var(--blue)',
                      cursor: 'pointer',
                      '&:hover': {
                        /* transform: 'scale(1.1)', */
                        boxShadow: 'inset 0 0 3px 3px var(--blue-darker)',
                      },
                    }}
                    onClick={() => {
                      // console.log('clicked add Tactical!');
                      setShowSaveTacticalFormModal(true);
                    }}
                  >
                    <IconButton
                      size='small'
                      sx={{
                        color: 'var(--white)',
                        padding: '6px',
                        borderRadius: 0, 
                        transition: 'transform 0.2s',
                      }}
                    >
                      <AddIcon fontSize='small' />
                    </IconButton>
                    <Typography variant='body2' color={'var(--white)'}>ADD TACTICAL</Typography>
                  </Box>
                </Tooltip>
              </Grid>

              {tacticalLoading ? <>
                <Box sx={{ display : 'flex', justifyContent : 'center', alignItems : 'center', marginTop : '2rem' }}>
                  <CircularProgress color='secondary' size={30}/>
                </Box>
              </> : tacticals.length > 0 ? <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow sx={{
                        backgroundColor : '#11A0DB'
                      }}>
                      <TableCell/>
                      <TableCell>Tactical Tasks</TableCell>
                      <TableCell>Progress</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Actual Start Date</TableCell>
                      <TableCell>Actual Due Date</TableCell>
                      <TableCell>Plan Start Date</TableCell>
                      <TableCell>Plan Due Date</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                      {tacticals?.map((tactical) => (
                        <>  
                          <TacticalRow key={tactical.id} tactical={tactical} update={(data) => {
                            const index = tacticals.findIndex(x => x.id === data.id)
                            tacticals[index] = data
                            dispatch(alterStrategic({...strategic, ...{ progress : Number(tacticals.map(x => Number(x.progress)).reduce((a, b) => a + b, 0) / tacticals.length).toPrecision(3) }}))
                            setTacticals([...tacticals])
                            update({...strategic, ...{ progress : Number(tacticals.map(x => Number(x.progress)).reduce((a, b) => a + b, 0) / tacticals.length).toPrecision(3) }})
                          }}/>
                        </>
                      ))}
                  </TableBody>
              </Table> : <><Box sx={{ display : 'flex', justifyContent : 'center', alignItems : 'center', marginTop : '1rem' }}>
                        <Typography variant='subtitle2' component={'p'}>No tacticals data</Typography>
                </Box></>}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <TaskDrawer 
        open={openTaskDrawer}
        onClose={() => setOpenTaskDrawer(false)}
        taskComponent={<TaskDetails title={'Strategic'} task={strategic} backgroundColor='#F99D1B'/>}
        components={<CommentsAndAttachmentsTab
          comments={comments?.map((comment) => ({ id : comment.id, text : comment.comment, user : comment.user, createdAt : comment.created_at }))}
          onSubmitComment={(comment) => {
            const user = JSON.parse(localStorage.getItem('auth'))
            setStatus(STATE_STATUS.LOADING)
            strategictaskCommentService.createComment({ data : { user : user.id, comment : comment, strategic_task : strategic.id } })
              .then((res) => {
                setStatus(STATE_STATUS.SUCCESS)
              })
          }}
          onDeleteComment={(comment) => {
            setStatus(STATE_STATUS.LOADING)
            strategictaskCommentService.deleteComment({ id : comment.id })
              .then((res) => {
                setStatus(STATE_STATUS.SUCCESS)
              })
          }}
          />}/>
      <Modal open={showForm} onClose={() => setShowForm(false)} sx={{
        display : 'flex',
        justifyContent : 'center',
        paddingY : '3rem',
        paddingBottom : '13rem'
      }}>
        <TaskformWrapper title={'Strategic'}>
          <SaveStrategicForm
            inputConfig={{
                taskDescDisabled: JSON.parse(localStorage.getItem('auth')).role_name === userRole.CYTECH_USER,
                plannedDueDateDisabled: JSON.parse(localStorage.getItem('auth')).role_name === userRole.CYTECH_USER,
                plannedStartDateDisabled: JSON.parse(localStorage.getItem('auth')).role_name === userRole.CYTECH_USER,
                actualDueDateDisabled: JSON.parse(localStorage.getItem('auth')).role_name === userRole.CYTECH_USER,
                actualStartDateDisabled: JSON.parse(localStorage.getItem('auth')).role_name === userRole.CYTECH_USER
            }}
            onSubmit={(values, toEdit) => {

                const data = {
                    task_desc: values['name'],
                    delivery_id: strategic.delivery.id,
                    planned_due_date: values['plannedDueDate'],
                    planned_start_date: values['plannedStartDate'],
                    actual_due_date: values['actualDueDate'],
                    actual_start_date: values['actualStartDate'],
                    task_status: values['taskStatus']
                }
                const dataToUpdate = { ...data, id: values['id'] }
                dispatch(setIsSubmitting(true))
                strategicService.update({ updatedData : dataToUpdate, dispatch })
                  .then((res) => {
                    dispatch(alterStrategic(res.data))
                    dispatch(setIsSubmitting(false))
                    setShowForm(false)
                  })
            }}
            width={'50vh'}
            strategicData={strategic}
            showHeader={false} onClose={() => setShowForm(false)}
            toEdit={true}
            submitBtnText={'Update'} />
        </TaskformWrapper>
      </Modal>
      {/* .-. -- -...  Modal Tactical Form*/}
      <Dialog
          open={showSaveTacticalFormModal}
          onClose={() => setShowSaveTacticalFormModal(false)}
          maxWidth="md"
        >
          <DialogTitle>Tactical</DialogTitle>
              <SaveStrategicForm
                onClose={() => setShowSaveTacticalFormModal(false)}
                clientInfoId={strategic_id}
                submitBtnText='create'  
                width={'50vh'}
                onSubmit={(values)=>{
                  const data = {
                    task_desc: values['name'],
                    strategic_task_id: strategic_id,
                    planned_due_date: values['plannedDueDate'],
                    planned_start_date: values['plannedStartDate'],
                    actual_due_date: values['actualDueDate'],
                    actual_start_date: values['actualStartDate'],
                    task_status: values['taskStatus']
                  }
                  tacticalService.create({ data, dispatch })
                    .then((res) => {
                      setShowSaveTacticalFormModal(false)
                      setTacticals([...tacticals, res.data])
                    })
                }}
              />
      </Dialog>
      
    </>
  );
}

export default function DeliverableDetails({ deliverableId }) {
  const { isLoading, isError, isSuccess, strategics } = useSelector(state => state.strategic)
  const dispatch = useDispatch()

  /* .-. -- -...  Switcher State Strategic*/
  const [showSaveStrategicFormModal, setShowSaveStrategicFormModal] = useState(false);

  useEffect(() => {
    dispatch(getAllStrategicsByDelivery({ deliverableId }))
  }, [dispatch])

  return(
    <Box>
      <Box sx={{height : '85%'}}>
        {/* .-. -- -...  Add button fo Strategic */}
        {/* .-. -- -... Move button */}
        <Grid container justifyContent="flex-end"> 
          <Tooltip title='Add Strategic on this deliverables' >
            <Box
              width={'7vw'}
              marginLeft='auto'
              sx={{
                display: 'flex',
                alignItems: 'center',
                border: '1px solid var(--blue)',
                borderRadius: '5px',
                margin:'5px 0',
                backgroundColor: 'var(--blue)',
                cursor: 'pointer',
                '&:hover': {
                  /* transform: 'scale(1.1)', */
                  boxShadow: 'inset 0 0 3px 3px var(--blue-darker)',
                },
              }}
              onClick={() => {
                // console.log('clicked add strategic!');
                setShowSaveStrategicFormModal(true);
              }}
            >
              <IconButton
                size='small'
                sx={{
                  color: 'var(--white)',
                  padding: '6px',
                  borderRadius: 0, 
                  transition: 'transform 0.2s',
                }}
              >
                <AddIcon fontSize='small' />
              </IconButton>
              <Typography variant='body2' color={'var(--white)'}>ADD STRATEGIC</Typography>
            </Box>
          </Tooltip>
        </Grid>
      </Box>
      {isLoading ? <>
        <Box sx={{ display : 'flex', justifyContent : 'center', alignItems : 'center', marginTop : '5rem' }}>
          <CircularProgress color='secondary' size={50}/>
        </Box>
          </> : (
          strategics.length > 0 ?
          <Box sx={{ height : '60%'}}>
            <TableContainer component={Paper} sx={{ height : '70vh', zIndex : '-1' }}>
              <Table aria-label="collapsible table">
                <TableHead >
                  <TableRow sx={{
                    border: '1px solid var(--gray)'
                  }}>
                    <TableCell />
                    <TableCell>Strategic Tasks</TableCell>
                    <TableCell>Progress</TableCell>
                    <TableCell align="right">Status</TableCell>
                    <TableCell align="right">Actual Start Date</TableCell>
                    <TableCell align="right">Actual Due Date</TableCell>
                    <TableCell align="right">Plan Start Date</TableCell>
                    <TableCell align="right">Plan Due Date</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {strategics.map((row) => (
                    <StrategicRow key={row.id} strategic={row} update={(data) => {
                      let items = strategics
                      let strategicItems = []
                      for(let item of items){
                        strategicItems.push(item)
                      }
                      const index = strategicItems.findIndex(x => x.id === data.id)
                      strategicItems[index] = data
                      dispatch(alterDeliverable({...data.delivery, ...{ progress : Number(strategicItems.map(x => Number(x.progress)).reduce((prev, curr) => prev + curr, 0)) / strategicItems.length }}))
                    }}/>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            </Box> : <Box sx={{width : '100%' ,display : 'flex' , justifyContent : 'center', marginTop : '4rem', alignItems : 'center' }}>
              <Typography textAlign={'center'}>No Data</Typography>
            
            </Box>
          )
      }
      {/* //.-. -- -...  Modal Strategic Form */}
      <Dialog
        open={showSaveStrategicFormModal}
        onClose={() => setShowSaveStrategicFormModal(false)}
        maxWidth="md"
      >
        <DialogTitle>Strategic</DialogTitle>
          <SaveStrategicForm
            onClose={() => setShowSaveStrategicFormModal(false)}
            clientInfoId={deliverableId}
            submitBtnText='create'  
            width={'50vh'}
            onSubmit={(values)=>{
              const data = {
                task_desc: values['name'],
                delivery_id: deliverableId,
                planned_due_date: values['plannedDueDate'],
                planned_start_date: values['plannedStartDate'],
                actual_due_date: values['actualDueDate'],
                actual_start_date: values['actualStartDate'],
                task_status: values['taskStatus']
              }
              dispatch(createStrategic({ data }))
              setShowSaveStrategicFormModal(false)
            }}
          />
      </Dialog> 
    </Box>
  )
}



