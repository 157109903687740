import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import React, { useState } from 'react'

export const SelectInput = ({ label, options, onChange, defaultVal, disbaled = false }) => {

    const [value, setValue] = useState(defaultVal)
  return (
    <FormControl fullWidth color='info' size='small'>
        <InputLabel>{label}</InputLabel>
        <Select
            disabled={disbaled}
            fullWidth
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={defaultVal}
            label={label}
            onChange={(event) => {
                onChange(event.target.value)
                // setValue(event.target.value)
            }}
        >
            {options?.map((opt, i) => (
                <MenuItem key={i} value={opt?.keyVal}>{opt?.value}</MenuItem>
            ))}
        </Select>
    </FormControl>
  )
}
