import moment from "moment";
import saveDeliverable from "./saveStrategic";
import * as Yup from 'yup'
import { parseDate } from "../../helpers/dateformatter";

const {
    formField : {
        name,
        plannedStartDate,
        plannedDueDate,
        actualStartDate,
        actualDueDate,
        taskStatus
    }
} = saveDeliverable



const saveStrategicValidator = Yup.object()
    .shape({
        [name.name] : Yup.string().required(name.requiredErrorMsg),
        [plannedStartDate.name] : Yup.string()
            .transform((val, orig) => {
                if(!val) return ''
                return moment(parseDate(orig)).format('YYYY-MM-DD') 
            })
            .nullable().optional(),
        [plannedDueDate.name] : Yup.string().required(plannedDueDate.requiredErrorMsg),
        [actualDueDate.name] : Yup.string().nullable().optional(),
        [actualStartDate.name] : Yup.string().nullable().optional(),
        [taskStatus.name] : Yup.string().required('Status is required')
    })


export default saveStrategicValidator