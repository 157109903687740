import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import notificationService from "../../services/notificationService";
import STATE_STATUS from "../../constants/status";

const initialState = {
    notifications : [],
    status : STATE_STATUS.IDLE,
    count : 0
}
export const getAllNotifications = createAsyncThunk(
    'notification/getAll',
    async (params, thunk) => {
        try {
            const res = await notificationService.getAll({ stakeholderId : params.stakeholderId })
            return res.data
        } catch (error) {
            return thunk.rejectWithValue(error.response.data)
        }
    }
)

// /* .-. -- -... Notifications  */
// export const removeOneNotification = createAsyncThunk(
//     'nofication/removeOne',
//     async (params, thunk) => {
//         try {
//             const res = await notificationService.removeOne({stakeholderId: params.stakeholderId})
//             return res.data
//         } catch (error){
//             return thunk,rejectWithValue(error.response.data)
//         }
//     }
// )

const notificationSlice = createSlice({
    name : 'notification',
    initialState,
    reducers : {
        removeOneNotification:(state, action) => {
            const index = state.notifications.findIndex(x => x.id === action.payload.id)
            state.notifications[index].notification.status = "SN"
            if(state.count > 0){
                state.count -= 1
            }  
        }
    },
    extraReducers : (builder) => {
        builder.addCase(getAllNotifications.pending, (state) => {
            state.notifications = []
            state.status = STATE_STATUS.LOADING
            state.count = 0
        }).addCase(getAllNotifications.fulfilled, (state, action) => {
            state.notifications = action.payload
            state.status = STATE_STATUS.SUCCESS
            state.count = state.notifications.filter(n => n.notification.status === 'DL').length
            // console.log('Notification count ',state.count)
        }).addCase(getAllNotifications.rejected, (state) => {
            state.notifications = []
            state.status = STATE_STATUS.FAILED
            state.count = 0
        })
    }
})

export default notificationSlice.reducer
export const { removeOneNotification } = notificationSlice.actions