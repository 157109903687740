import React from 'react'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { NotificationContent } from './NotificationContent';

export const NotificationList = ({ notifications = []}) => {
  return (
    /* .-. -- -... Notifications List*/
    <List sx={{ width: '100%' }}>
      {notifications.map((notification) => (
        <NotificationContent notification={notification} key={notification.id}/>
      ))}
    </List>
  );
}

