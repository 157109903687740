import axios from "axios"
import { apiUrl } from "../constants/urls"
import httpClient from "../config/axiosConfig"



const getAll = async ({ stakeholderId }) => {
    try {
        const res = await axios.get(`${apiUrl}notifications?stakeholder_id=${stakeholderId}`)
        return res
    } catch (error) {
        return error
    }
}

const updateStatus = async ({ status,  notificationContentId }) => {
    try {
        const token = JSON.parse(localStorage.getItem('auth'))?.access_token
        const res = await httpClient.put('notifications/status/' + notificationContentId + '/', 
            { status },
            {
                headers : {
                    Authorization : 'Bearer ' + token
                }
            } )
        return res
    } catch (error) {
        return error
    }
} 


const notificationService = {
    getAll,
    updateStatus
}

export default notificationService