import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import operationalService from "../../services/operationalService";


const initialState = {
    isLoading : false,
    isSuccess  : false,
    isError : false,
    operationals : [],
    operational : null,
    response : null,
    showForm : false,
    showAlert : false,
    message : '',
    isDeleteLoading : false,
    isSubmitting : false
}

export const getAllOperationals = createAsyncThunk(
    'operational/getAll',
    async (params, thunk) => {
        try {
            const res = await operationalService.getAll({ tacticalId : params?.tacticalId, assigneeId : params?.assigneeId, dispatch : thunk.dispatch })
            return res.data
        } catch (error) {
            return thunk.rejectWithValue(error.response.data)
        }
    }
)

export const getAssignedToMe = createAsyncThunk(
    'operational/assgined-to-me',
    async (params, thunk) => {
        try {
            const res = await operationalService.getAllAssignedToMe({ assigneeId : params.assigneeId, dispatch : thunk.dispatch })  
            return res.data
        } catch (error) {
            return thunk.rejectWithValue(error.response.data)
        }
    }
)

export const getOperational = createAsyncThunk(
    'operational/getOne',
    async (params, thunk) => {
        try {
            const res = await operationalService.getOne({ id : params.id, dispatch : thunk.dispatch })
            return res.data
        } catch (error) {
            return thunk.rejectWithValue(error.response.data)
        }
    }
)

export const createOperational = createAsyncThunk(
    'opertional/create',
    async (params, thunk) => {
        try {
            const res = await operationalService.create({ data : params.data, dispatch : thunk.dispatch })
            return res.data
        } catch (error) {
            return thunk.rejectWithValue(error.response.data)
        }
    }
)

export const updateOperational = createAsyncThunk(
    'opertional/update',
    async (params, thunk) => {
        try {
            const res = await operationalService.update({ updatedData : params.data, dispatch : thunk.dispatch })
            return res.data
        } catch (error) {
            return thunk.rejectWithValue(error.response.data)
        }
    }
)

export const deleteOperational = createAsyncThunk(
    'opertional/delete',
    async (params, thunk) => {
        try {
            const res = await operationalService.deleteOne({ id : params.id })
            return res.data
        } catch (error) {
            return thunk.rejectWithValue(error.response.data)
        }
    }
)

const operationalSlice = createSlice({
    name : 'operational',
    initialState,
    reducers : {
        showFormToggle : (state) => {
            state.showForm = !state.showForm
        },
        showForm : (state) => {
            state.showForm = true
        },
        resetFormState : (state) => {
            state.showForm = false
        },
        showAlert : (state) => {
            state.showAlert = true
        },
        resetAlertState : (state) => {
            state.showAlert = false
        },
        setOperational : (state, action) => {
            state.operational = action.payload
        },
        setOperationalIsSubmitting : (state, action) => {
            state.isSubmitting = action.payload
        }
    },
    extraReducers : (builder) => {
        builder.addCase(getAllOperationals.pending, (state) => {
            state.isError = false
            state.isLoading = true
            state.isSuccess = false
            state.operationals = []
        }).addCase(getAllOperationals.fulfilled, (state, action) => {
            state.isError = false
            state.isLoading = false
            state.isSuccess = true
            state.operationals = action.payload
        }).addCase(getAllOperationals.rejected, (state, action) => {
            state.isError = true
            state.isLoading = false
            state.isSuccess = false
            state.operationals = []
        }).addCase(createOperational.pending, (state) => {
            state.isError = false
            state.isSubmitting = true
            state.isSuccess = false
        }).addCase(createOperational.fulfilled, (state, action) => {
            state.isError = false
            state.isSubmitting = false
            state.isSuccess = true
            state.operationals.push(action.payload)
            state.showForm = false
            state.showAlert = true
            state.message = 'Successfully added operational'
        }).addCase(createOperational.rejected, (state, action) => {
            state.isError = true
            state.isSubmitting = false
            state.isSuccess = false
            state.showForm = true
            state.showAlert = true
            state.message = 'Failed to add operational'
            // state.operational = action.payload
            console.log(action.payload)
        }).addCase(updateOperational.pending, (state) => {
            state.isError = false
            state.isSubmitting = true
            state.isSuccess = false
        }).addCase(updateOperational.fulfilled, (state, action) => {
            state.isError = false
            state.isSubmitting = false
            state.isSuccess = true
            const index = state.operationals.findIndex(o => o.id == action.payload.id)
            state.operationals[index] = action.payload
            state.showForm = false
            state.showAlert = true
            state.message = 'Successfully modified operational'
        }).addCase(updateOperational.rejected, (state, action) => {
            state.isError = true
            state.isSubmitting = false
            state.isSuccess = false
            state.showForm = true
            state.showAlert = true
            state.message = 'Failed to modified operational'
            // state.operational = action.payload
            console.log(action.payload)
        }).addCase(getOperational.pending, (state) => {
            state.isError = false
            state.isLoading = true
            state.isSuccess = false
            state.operational = null
        }).addCase(getOperational.fulfilled, (state, action) => {
            state.isError = false
            state.isLoading = false
            state.isSuccess = true
            state.operational = action.payload
        }).addCase(getOperational.rejected, (state, action) => {
            state.isError = true
            state.isLoading = false
            state.isSuccess = false
            state.operational = null
        }).addCase(getAssignedToMe.pending, (state) => {
            state.isError = false
            state.isLoading = true
            state.isSuccess = false
            state.operationals = []
        }).addCase(getAssignedToMe.fulfilled, (state, action) => {
            state.isError = false
            state.isLoading = false
            state.isSuccess = true
            state.operationals = action.payload
        }).addCase(getAssignedToMe.rejected, (state, action) => {
            state.isError = true
            state.isLoading = false
            state.isSuccess = false
            state.operationals = []
        }).addCase(deleteOperational.pending, (state) => {
            state.isError = false
            state.isDeleteLoading = true
            state.isSuccess = false
            state.showAlert = false
            state.message = ''
        }).addCase(deleteOperational.fulfilled, (state, action) => {
            state.isError = false
            state.isDeleteLoading = false
            state.isSuccess = true
            state.showAlert = true
            state.message = 'Successfully deleted operational'
            state.operationals = state.operationals.filter(o => o.id !== state.operational.id)
        }).addCase(deleteOperational.rejected, (state, action) => {
            state.isError = true
            state.isDeleteLoading = false
            state.isSuccess = false
            state.showAlert = true
            state.message = 'Failed to delete operational'
        })
    }
})

export const { showAlert, showForm, resetAlertState, resetFormState, showFormToggle, setOperational, setOperationalIsSubmitting } = operationalSlice.actions
export default operationalSlice.reducer
