import React from 'react'
import { Navbar } from '../../components/common/Navbar'
import { Box } from '@mui/material'
import { Outlet } from 'react-router-dom';


export const Dashboard = () => {
  return <>
    {/* <Navbar/> */}
    {/* Pages */}
    <Box marginTop={'2.2rem'} width={'100%'}>
        <Outlet/>
    </Box>
  </>
}
