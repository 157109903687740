import saveDeliverable from "./saveStrategic";

const {
    formField : {
        name,
        plannedStartDate,
        plannedDueDate,
        actualStartDate,
        actualDueDate,
        taskStatus
    }
} = saveDeliverable


export default {
    [name.name] : '',
    [plannedStartDate.name] : '',
    [plannedDueDate.name] : '',
    [actualStartDate.name] : '',
    [actualDueDate.name] : '',
    [taskStatus.name] : 'NT'
}


export const getValues = ( strategicData ) => {
    return { [name.name] : strategicData.name,
        [plannedStartDate.name] : strategicData.plannedStartDate,
        [plannedDueDate.name] : strategicData.plannedDueDate,
        [actualStartDate.name] : strategicData.actualStartDate,
        [actualDueDate.name] : strategicData.actualDueDate,
        [taskStatus.name] : strategicData.taskStatus }
}

