import { LinearProgress, Stack, Typography } from '@mui/material'
import React from 'react'

export const LinearProgressWithLabel = ({ value, variant = 'determinate' }) => {
  return (
    <Stack
        sx={{
            display : 'flex',
            flexDirection : 'row',
            alignItems : 'center'
        }}>
        <LinearProgress color='info' value={value} variant={variant} sx={{ width : '100px', height : '13px', marginRight : '5px', borderRadius : '10px' }}/>
        <Typography fontSize={13}>{`${value}%`}</Typography>
    </Stack>
  )
}
