import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import deliverableService from "../../services/deliverableService";
import strategicService from "../../services/strategicService";



const initialState = {
    isLoading : false,
    isSuccess  : false,
    isError : false,
    strategics : [],
    strategic : null,
    response : null,
    showForm : false,
    showAlert : false,
    message : '',
    isDeleteLoading : false,
    isSubmitting : false
}

export const createStrategic = createAsyncThunk(
    'strategic/create',
    async (params, thunk) => {
        try {
            const res = await strategicService.create({ data : params.data })
            return res.data
        } catch (error) {
            return thunk.rejectWithValue(error.response.data)
        }
    }
)

export const updateStrategic = createAsyncThunk(
    'strategic/update',
    async (params, thunk) => {
        try {
            const res = await strategicService.update({ updatedData : params.data })
            return res.data
        } catch (error) {
            return thunk.rejectWithValue(error.response.data)
        }
    }
)

export const getAllStrategicsByDelivery = createAsyncThunk(
    'strategic/getAllStrategicsByDelivery',
    async (params, thunk) => {
        try {
            const res = await strategicService.getAllByDeliverableId({ deliverableId : params.deliverableId, dispatch : thunk.dispatch })
            return res.data
        } catch (error) {
            return thunk.rejectWithValue(error.response.data)
        }
    }
)

export const deleteStrategic = createAsyncThunk(
    'strategic/delete',
    async (params, thunk) => {
        try {
            const res = await strategicService.deleteById({ id : params.id })
            return res.data
        } catch (error) {
            return thunk.rejectWithValue(error.response.data)
        }
    }
)

const strategicSlice = createSlice({
    name : 'strategic',
    initialState,
    reducers : {
        showForm : (state) => {
            state.showForm = true
        },
        resetFormState : (state) => {
            state.showForm = false
        },
        showAlert : (state) => {
            state.showAlert = true
        },
        resetAlertState : (state) => {
            state.showAlert = false
        },
        setStrategic : (state, action) => {
            state.strategic = action.payload
        },
        alterStrategic : (state, action) => {
            const index = state.strategics.findIndex(s => s.id == action.payload.id)
            state.strategics[index] = action.payload
        },
        setIsSubmitting : (state, action) => {
            state.isSubmitting = action.payload
        }
    },
    extraReducers : (builder) => {
        builder.addCase(createStrategic.pending, (state) => {
            state.isError = false
            state.isSuccess = false
            state.isSubmitting = true
            state.showAlert = false
            state.message = ''
        }).addCase(createStrategic.fulfilled, (state, action) => {
            state.isError = false
            state.isSuccess = true
            state.isSubmitting = false
            state.strategics.push(action.payload)
            state.showAlert = true
            state.message = 'Strategic successfully added'
            state.showForm = false
        }).addCase(createStrategic.rejected, (state) => {
            state.isError = true
            state.isSuccess = false
            state.isSubmitting = false
            state.showAlert = true
            state.message = 'Failed to add strategic'
        }).addCase(getAllStrategicsByDelivery.pending, (state) => {
            state.isError = false
            state.isSuccess = false
            state.isLoading = true
            state.strategics = []
        }).addCase(getAllStrategicsByDelivery.fulfilled, (state, action) => {
            state.isError = false
            state.isSuccess = true
            state.isLoading = false
            state.strategics = action.payload
        }).addCase(getAllStrategicsByDelivery.rejected, (state) => {
            state.isError = true
            state.isSuccess = false
            state.isLoading = false
            state.strategics = []
        }).addCase(updateStrategic.pending, (state) => {
            state.isError = false
            state.isSuccess = false
            state.isSubmitting = true
            state.showAlert = false
            state.message = ''
        }).addCase(updateStrategic.fulfilled, (state, action) => {
            state.isError = false
            state.isSuccess = true
            state.isSubmitting = false    
            state.showAlert = true
            state.message = 'Strategic successfully modified'
            state.showForm = false
            const index = state.strategics.findIndex(s => s.id == action.payload.id)
            state.strategics[index] = action.payload
        }).addCase(updateStrategic.rejected, (state) => {
            state.isError = true
            state.isSuccess = false
            state.isSubmitting = false
            state.showAlert = true
            state.message = 'Failed to modified strategic'
        }).addCase(deleteStrategic.pending, (state) => {
            state.isError = false
            state.isSuccess = false
            state.isLoading = true
            state.showAlert = false
        }).addCase(deleteStrategic.fulfilled, (state, action) => {
            state.isError = false
            state.isSuccess = true
            state.isLoading = false    
            state.showAlert = true
            state.message = 'Strategic successfully deleted'
            state.strategics = state.strategics.filter(s => s.id !== state.strategic.id)
        }).addCase(deleteStrategic.rejected, (state) => {
            state.isError = true
            state.isSuccess = false
            state.isLoading = false
            state.showAlert = true
            state.message = 'Failed to delete strategic'
        })
    }
    
})

export const { showAlert, showForm, resetAlertState, resetFormState, setStrategic, alterStrategic, setIsSubmitting } = strategicSlice.actions
export default strategicSlice.reducer