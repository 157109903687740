import axios from "axios";
import { apiUrl } from "../constants/urls";
import { handleUnauthorized } from "../features/auth/authSlice";
import httpClient from "../config/axiosConfig";



const getAll = async (dispatch, client = null) => {
    try {
        const token = JSON.parse(localStorage.getItem('auth'))?.access_token
        const res = await httpClient.get(`stakeholder/${client ? '?client=' + client : ''}`, {
            headers : {
                Authorization : `Bearer ${token}`,
            }
        })
        return res
    } catch (error) {

        if (Number(error.response.status) === 401) {
            dispatch(handleUnauthorized())
        }
        return error
    }
}


const stakeholderService = {
    getAll
}

export default stakeholderService