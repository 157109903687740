import React, { useEffect, useState } from 'react'
import { Box, Card, CardContent, CardHeader, CircularProgress, Grid, IconButton, Tooltip, Typography } from '@mui/material'
import overallstatusService from '../../services/overallstatusService'
import GaugeChart from 'react-gauge-chart'
import { getOverallStatusPercentage } from '../../helpers/percentage'
import { userRole } from '../../constants/userRole'
import { useDispatch, useSelector } from 'react-redux'
import { fetchOverallStatus } from '../../features/dashboard/dashboardSlice'
import { useLocation } from 'react-router-dom'
import queryParamHelper from '../../helpers/queryParamHelper'
import HelpIcon from '@mui/icons-material/Help';

export const OverallStatus = () => {

    // const [overallStatus, setOverallStatus] = useState(null)
    const { overallStatus } = useSelector(state => state.dashboard)
    const dispatch = useDispatch()
    const location = useLocation()
    const query = queryParamHelper.getQueryParam('selectedClient', location.search)
    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('auth'))

        let clientId = (user.role_name == userRole.CLIENT_USER || user.role_name == userRole.CLIENT_ADMIN) ? user.client : ''
        
        if(query){
            clientId = query.value
        }
        dispatch(fetchOverallStatus({ clientId }))
    }, [])
  return (
    <Box width={'100%'}>
        <Card sx={{
            borderRadius: '1rem',
            width : '100%',
            minHeight : '40vh'
        }}>
            {/* .-. -- -... Tooltip Over All Status*/}
            <Grid container alignItems="center" justifyContent="flex-start" sx={{paddingLeft: '5px'}}>
                <Grid item>
                    <Typography variant="h6">OVERALL STATUS</Typography>
                </Grid>
                <Grid item>
                <Tooltip
                    title={
                        <>
                        Assessment of the overall health of the tasks for each client. <br />
                        <span style={{ fontWeight: 'bold', color: 'var(--tooltip-font-green)' }}>On Time</span> - Project is progressing as scheduled, and the current status aligns with the planned timeline. <br />
                        <span style={{ fontWeight: 'bold', color: 'var(--tooltip-font-yellow-green)' }}>Overdue</span> - There are tasks that are expected to be completed by a certain date, but for some reason, they have not been finished within the designated time-frame. <br />
                        <span style={{ fontWeight: 'bold', color: 'var(--tooltip-font-yellow)' }}>Behind</span> - Project is progressing as per the initial plan, and there might be a risk of missing the original deadline if the situation is not addressed promptly. <br />
                        <span style={{ fontWeight: 'bold', color: 'var(--tooltip-font-red)' }}>Critical</span> - Project might be facing severe issues or challenges that pose a significant threat to the successful completion of the project.
                        </>
                    }
                    placement="right-start"
                >
                <HelpIcon size="small" fontSize="small" sx={{paddingLeft: '5px', color: 'var(--tooltip-bg-color)'}}/>
                </Tooltip>

                </Grid>
            </Grid>
            <CardContent sx={{
                position : 'relative',
                display : 'flex',
                justifyContent : 'center',
                alignItems : 'center',
                marginTop : '4vh'
            }}>
                {/* <CircularProgress variant='determinate' value={95} size={230} color={'info'}/>
                <Box sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right : 0,
                    left: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    }}>
                    <Typography variant="caption"
                        component="div"
                        color="text.secondary"
                        fontSize={'1rem'}>{overallStatus?.status}</Typography>
                </Box> */}
                <GaugeChart 
                    id="gauge-chart1" animate={false} 
                    nrOfLevels={4} 
                    hideText={true}
                    percent={getOverallStatusPercentage(overallStatus?.status)}/>
                
                    <Typography sx={{ position : 'absolute', bottom : '4' }}>{overallStatus?.status}</Typography>
            </CardContent>
        </Card>
    </Box>
  )
}
