import axios from "axios";
import { apiUrl } from "../constants/urls";
import store from "../features/store";
import { handleUnauthorized } from "../features/auth/authSlice";

const httpClient = axios.create({
    baseURL : apiUrl
})

httpClient.interceptors.response.use(
    (res) => res,
    (err) => {
        switch(err.response.status){
            case 401:
                store.dispatch(handleUnauthorized())
                break;
            default:
                break;
        }

        return Promise.reject(err)
    }
)

export default httpClient