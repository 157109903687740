import React from 'react'
import { OperationalTaskResult, StrategicTaskResult, TacticalTaskResult } from './TaskSearchedResult'

export const TaskSearchedResultContainer = ({ searchedResult, onClick }) => {
  

    if(searchedResult.type === 'strategic') {
        return <>
            <StrategicTaskResult strategic={searchedResult} onClick={onClick}/>
        </>
    }else if(searchedResult.type === 'tactical') {
        return <>
            <TacticalTaskResult tactical={searchedResult} onClick={onClick}/>
        </>
    }else{
        return <>
            <OperationalTaskResult operational={searchedResult} onClick={onClick}/>
        </>
    }
}
