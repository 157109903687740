import { Box, CircularProgress } from '@mui/material'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { SelectInput } from '../../common/SelectInput'
import operationalService from '../../../services/operationalService'

export const ListAndAssignStakeholder = ( { operational, disabled = false } ) => {
    const [assignedStakeholderId, setAssignedStakeholderId] = useState(!operational.stakeholder?.id ? 0 : operational.stakeholder.id)
    const { stakeholders, isLoading } = useSelector(state => state.stakeholder)
    const [assigning, setAssigning] = useState(false)
  return (
    <Box sx={{
        display : 'flex',
        justifyContent : 'center',
        alignItems : 'center',
        width : '100%'
    }}>
        {(isLoading || assigning) ? <>
            <CircularProgress color='info' size={20}/>
        </> : <SelectInput 
            disbaled={disabled}
            options={[...stakeholders.map((stakeholder) => ({ keyVal : stakeholder.id, value : stakeholder.name })), { keyVal : 0, value : 'No Assignee' }]} 
            label={'Assignee'}
            defaultVal={assignedStakeholderId}
            onChange={(value) => {
                if(value === 0) return
                setAssigning(true)
                operationalService.assignStakeholder({ data : { stakeholder_id : value, operational_task_id : operational.id } })
                    .then((res) => {
                        setAssigning(false)
                        setAssignedStakeholderId(res.data.stakeholder.id)
                        console.log(res)
                    })
                    .catch((err) => {
                        setAssigning(false)
                        setAssignedStakeholderId((prev) => (prev))
                    })
            }}
            />}
    </Box>
  )
}
