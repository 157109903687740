import React, { useState, useEffect } from 'react'
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';
import InfoIcon from '@mui/icons-material/Info';
import { Box, Button, IconButton, Modal, Paper, Stack, Typography, Tooltip,  } from '@mui/material'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Timeline } from '../../../components/dashboard/Timeline'
import { DeliveryRisk } from '../../../components/dashboard/DeliveryRisk'
import { ApproachingAndOverdueTask } from '../../../components/dashboard/ApproachingAndOverdueTask'
import { useNavigate } from 'react-router-dom';
import clientInformationService from '../../../services/clientInformationService';
import { Close, CloseOutlined } from '@mui/icons-material';
import { OverallStatus } from '../../../components/dashboard/OverallStatus';
import ApexCharts from 'react-apexcharts';
import projectWorkingService from '../../../services/projectWorkingService';
import { useDispatch, useSelector } from 'react-redux';
import { OperationalTaskTable } from '../../../components/task/OperationalTaskTable';
import operationalService from '../../../services/operationalService';
import { OperationalTaskDetails } from '../../../components/task/OperationalTaskDetails';
import { userRole } from '../../../constants/userRole';
import HelpIcon from '@mui/icons-material/Help';


export const EntryPoint = () => {
    const [clients, setClients] = useState([])
    const [projectWorking, setProjectWorking] = useState([])
    const { clients : clientInfos } = useSelector(state => state.clientInfo)
    const [operationalTasks, setOperationalTasks] = useState([])
    const [showTaskDetails, setShowTaskDetails] = useState(false)
    const [operationalHeaderTitle, setOperationalHeaderTitle] = useState('')
    const [taskDetails, setTaskDetails] = useState(null)
    const [ongoingTasks, setOngoingTasks] = useState([])
    const [ongoingTaskLoading, setOngoingTaskLoading] = useState(false)
    const dispatch = useDispatch()
    
    var options = {
        
        legend: {
        show: true
        },
        chart: {
            height: 400,
            width : 600,
            type: 'treemap',
            toolbar : {
                show : false,
            },
            events: {
                click: (event, chartContext, config) => {

                    if(chartContext.data.twoDSeriesX[config.dataPointIndex] === undefined) return
                    // console.log(chartContext.data.twoDSeriesX[config.dataPointIndex]);
                    onViewProjectWorking(chartContext.data.twoDSeriesX[config.dataPointIndex])
                }
            },
        },
        title: {
            text: 'Ongoing Tasks',
            style : {
                color :  '#ffffff',
                fontSize : '26px',
            }
        },
        colors: [
            '#f44336',
            '#11A0DB',
            '#F99D1B',
            '#67AD45'
        ],
        dataLabels: {
            enabled: true,
            offsetY: -4,
            style : {
                fontSize : '12px',
                color : '#000000',
            }
        },
        plotOptions: {
            treemap: {
                distributed: true,
                enableShades: false,
                foreColor: '#000000',
            }
        }
    };

    /* console.log(clientInfos) */
    const onViewProjectWorking = (data) => {
        // if((JSON.parse(localStorage.getItem('auth')).role_name !== userRole.CLIENT_ADMIN && JSON.parse(localStorage.getItem('auth')).role_name !== userRole.CLIENT_USER)){
        //     setOperationalsShowModal(true)
        // }
        setOperationalHeaderTitle('Ongoing tasks')
        operationalService.getAllOngoing({ client : data })
            .then((res) => {
                if((JSON.parse(localStorage.getItem('auth')).role_name !== userRole.CLIENT_ADMIN && JSON.parse(localStorage.getItem('auth')).role_name !== userRole.CLIENT_USER)){
                    setOperationalsShowModal(true)
                }
                setOperationalTasks(res.data)
            })
            .catch(err => console.log(err))
    }

    const fetchOngoingTasks = (clientName) => {
        
        setOperationalHeaderTitle('Ongoing tasks')
        setOngoingTaskLoading(true)
        operationalService.getAllOngoing({ client : clientName })
            .then((res) => {
                setOngoingTaskLoading(false)
                setOngoingTasks(res.data)
            })
            .catch(err => console.log(err))
    }

    useEffect(() => {
        
        clientInformationService.getAll()
            .then((res) => {
                setClients(res.data)
                /* console.log(res.data) */
                // dispatch(setClients(res.data))
            })
            .catch((err) => console.log(err))

        projectWorkingService.fetchAllProjectWorking()
            .then((res) => setProjectWorking(res.data.map(pw => ({ x : pw.client_name, y : pw.working, meta : pw }))))
            .catch((err) => console.log(err))
        
        if((JSON.parse(localStorage.getItem('auth')).role_name === userRole.CLIENT_ADMIN || JSON.parse(localStorage.getItem('auth')).role_name === userRole.CLIENT_USER)){
            fetchOngoingTasks(JSON.parse(localStorage.getItem('auth')).client_data.name)
        }
      return () => {
        
      }
    }, [])
    

    const [clientModalShow, setClientModalShow] = useState(false)
    const [operationalsShowModal, setOperationalsShowModal] = useState(false)

    const navigate = useNavigate()
  return (
    <Box width={'100%'} display={'flex'} flexDirection={'column'} paddingX={'2rem'}>
        
        <Box width={'100%'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
            <ApproachingAndOverdueTask onClick={(operationals, type) => {
                /* console.log(operationals) */
                setOperationalTasks(operationals)
                setOperationalsShowModal(true)
                switch(type){
                    case 'approaching':
                        setOperationalHeaderTitle('Tasks Approaching (+14 days)')
                        break
                    case 'overdue':
                        setOperationalHeaderTitle('Tasks Overdue')
                        break
                    default:
                        break;
                }
            }}/>
            {/* <OverallStatus/> */}
        </Box>
        {/* <Box width={'100%'} display={'flex'} justifyContent={'center'} alignItems={'center'} >
            <DeliveryRisk/>
            <Timeline onShowClients={() => setClientModalShow(true)}/>
        </Box> */}

        <Box width={'100%'} display={'flex'} justifyContent={'center'} alignItems={'center'} >
            {(JSON.parse(localStorage.getItem('auth')).role_name === userRole.CLIENT_ADMIN || JSON.parse(localStorage.getItem('auth')).role_name === userRole.CLIENT_USER) ? 
                <>
                    {ongoingTaskLoading ? <></> : <Paper sx={{
                        minHeight : '40px',
                        padding : '2rem',
                        width : '100%'
                    }}>
                        <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} marginBottom={'2rem'}>
                            <Typography fontSize={'20px'} fontWeight={'bold'}>{operationalHeaderTitle}</Typography>
                        </Stack>
                        <Stack 
                            sx={{  
                                minHeight : '30vh',
                                overflow: 'auto',
                                overflowY : 'auto',
                                maxHeight : '30vh',
                                width : '100%'
                            }}>
                            <OperationalTaskTable operationals={ongoingTasks} onClick={(operational) => {
                                setTaskDetails(operational)
                                setShowTaskDetails(true)
                            }}/>
                        </Stack>
                    </Paper>}
                </> : 
                <>
                <div style={{ position: 'relative' }}>
                  {/* .-. -- -... Tooltip*/}
                    <div style={{
                    position: 'absolute',
                    top: '5px',
                    left: '224px',
                    zIndex: 1,
                    }}>
                        <Tooltip
                        title="Shows the tasks in progress, allowing stakeholders to monitor performance and make informed decisions based on the latest developments."
                        placement="right-start"
                        >
                            <HelpIcon size="small" fontSize="small" sx={{paddingLeft: '5px', color: 'var(--tooltip-bg-color)'}}/>
                        </Tooltip>
                    </div>
                    <ApexCharts options={options} width={1810} height={360} type='treemap' series={[{ data: projectWorking }]} />
                </div>
                </>

            }
            
        </Box>

        <Modal open={clientModalShow} onClose={() => setClientModalShow(false)} sx={{
            display : 'flex',
            justifyContent : 'center',
            alignItems : 'center',
        }}>
            <Box
                width={'40%'} sx={{
                    display : 'flex',
                    justifyContent : 'center',
                }}>
                <Paper sx={{
                    width : '100%',
                    padding : '2rem',
                    borderRadius : '2rem',
                }}>
                    <Box sx={{
                        display : 'flex',
                        justifyContent : 'space-between',
                        alignItems : 'center'
                    }}>
                        <Typography variant='h5' fontWeight={'bold'}>CLIENTS</Typography>
                        <IconButton onClick={() => setClientModalShow(false)}>
                            <CloseOutlined/>
                        </IconButton>
                    </Box>

                    <Box>
                        <TableContainer component={Paper} 
                            sx={{ 
                                marginleft : '1rem', 
                                marginTop : '1rem', 
                                borderRadius : '20px', 
                                minHeight : '60vh',
                                overflow: 'auto',
                                overflowY : 'auto',
                                maxHeight : '60vh' 
                            }}>
                            <Table sx={{ width : '100%' }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>CLIENT</TableCell>
                                        <TableCell align="left">ACTION</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {clients?.map((client, i) => (
                                        <TableRow
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        key={i}
                                        >
                                            <TableCell component="th" scope="row">
                                                {client.name}
                                            </TableCell>
                                            <TableCell align="left">
                                                <Button onClick={() => {
                                                    navigate(`/client-details/${client.id}`)
                                                }} size='small' color='info' variant='contained' startIcon={<InfoIcon/>}>Details</Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Paper>
            </Box>
        </Modal>
        <Modal open={operationalsShowModal} onClose={() => {
            setOperationalHeaderTitle('Ongoing Tasks')
            setOperationalsShowModal(false)
        }} sx={{
            padding : '2rem'
        }}>
            <Paper sx={{
                minHeight : '95vh',
                padding : '2rem'
            }}>
                <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} marginBottom={'2rem'}>
                    <Typography fontSize={'20px'} fontWeight={'bold'}>{operationalHeaderTitle}</Typography>
                    <IconButton size='small' onClick={() => {
                        setOperationalHeaderTitle('Ongoing Tasks')
                        setOperationalsShowModal(false)
                    }}>
                        <Close fontSize='small'/>
                    </IconButton>
                </Stack>
                <Stack 
                    sx={{  
                        minHeight : '80vh',
                        overflow: 'auto',
                        overflowY : 'auto',
                        maxHeight : '60vh' 
                    }}>
                    <OperationalTaskTable operationals={operationalTasks} onClick={(operational) => {
                        setTaskDetails(operational)
                        setShowTaskDetails(true)
                    }}/>
                </Stack>
            </Paper>
        </Modal>
        <Modal open={showTaskDetails} onClose={() => setShowTaskDetails(false)} sx={{ padding : '2rem' }}>
            <Paper sx={{
                padding : '3rem'
            }}>
                <Stack flexDirection={'row'} sx={{
                    paddingBottom : '2rem',
                    justifyContent : 'space-between',
                    alignItems : 'center'
                }}>
                    <Typography>{taskDetails?.tactical_task.strategic_task.delivery.client_info.name}</Typography>
                    <IconButton size='small' onClick={() => setShowTaskDetails(false)}>
                        <Close fontSize='small'/>
                    </IconButton>
                </Stack>
                <Stack>
                    <OperationalTaskDetails operationalTask={taskDetails} panelHeight='50vh'/>
                </Stack>
            </Paper>
        </Modal>
    </Box>
  )
}
