import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import deliverableService from "../../services/deliverableService";



const initialState = {
    isLoading : false,
    isSuccess  : false,
    isError : false,
    deliverables : [],
    deliverable : null,
    response : null,
    showForm : false,
    showAlert : false,
    alertMessage : '',
    deliverableId : null,
    isDeleteLoading : false,
}


export const getAllDeliverablesByClient = createAsyncThunk(
    'deliverable/getAllByClient',
    async (params, thunk) => {
        try {
            const res = await deliverableService.getAllByClientId( { clientId : params.clientInfoId, dispatch : thunk.dispatch } )
            return res.data
        } catch (error) {
            return thunk.rejectWithValue(error.response.data)
        }
    }
)

export const createClientDeliverable = createAsyncThunk(
    'deliverable/create',
    async (params, thunk) => {
        try {
            const res = await deliverableService.create({ data : params.data, dispatch : thunk.dispatch })
            return res.data
        } catch (error) {
            return thunk.rejectWithValue(error.response.data)
        }
    }
)

export const updateClientDeliverable = createAsyncThunk(
    'deliverable/update',
    async (params, thunk) => {
        try {
            const res = await deliverableService.update({ updatedData : params.data, dispatch : thunk.dispatch })
            return res.data
        } catch (error) {
            return thunk.rejectWithValue(error.response.data)
        }
    }
)

export const deleteClientDeliverable = createAsyncThunk(
    'deliverable/delete',
    async (params, thunk) => {
        try {
            const res = await deliverableService.deleteById({ id : params.id })
            return res.data
        } catch (error) {
            return thunk.rejectWithValue(error.response.data)
        }
    }
)

const deliverableSlice = createSlice({
    name : 'deliverable',
    initialState,
    reducers : {
        resetFormState : (state) => {
            state.showForm = false
        },
        resetAlertState : (state) => {
            state.showAlert = false
        },
        showForm : (state) => {
            state.showForm = true
        },
        setDeliverableId : (state, action) => {
            state.deliverableId = action.payload
        },
        alterDeliverable : (state, action) => {
            const index = state.deliverables.findIndex(x => x.id === action.payload.id)
            state.deliverables[index] = action.payload
        }
    },
    extraReducers : (builder) => {
        builder.addCase(getAllDeliverablesByClient.pending, (state) => {
            state.deliverables = []
            state.isError = false
            state.isSuccess = false
            state.isLoading = true
            state.response = null
        }).addCase(getAllDeliverablesByClient.fulfilled, (state, action) => {
            state.deliverables = action.payload
            console.log(action)
            state.isError = false
            state.isSuccess = true
            state.isLoading = false
        }).addCase(getAllDeliverablesByClient.rejected, (state, action) => {
            state.deliverables = []
            state.isError = true
            state.isSuccess = false
            state.isLoading = false
            state.response = action.payload
        }).addCase(createClientDeliverable.pending, (state) => {
            // state.deliverables = []
            state.isError = false
            state.isSuccess = false
            state.isLoading = true
            state.response = null
            state.showForm = true
            state.showAlert = false
        }).addCase(createClientDeliverable.fulfilled, (state, action) => {
            // state.deliverables = action.payload
            console.log(action.payload)
            state.deliverables.push(action.payload)
            state.isError = false
            state.isSuccess = true
            state.isLoading = false
            state.response = action.payload
            state.showForm = false
            state.showAlert = true
            state.alertMessage = 'Deliverable added successfully!'
        }).addCase(createClientDeliverable.rejected, (state, action) => {
            state.isError = true
            state.isSuccess = false
            state.isLoading = false
            state.response = action.payload
            state.showForm = true
            state.showAlert = true
            state.alertMessage = 'Failed to add deliverable'
        }).addCase(updateClientDeliverable.pending, (state) => {
            // state.deliverables = []
            state.isError = false
            state.isSuccess = false
            state.isLoading = true
            state.response = null
            // state.showForm = true
            state.showAlert = false
        }).addCase(updateClientDeliverable.fulfilled, (state, action) => {
            // state.deliverables = action.payload
            state.isError = false
            state.isSuccess = true
            state.isLoading = false
            state.response = action.payload
            state.showForm = false
            state.showAlert = true
            const index = state.deliverables.findIndex(d => d.id == action.payload.id)
            state.deliverables[index] = action.payload
            state.alertMessage = 'Deliverable modified successfully!'
        }).addCase(updateClientDeliverable.rejected, (state, action) => {
            state.isError = true
            state.isSuccess = false
            state.isLoading = false
            state.response = action.payload
            state.showForm = true
            state.showAlert = true
            state.alertMessage = 'Delivarable failed to modified'
        }).addCase(deleteClientDeliverable.pending, (state) => {
            state.isError = false
            state.isSuccess = false
            state.isDeleteLoading = true
            state.showAlert = false
        }).addCase(deleteClientDeliverable.fulfilled, (state, action) => {
            state.isError = false
            state.isSuccess = true
            state.isDeleteLoading = false
            state.showAlert = true
            state.alertMessage = 'Deliverable deleted successfully!'
            state.deliverables = state.deliverables.filter(d => d.id !== state.deliverableId)
        }).addCase(deleteClientDeliverable.rejected, (state, action) => {
            state.isError = true
            state.isSuccess = false
            state.isDeleteLoading = false
            state.showAlert = true
            state.alertMessage = 'Failed to delete deliverable!'
        })
    }
})

export const { resetFormState, resetAlertState, showForm, setDeliverableId, alterDeliverable } = deliverableSlice.actions
export default deliverableSlice.reducer
