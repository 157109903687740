import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import stakeholderService from "../../services/stakeholderService";



const initialState = {
    isLoading : false,
    isSuccess  : false,
    isError : false,
    stakeholders : [],
    stakeholder : null,
    response : null
}

export const getAllStakeholder = createAsyncThunk(
    'stakeholder/getAll',
    async (params, thunk) => {
        try {
            const res = await stakeholderService.getAll(thunk.dispatch, (params?.client ? params.client : null))
            return res.data
        } catch (error) {
            return thunk.rejectWithValue(error.response.data)
        }
    }
)

const stakeholderSlice = createSlice({
    name : 'stakeholder',
    initialState,
    extraReducers : (builder) => {
        builder.addCase(getAllStakeholder.pending, (state) => {
            state.isLoading = true
            state.isSuccess = false
            state.isError = false
            state.stakeholders = []
            state.stakeholder = null
            state.response = null
        }).addCase(getAllStakeholder.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.isError = false
            state.stakeholders = action.payload
        }).addCase(getAllStakeholder.rejected, (state) => {
            state.isLoading = false
            state.isSuccess = false
            state.isError = true
            state.stakeholders = []
            state.stakeholder = null
            state.response = null
        })
    }
})


export default stakeholderSlice.reducer