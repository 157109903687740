import { Avatar, Box, Chip, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAssignedToMe } from '../../features/operational/operationalSlice'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { OperationalTaskTable } from '../../components/task/OperationalTaskTable'

export const Assignedme = () => {

  const dispatch = useDispatch()
  const { isLoading : operationalLoading, operationals } = useSelector(state => state.operational)
  const navigate = useNavigate()
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('auth'))
    dispatch(getAssignedToMe({ assigneeId : user.id }))
  }, [dispatch])

  return (
    <Box 
      sx={{
        width : '100%',
        padding : '3rem'
      }}>
        <Typography fontSize={'25px'} fontWeight={'700'}>My Tasks</Typography>
        <Paper 
          sx={{
            padding : '2rem',
            marginTop : '1.5rem'
          }}
          elevation={10}
        >
          {operationalLoading ? <></> : <Box>
            <Box>
              <Typography marginBottom={'1rem'} fontSize={'14px'} textTransform={'uppercase'} fontWeight={'400'}>Operational Tasks</Typography>
            </Box>
            <OperationalTaskTable operationals={operationals} onClick={(operational) => {
              navigate(`/client-details/${operational.tactical_task.strategic_task.delivery.client_info.id}?operationalId=${operational.id}&redirectTo=assigned-me`)
            }}/>
          </Box>}
        </Paper>
    </Box>
  )
}
