import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import operationalCommentService from "../../services/operationalCommentService";
import STATE_STATUS from "../../constants/status";


const initialState = {
    status : 'idle',
    comments : [],
}

export const createOperationalComment = createAsyncThunk(
    'operational/task/create',
    async (params, thunk) => {
        try {
            const res = await operationalCommentService.createComment({ data : params.data })
            return res.data
        } catch (error) {
            return thunk.rejectWithValue(error.response.data)
        }
    }
)

export const getAllOperationalComments = createAsyncThunk(
    'operational/task/getAll',
    async (params, thunk) => {
        try {
            const res = await operationalCommentService.getAll({ operationalId : params.operationalId })
            return res.data
        } catch (error) {
            return thunk.rejectWithValue(error.response.data)
        }
    }
)

const operationalTaskCommentSlice = createSlice({
    name : 'operationalTaskComment',
    initialState,
    extraReducers : (builder) => {
        builder.addCase(createOperationalComment.pending, (state) => {
            state.status = STATE_STATUS.LOADING
        })
        .addCase(createOperationalComment.fulfilled, (state, action) => {
            state.status = STATE_STATUS.SUCCESS
            state.comments.push(action.payload)
        })
        .addCase(createOperationalComment.rejected, (state) => {
            state.status = STATE_STATUS.FAILED
        }).addCase(getAllOperationalComments.pending, (state) => {
            state.status = STATE_STATUS.LOADING
        })
        .addCase(getAllOperationalComments.fulfilled, (state, action) => {
            state.status = STATE_STATUS.SUCCESS
            state.comments = action.payload
        })
        .addCase(getAllOperationalComments.rejected, (state) => {
            state.status = STATE_STATUS.FAILED
        })
    }
})

export default operationalTaskCommentSlice.reducer