import axios from "axios"
import { apiUrl } from "../constants/urls"
import httpClient from "../config/axiosConfig"


const createOperationalAttachment = async ({ data }) => {
    try {
        const token = JSON.parse(localStorage.getItem('auth'))?.access_token
        // const res = await axios.post(`${apiUrl}taskattachment/`, data)
        const res = await httpClient.post(`taskattachment/`, data, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        return res
    } catch (error) {
        return error
    }
}

const getAllOperationalAttachments = async ({ operationalId = null }) => {
    try {
        const token = JSON.parse(localStorage.getItem('auth'))?.access_token
        // const res = await axios.get(`${apiUrl}taskattachment${operationalId != null && `?operational_task_id=${operationalId}`}`)
        const res = await httpClient.get(`taskattachment${operationalId != null && `/?operational_task_id=${operationalId}`}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        return res
    } catch (error) {
        return error
    }
}

const deleteOperationalAttachment = async ({ id }) => {
    
    try {

        const token = JSON.parse(localStorage.getItem('auth'))?.access_token
        const res = await httpClient.delete(`taskattachment/${id}`, {
            headers : {
                Authorization: `Bearer ${token}`
            }
        })
        return res
    } catch (error) {
        return error
    }

}


const taskAttachmentService = {
    createOperationalAttachment,
    getAllOperationalAttachments,
    deleteOperationalAttachment
}

export default taskAttachmentService