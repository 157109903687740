import axios from "axios";
import { apiUrl } from "../constants/urls";


const updateStatus = async ({ data }) => {
    try {
        const res = await axios.put(`${apiUrl}task/update-status`, data)
        return res
    } catch (error) {
        return error
    }
}

const getTasksApproaching14Days = async ({ clientId = null }) => {
    try {
        const res = await axios.get(`${apiUrl}task/approaching${clientId ? `?client_id=${clientId}` : ''}`)
        return res
    } catch (error) {
        return error
    }
}

const getTasksOverdue = async ({ clientId = null }) => {
    try {
        const res = await axios.get(`${apiUrl}task/overdue${clientId ? `?client_id=${clientId}` : ''}`)
        return res
    } catch (error) {
        return error
    }
}


const tasksService = {
    updateStatus,
    getTasksApproaching14Days,
    getTasksOverdue
}

export default tasksService