import axios from "axios";
import { apiUrl } from "../constants/urls";




const login = async ({ userCredentials }) => {
    try {
        const res = await axios.post(`${apiUrl}auth/login`, userCredentials)
        return res
    } catch (error) {
        return error
    }
}

const authService = {
    login
}

export default authService