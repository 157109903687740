import axios from "axios";  
import { apiUrl } from "../constants/urls";
import { handleUnauthorized } from "../features/auth/authSlice";
import httpClient from "../config/axiosConfig";

const getAll = async ({ strategicId = null, dispatch }) => {
    try{
        const token = JSON.parse(localStorage.getItem('auth'))?.access_token
        const res = await axios.get(`${apiUrl}tacticaltask${strategicId ? `?strategic_task_id=${strategicId}` : ''}`, {
            headers : {
                Authorization : `Bearer ${token}`
            }
        })
        return res
    }catch(error){
        if (Number(error.response.status) === 401) {
            dispatch(handleUnauthorized())
        }
        return error
    }
}

const create = async ({ data, dispatch }) => {
    try {
        const token = JSON.parse(localStorage.getItem('auth'))?.access_token
        const res = await axios.post(`${apiUrl}tacticaltask/`, data, {
            headers : {
                Authorization : `Bearer ${token}`
            }
        })
        return res
    } catch (error) {

        if (Number(error.response.status) === 401) {
            dispatch(handleUnauthorized())
        }
        return error
    }
}

const update = async ({ updatedData, dispatch }) => {
    try {
        const token = JSON.parse(localStorage.getItem('auth'))?.access_token
        const res = axios.put(`${apiUrl}tacticaltask/update/`, updatedData, {
            headers : {
                Authorization : `Bearer ${token}`
            }
        })
        return res
    } catch (error) {
        if (Number(error.response.status) === 401) {
            dispatch(handleUnauthorized())
        }
        return error
    }
}

const deleteById = async ({ id }) => {
    try {
        const token = JSON.parse(localStorage.getItem('auth'))?.access_token
        const res = await httpClient.delete(`tacticaltask/delete/${id}/`, {
            headers : {
                Authorization : `Bearer ${token}`
            }
        })
        return res
    } catch (error) {
        return error
    }
}


const tacticalService = {
    getAll,
    create,
    update,
    deleteById
}

export default tacticalService