


const getQueryParam = (term, searchSplit) => {
    let results = []

    if(!searchSplit) return null

    const queryParams = searchSplit.split('?').filter(s => s != '')[0]
    const terms = queryParams.split('&')
    for(const term of terms){
        const items = term.split('=')
        const result = {
            term : items[0],
            value : items[1]
        }
        results.push(result)
        // console.log(items)
    }
    
    return results.find(r => r.term === term)
}

const queryParamHelper = {
    getQueryParam
}

export default queryParamHelper