import httpClient from "../config/axiosConfig"


const getAllComments = async ({ strategicId }) => {
    try {
        const token = JSON.parse(localStorage.getItem('auth'))?.access_token
        const res = await httpClient.get(`strategictaskcomments/?strategic_id=${strategicId}`, {
            headers : {
                Authorization : `Bearer ${token}`
            }
        })
        return res
    } catch (error) {
        throw new Error('Something went wrong')
    }
}

const createComment = async ({ data }) => {
    try {
        const token = JSON.parse(localStorage.getItem('auth'))?.access_token
        const res = httpClient.post('strategictaskcomments/', data, {
            headers : {
                Authorization : `Bearer ${token}`
            }
        })
        return data
    } catch (error) {
        throw new Error('Something went wrong')
    }
}

const deleteComment = async ({ id }) => {
    try {
        const token = JSON.parse(localStorage.getItem('auth'))?.access_token
        const res = httpClient.delete(`strategictaskcomments/${id}/`, {
            headers : {
                Authorization : `Bearer ${token}`
            }
        })
        return res
    } catch (error) {
        throw new Error('Something went wrong')
    }
}

const strategictaskCommentService = {
    getAllComments,
    createComment,
    deleteComment
}

export default strategictaskCommentService