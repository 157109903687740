import axios from "axios"
import { apiUrl } from "../constants/urls"
import httpClient from "../config/axiosConfig"



const getAll = async ({ operationalId = null }) => {
    try {
        const token = JSON.parse(localStorage.getItem('auth'))?.access_token
        // const res = await axios.get(`${apiUrl}taskcomment${operationalId && `?operational_task_id=${operationalId}`}`)
        const res = httpClient.get(`taskcomment${operationalId && `?operational_task_id=${operationalId}`}`, {
            headers : {
                Authorization : `Bearer ${token}`
            }
        })
        return res
    } catch (error) {
        return error
    }
}

const createComment = async ({ data }) => {
    try {
        const token = JSON.parse(localStorage.getItem('auth'))?.access_token
        // const res = await axios.post(`${apiUrl}taskcomment/`, data)
        const res = await httpClient.post(`taskcomment/`, data, {
            headers : {
                Authorization : `Bearer ${token}`
            }
        })
        return res
    } catch (error) {
        return error
    }
}

const deleteComment = async ({ id }) => {
    try {
        const token = JSON.parse(localStorage.getItem('auth'))?.access_token
        // const res = await axios.post(`${apiUrl}taskcomment/`, data)
        const res = await httpClient.delete(`taskcomment/${id}/`, {
            headers : {
                Authorization : `Bearer ${token}`
            }
        })
        return res
    } catch (error) {
        return error
    }
}

const operationalCommentService = {
    getAll,
    createComment,
    deleteComment
}

export default operationalCommentService