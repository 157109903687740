import React from 'react'
import { Attachment } from './Attachment'

export const AttachmentList = ({ attachments, onDelete }) => {
  return (
    <>
        {attachments?.map((attachment) => (
          <Attachment desc={attachment.attachment_desc == undefined ? '' : attachment.attachment_desc} url={attachment.attachment_url} key={attachment.id} id={attachment.id} onDelete={onDelete}/>
        ))}
    </>
  )
}
