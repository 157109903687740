
import * as Yup from 'yup'
import saveOperational from './saveOperational'

const {
    formField : {
        name,
        plannedStartDate,
        plannedDueDate,
        actualStartDate,
        actualDueDate,
        taskStatus
    }
} = saveOperational



const saveOperationalValidator = Yup.object()
    .shape({
        [name.name] : Yup.string().required(name.requiredErrorMsg),
        [plannedStartDate.name] : Yup.string().nullable().optional(),
        [plannedDueDate.name] : Yup.string().required(plannedDueDate.requiredErrorMsg),
        [actualDueDate.name] : Yup.string().nullable().optional(),
        [actualStartDate.name] : Yup.string().nullable().optional(),
        [taskStatus.name] : Yup.string().required('Status is required')
    })


export default saveOperationalValidator