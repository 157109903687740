import React, { useRef } from 'react'
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { Box, IconButton, Tooltip } from '@mui/material';


export const FilePicker = ({ getData }) => {
    const file = useRef(null)
  return (
    <Box>
        <Tooltip title='Choose Files'>
            <IconButton color='secondary' onClick={() => {
                file.current.click()
            }}>
                <FileUploadIcon color='action'/>
            </IconButton>
        </Tooltip>
        <input hidden type='file' multiple ref={file} onChange={(e) => {
            getData(e.currentTarget.files)
        }}/>
    </Box>
  )
}
