import * as Yup from 'yup'
import saveClientInformation from "./saveClientInformation";

const {
    formField : {
        name,
        accountManager,
        missionStatement,
        premium,
        reportingPeriod,
        securityChampion,
        startDate,
        secopsSME,
        customerSuccessRep
    }
} = saveClientInformation


const saveClientInformationValidator = Yup.object()
    .shape({
        [name.name] : Yup.string().required(name.requiredErrorMsg),
        [accountManager.name] : Yup.string().required('Account manager name is required'),
        [missionStatement.name] : Yup.string().required('Mission statement is required'),
        [premium.name] : Yup.boolean(),
        [reportingPeriod.name] : Yup.string().required('Reporting period is required'),
        [securityChampion.name] : Yup.string().required('Security champion is required'),
        [startDate.name] : Yup.string().required('Start date is required'),
        [secopsSME.name] : Yup.string().nullable().optional(),
        [customerSuccessRep.name] : Yup.string().nullable().optional()
    })


export default saveClientInformationValidator