const values = [{ status : 'Low', value : 0.12 }, { status : 'Medium', value : 0.37 }, { status : 'High', value : 0.63 }, { status : 'Critical', value : 0.88 }]


export const getDeliveryRiskPercentage = (status) => {
    
    if(status == "Low"){
        return 0.12
    }else if(status == 'Medium'){
        return 0.37
    }else if(status == 'High'){
        return 0.63
    }else if(status === "Critical"){
        console.log(status)
        return 0.88
    }
}

export const getOverallStatusPercentage = (status) => {
    
    if(status == "On Time"){
        return 0.12
    }else if(status == 'Overdue'){
        return 0.37
    }else if(status == 'Behind'){
        return 0.63
    }else if(status === "Critical"){
        console.log(status)
        return 0.88
    }
}


