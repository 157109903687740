
import { Box, Button, CircularProgress, FormControl, IconButton, InputLabel, MenuItem, Modal, Paper,Select,TextField, Tooltip, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';

import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import AddIcon from '@mui/icons-material/Add';
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import React, { useState, forwardRef } from 'react'
import { useParams } from 'react-router-dom'
import { clients, deliverables, strategicTasks } from '../../../../sampleData'
import { DateTimeField } from '@mui/x-date-pickers';
import { Form, Formik } from 'formik';
import initialValues, { getValues } from '../../../../validators/tactical/initialValues';
import { InputField } from '../../../common/InputField';
import { DatePickerField } from '../../../common/DatePickerField';
import saveTactical from '../../../../validators/tactical/saveTactical';
import saveTacticalValidator from '../../../../validators/tactical/saveTacticalValidator';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { parseDate } from '../../../../helpers/dateformatter';

const statuses = [{ keyVal : 'NT', value : 'Not Yet Started' }, { keyVal : 'IP', value : 'In Process' }, { keyVal : 'DN', value : 'DONE' }, { keyVal : 'ST', value : 'Started' },{ keyVal : 'SP', value : 'Stopped' }, { keyVal : 'RS', value : 'Restarted' }]

export const SaveTacticalForm = forwardRef (( { 
    showHeader = true, 
    onClose, 
    toEdit = false, 
    tacticalData, 
    submitBtnText = 'INSERT', 
    onSubmit, 
    width = '40%', 
    height = '65%',
    inputConfig = {
        taskDescDisabled : false,
        plannedDueDateDisabled : false,
        plannedStartDateDisabled : false,
        actualDueDateDisabled : false,
        actualStartDateDisabled : false
    } }, ref ) => {

    const { formId, formField : { name, plannedStartDate, plannedDueDate, actualDueDate, actualStartDate, taskStatus } } = saveTactical
    const { isSubmitting : submitting, isSuccess, isError } = useSelector(state => state.tactical)

    return (
        <Paper  elevation={2} sx={{
            padding : '2rem',
            width : width,
            height : height
        }}>
        {showHeader && <Box sx={{
            display : 'flex',
            justifyContent : 'space-between',
            alignItems : 'center',
            marginBottom : '1rem'
        }}>
            <Typography variant='subtitle2' component={'small'} >{ toEdit ? 'Edit' : 'Add New' }</Typography>
            <Tooltip title='CLOSE'>
                <IconButton onClick={() => onClose()} size='small'>
                    <CloseOutlinedIcon fontSize='1px'/>
                </IconButton>
            </Tooltip>
        </Box>}
        <Formik
            initialValues={toEdit ? getValues({
                name : tacticalData.task_desc,
                plannedDueDate : tacticalData.planned_due_date,
                plannedStartDate : String(tacticalData.planned_start_date).includes('1900') ? '' : tacticalData.planned_start_date,
                actualDueDate : String(tacticalData.actual_due_date).includes('1900') ? "" : tacticalData.actual_due_date,
                actualStartDate : String(tacticalData.actual_start_date).includes('1900') ? "" : tacticalData.actual_start_date,
                taskStatus : tacticalData.task_status
            }) : initialValues}
            validationSchema={saveTacticalValidator}
            onSubmit={(event) => {

                const { id } = tacticalData ? tacticalData :  { id : 0 }
                // const data = {
                //     id,
                //     name : event[name.name],
                //     plannedDueDate : event[plannedDueDate.name] ? moment(event[plannedDueDate.name]['$d']).format('YYYY-MM-DD') : moment(new Date().toString()).format('YYYY-MM-DD') ,
                //     plannedStartDate : event[plannedStartDate.name] ? moment(event[plannedStartDate.name]['$d']).format('YYYY-MM-DD') : moment(new Date().toString()).format('YYYY-MM-DD'),
                //     actualDueDate : event[actualDueDate.name] ? moment(event[actualDueDate.name]['$d']).format('YYYY-MM-DD') : moment(new Date().toString()).format('YYYY-MM-DD'),
                //     actualStartDate : event[actualStartDate.name] ? moment(event[actualStartDate.name]['$d']).format('YYYY-MM-DD') : moment(new Date().toString()).format('YYYY-MM-DD'),
                //     taskStatus : event[taskStatus.name]
                // }
                // const data = {
                //     id,
                //     name : event[name.name],
                //     plannedDueDate : toEdit ? moment((event[plannedDueDate.name]['$d'] === undefined) ? event[plannedDueDate.name] : event[plannedDueDate.name]['$d']).format('YYYY-MM-DD') :  moment((event[plannedDueDate.name] === '') ? new Date().toISOString() : event[plannedDueDate.name]['$d']).format('YYYY-MM-DD'),
                //     plannedStartDate : toEdit ? moment((event[plannedStartDate.name]['$d'] === undefined) ? event[plannedStartDate.name] : event[plannedStartDate.name]['$d']).format('YYYY-MM-DD') : moment((event[plannedStartDate.name] === '') ? new Date("1900-01-01").toISOString() : event[plannedStartDate.name]['$d']).format('YYYY-MM-DD'),
                //     actualDueDate : toEdit ? moment((event[actualDueDate.name]['$d'] === undefined) ? event[actualDueDate.name] : event[actualDueDate.name]['$d']).format('YYYY-MM-DD') : moment((event[actualDueDate.name] === '') ? new Date("1900-01-01").toISOString() : event[actualDueDate.name]['$d']).format('YYYY-MM-DD'),
                //     actualStartDate : toEdit ? moment((event[actualStartDate.name]['$d'] === undefined) ? event[actualStartDate.name] : event[actualStartDate.name]['$d']).format('YYYY-MM-DD') : moment((event[actualStartDate.name] === '') ? new Date("1900-01-01").toISOString() : event[actualStartDate.name]['$d']).format('YYYY-MM-DD'),
                //     taskStatus : event[taskStatus.name]
                // }
                const data = {
                    id,
                    name : event[name.name],
                    plannedDueDate : moment(parseDate(event[plannedDueDate.name])).format('YYYY-MM-DD'),
                    plannedStartDate : moment(parseDate(event[plannedStartDate.name], plannedStartDate.name)).format('YYYY-MM-DD'),
                    actualDueDate : moment(parseDate(event[actualDueDate.name], actualDueDate.name)).format('YYYY-MM-DD'),
                    actualStartDate : moment(parseDate(event[actualStartDate.name], actualStartDate.name)).format('YYYY-MM-DD'),
                    taskStatus : event[taskStatus.name]
                }
                onSubmit(data, toEdit)
            }}>
            {({ isSubmitting, setFieldValue, values }) => (
                <Form>
                    <Box sx={{
                        display : 'flex',
                        flexDirection : 'column',
                        alignItems : 'center',
                        marginTop : '0.7rem'
                    }}>
                        <Box width={'100%'}>
                            <InputField multiline maxRows={4} disabled={inputConfig.taskDescDisabled} label='Description' color='info' fullWidth name={name.name}/>
                        </Box>
                        <Box width={'100%'} marginTop={'1rem'}>
                            <DatePickerField disabled={inputConfig.plannedStartDateDisabled} label='Plan Start Date' name={plannedStartDate.name} />
                        </Box>
                        <Box width={'100%'} marginTop={'1rem'}>
                            <DatePickerField disabled={inputConfig.plannedDueDateDisabled} label='Plan Due Date' name={plannedDueDate.name}/>
                        </Box>
                        <Box width={'100%'} marginTop={'1rem'}>
                            <DatePickerField disabled={inputConfig.actualStartDateDisabled} label='Actual Start Date' name={actualStartDate.name}/>
                        </Box>
                        <Box width={'100%'} marginTop={'1rem'}>
                            <DatePickerField disabled={inputConfig.actualDueDateDisabled} label='Actual Due Date' name={actualDueDate.name}/>
                        </Box>
                        {toEdit && <Box width={'100%'} marginTop={'1rem'}>
                            <FormControl fullWidth color='info' >
                                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={values[taskStatus.name]}
                                    label="Age"
                                    onChange={(event) => {
                                        setFieldValue(taskStatus.name, event.target.value)
                                    }}
                                >
                                    {statuses.map((status, i) => (
                                        <MenuItem key={i} value={status.keyVal}>{status.value}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>}
                        <Box width={'100%'} marginTop={'1rem'}>
                            <Button type='submit' color='info' fullWidth variant='outlined' sx={{
                                display : 'flex',
                                justifyContent : 'center',
                                alignItems : 'center'
                            }}
                            disabled={submitting}>
                                {submitting && <CircularProgress color='info' size={15} sx={{ marginRight : '0.5rem' }}/>}
                                <Typography variant='subtitle2' component={'p'}>{submitBtnText}</Typography>
                                </Button>
                        </Box>
                    </Box>
                </Form>
            )}
        </Formik>
    </Paper>
)
})
