import { Box, Grid, IconButton, InputAdornment, Modal, Paper, TextField, Typography } from '@mui/material'
import React, { useState, forwardRef, useEffect } from 'react'
import { AddTasksAndViewDeliverableDetails } from './AddTasksAndViewDeliverableDetails'
import { CloseOutlined, SearchOutlined } from '@mui/icons-material'
import { TaskSearchedResultContainer } from './resultsearchedtask/TaskSearchedResultContainer'
import strategicService from '../../../services/strategicService'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { resetAlertState as strategicResetAlertState } from '../../../features/strategic/strategicSlice'
import { resetAlertState as tacticalResetAlertState} from '../../../features/tactical/tacticalSlice'
import { resetAlertState as operationalResetAlertState } from '../../../features/operational/operationalSlice'
import { SaveStrategicForm } from './forms/SaveStrategicForm'
import { SaveTacticalForm } from './forms/SaveTacticalForm'
import { SaveOperationalForm } from './forms/SaveOperationalForm'
import { useParams } from 'react-router-dom'

export const TasksWrapper = forwardRef(({ deliverable, onClose }, ref) => {

    const [taskModalOpen, setTaskModalOpen] = useState(false)
    const [taskClicked, setTaskClicked] = useState(null)
    const [search, setSearch] = useState("")
    const [taskSearchResults, setTaskSearchResults] = useState([])
    const { showAlert : strategicAlert, message : strategicMessage } = useSelector(state => state.strategic)
    const { showAlert : tacticalAlert, message : tacticalMessage } = useSelector(state => state.tactical)
    const { showAlert : operationalAlert, message : operationalMessage } = useSelector(state => state.operational)

    const dispatch = useDispatch()
    const { id } = useParams()
    useEffect(() => {
        if(strategicAlert || tacticalAlert || operationalAlert){
            toast.success((strategicAlert ? strategicMessage : (tacticalAlert ? tacticalMessage : operationalMessage)))
            resetAlerts()
        }
    }, [strategicAlert, tacticalAlert, operationalAlert])


    const resetAlerts = () => {
        if(strategicAlert){
            dispatch(strategicResetAlertState())
        }else if (tacticalAlert) {
            dispatch(tacticalResetAlertState())
        }else{
            dispatch(operationalResetAlertState())
        }
    }

  return (
    <Paper elevation={10} sx={{
        width : '95%',
        maxWidth : '95%',
        height : '90%',
        marginY : '4rem',
        padding : '2rem',
    }}>
        <Box sx={{ display : 'flex', flexDirection : 'column', alignItems : 'flex-start', width : '100%' }}>
            <Box sx={{
                display : 'flex',
                justifyContent : 'space-between',
                alignItems : 'center',
                width : '100%',
                marginBottom : '1rem'
            }}>
                <Box sx={{
                    backgroundColor : '#67AD45',
                    padding : '5px',
                    borderRadius : '5px'
                }}>
                    <Typography variant='subtitle2' component={'p'} fontSize={'13px'} color={'GrayText'}>Deliverable:</Typography>
                    <Typography variant='subtitle2' component={'p'} fontSize={'12px'} color={'white'}>{deliverable?.name}</Typography>
                </Box>
                <IconButton size='small' onClick={() => {
                    // setShowAddTasksModal(false)
                    // setShowTacticalTasksTable(false)
                    // setShowTacticalAndOperationalDetailModal(false)
                    onClose()
                }}>
                    <CloseOutlined/>
                </IconButton>
            </Box>
            <TextField 
                size='small' 
                type='search'
                variant='standard'
                placeholder='Search tasks' 
                color='info'
                sx={{ width : '15rem' }}
                InputProps={{
                    startAdornment : (
                        <InputAdornment position='start'>
                            <SearchOutlined color='info'/>
                        </InputAdornment>
                    )
                }}
                onChange={(event) => {
                    setSearch(event.target.value)
                    console.log(search)
                    strategicService.searchTasks({ query : event.target.value, client : id })
                        .then((res) => setTaskSearchResults(res.data))
                        .catch((err) => setTaskSearchResults([]))
                }}/>
        </Box>
        {search.length > 0 ? <>
            <Box sx={{ display : 'flex', justifyContent : 'flex-start', alignItems : 'flex-start', width : '100%', flexDirection : 'column', overflow : 'visible', overflowY : 'scroll', height : '44rem', marginTop : '1rem', paddingTop : '1rem'}}>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ padding : '2rem'}} alignItems={'center'} justifyContent={'center'}>
                    {taskSearchResults.map((result, i) => (
                        <Grid item xs={4} key={i}>
                            <TaskSearchedResultContainer searchedResult={result} onClick={(data) => {
                                setTaskClicked(data.task)
                                setTaskModalOpen(true)
                                console.log(data)
                            }}/>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </> : <AddTasksAndViewDeliverableDetails deliverableId={deliverable?.id}/>}

        <Modal 
            open={taskModalOpen}
            onClose={() => setTaskModalOpen(false)}
            sx={{
                display : 'flex',
                justifyContent : 'center',
                alignItems : 'center'
            }}>
            <Paper sx={{
                padding : '2rem',
                width : '60%',
                display : 'flex',
                justifyContent : 'center',
                flexDirection : 'column'
            }}
            elevation={10}>
                <Box sx={{
                    marginBottom : '2rem'
                }}>
                    <Typography>{String(taskClicked?.type).toLocaleUpperCase()}</Typography>
                </Box>
                <TaskClicked task={taskClicked?.task} type={taskClicked?.type}/>
            </Paper>
        </Modal>
        
    </Paper>
  )
})


const TaskClicked = ({ task, type}) => {
    
    if(type === 'strategic'){
        return <>
            <SaveStrategicForm strategicData={task} toEdit={true} width='100%' showHeader={false} submitBtnText='SAVE'/>
        </>
    }else if(type === 'tactical'){
        return <>
            <SaveTacticalForm tacticalData={task} toEdit={true} width='100%' showHeader={false} submitBtnText='SAVE'/>
        </>
    }else{
        return <>
            <SaveOperationalForm operationalData={task} toEdit={true} width='100%' height='67%' showHeader={false} submitBtnText='SAVE'/>
        </>
    }
}
