import { Box, Button, CircularProgress, FormControl, IconButton, InputLabel, MenuItem, Modal, Paper, Select, TextField, Tooltip, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';

import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import AddIcon from '@mui/icons-material/Add';
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { clients, deliverables, strategicTasks, tacticalTasks } from '../../../sampleData'
import { DateTimeField } from '@mui/x-date-pickers';
import { DataGrid } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import { SaveStrategicForm } from './forms/SaveStrategicForm';
import { SaveTacticalForm } from './forms/SaveTacticalForm';
import { SaveOperationalForm } from './forms/SaveOperationalForm';
import { SelectInput } from '../../common/SelectInput';
import { useDispatch, useSelector } from 'react-redux';
import { createStrategic, deleteStrategic, getAllStrategicsByDelivery, resetAlertState, resetFormState, setStrategic, showForm, updateStrategic } from '../../../features/strategic/strategicSlice';
import { deleteTactical, setTactical, resetAlertState as tacticalResetAlertState, resetFormState as tacticalResetFormState, showForm as tacticalShowForm } from '../../../features/tactical/tacticalSlice';
import { deleteOperational, resetAlertState as operationalResetAlertState, resetFormState as operationalResetFormState, showForm as operationalShowForm, setOperational, showFormToggle } from '../../../features/operational/operationalSlice';
import { useEffect } from 'react'
import { createTactical, getAllTacticals, updateTactical } from '../../../features/tactical/tacticalSlice';
import { createOperational, getAllOperationals, updateOperational } from '../../../features/operational/operationalSlice';
import { ListAndAssignStakeholder } from './ListAndAssignStakeholder';
import { getAllStakeholder } from '../../../features/stakeholder/stakeholderSlice';
import { TaskStatus } from './TaskStatus';
import { userRole } from '../../../constants/userRole';
import moment from 'moment';
import { Delete } from '@mui/icons-material';
import { LinearProgressWithLabel } from '../../common/LinearProgressWithLabel';
import { format } from '../../../helpers/commentFormatter';
import { tooltip } from '../../../helpers/taskStatusHelper';

const statuses = [{ keyVal: 'NT', value: 'Not Yet Started' }, { keyVal: 'IP', value: 'In Progress' }, { keyVal: 'DN', value: 'Done' }, { keyVal: 'ST', value: 'Started' }, { keyVal: 'SP', value: 'Stopped' }, { keyVal: 'RS', value: 'Restarted' }]


const DeleteButtonField = ({ data, type }) => {
    const { isLoading: strategicLoading, strategic } = useSelector(state => state.strategic)
    const { isDeleteLoading: tacticalLoading, tactical } = useSelector(state => state.tactical)
    const { isDeleteLoading: operationalLoading, operational } = useSelector(state => state.operational)
    const dispatch = useDispatch()

    const onDelete = () => {
        switch (type) {
            case 'strategic':
                dispatch(setStrategic(data))
                dispatch(deleteStrategic({ id: data.id }))
                break;
            case 'tactical':
                dispatch(setTactical(data))
                dispatch(deleteTactical({ id: data.id }))
            case 'operational':
                dispatch(setOperational(data))
                dispatch(deleteOperational({ id: data.id }))
                break;
            default:
                break;
        }
    }

    const buttonRederer = () => {
        switch (type) {
            case 'strategic':
                return <>{strategicLoading && strategic?.id === data?.id ? <CircularProgress size={20} color='info' /> : <IconButton onClick={onDelete} size='small' color='error'>
                    <Delete />
                </IconButton>}</>
            case 'tactical':
                return <>{tacticalLoading && tactical?.id === data?.id ? <CircularProgress size={20} color='primary' /> : <IconButton onClick={onDelete} size='small' color='error'>
                    <Delete />
                </IconButton>}</>
            case 'operational':
                return <>{operationalLoading && operational?.id === data?.id ? <CircularProgress size={20} color='secondary' /> : <IconButton onClick={onDelete} size='small' color='error'>
                    <Delete />
                </IconButton>}</>
            default:
                break;
        }
    }

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                {buttonRederer()}
            </Box>
        </>
    )
}

const strategicColumns = [
    {
        field: 'edit',
        headerName: 'Edit',
        width: 40,
        renderCell: (cellValues) => {

            return (
                <>
                    <IconButton size='small'>
                        <EditIcon color='primary' />
                    </IconButton>
                </>
            )
        }
    },
    {
        field: 'task_desc',
        headerName: 'Strategic Tasks',
        width: 600,
        editable: true,
    },
    {
        field: 'progress',
        headerName: 'Progress',
        width: 220,
        renderCell: (cellValues) => {
            return (
                <>
                    <LinearProgressWithLabel value={Number(cellValues.row.progress)} />
                </>
            )
        }
    },
    {
        field: 'task_status',
        headerName: 'Status',
        width: 220,
        description : format(tooltip),
        renderCell: (cellValues) => {

            return (
                <>
                    <div className="m-1em">
                        <TaskStatus defaultValue={cellValues.row.task_status} options={statuses} taskType={'strategic'} data={cellValues.row} />
                    </div>
                </>
            )
        }
    },
    {
        field: 'planned_start_date',
        headerName: 'Plan Start Date',
        width: 190,
        editable: true,
        valueFormatter: (params) => moment(params.value).format('YYYY-MM-DD') === "1900-01-01" ? "-" : moment(params.value).format('MMM DD YYYY')
    },
    {
        field: 'planned_due_date',
        headerName: 'Plan Due Date',
        type: 'string',
        width: 190,
        editable: true,
        valueFormatter: (params) => moment(params.value).format('MMM DD YYYY')
    },
    {
        field: 'actual_start_date',
        headerName: 'Actual Start Date',
        width: 190,
        editable: true,
        valueFormatter: (params) => moment(params.value).format('YYYY-MM-DD') === "1900-01-01" ? "-" : moment(params.value).format('MMM DD YYYY')
    },
    {
        field: 'actual_due_date',
        headerName: 'Actual Due Date',
        width: 190,
        editable: true,
        valueFormatter: (params) => moment(params.value).format('YYYY-MM-DD') === "1900-01-01" ? "-" : moment(params.value).format('MMM DD YYYY')
    },
    {
        field: 'delete',
        headerName: 'Delete',
        width: 70,
        renderCell: (cellValues) => {

            return (
                <>
                    <DeleteButtonField data={cellValues.row} type={'strategic'} />
                </>
            )
        }
    },
];


const tacticalColumns = [
    {
        field: 'edit',
        headerName: 'Edit',
        width: 70,
        renderCell: (cellValues) => {

            return (
                <>
                    <IconButton size='small'>
                        <EditIcon color='primary' />
                    </IconButton>
                </>
            )
        }
    },
    {
        field: 'task_desc',
        headerName: 'Tactical Tasks',
        width: 600,
        editable: true,
    },
    {
        field: 'status',
        headerName: 'Status',
        width: 220,
        description : format(tooltip),
        renderCell: (cellValues) => {

            return (
                <>
                    <div className="m-1em">
                        <TaskStatus defaultValue={cellValues.row.task_status} taskType={'tactical'} options={statuses} data={cellValues.row} />
                    </div>
                </>
            )
        }
    },
    {
        field: 'planned_start_date',
        headerName: 'Plan Start Date',
        width: 190,
        editable: true,
        valueFormatter: (params) => moment(params.value).format('YYYY-MM-DD') === "1900-01-01" ? "-" : moment(params.value).format('MMM DD YYYY')
    },
    {
        field: 'planned_due_date',
        headerName: 'Plan Due Date',
        type: 'string',
        width: 190,
        editable: true,
        valueFormatter: (params) => moment(params.value).format('MMM DD YYYY')
    },
    {
        field: 'actual_start_date',
        headerName: 'Actual Start Date',
        width: 190,
        editable: true,
        valueFormatter: (params) => moment(params.value).format('YYYY-MM-DD') === "1900-01-01" ? "-" : moment(params.value).format('MMM DD YYYY')
    },
    {
        field: 'actual_due_date',
        headerName: 'Actual Due Date',
        width: 190,
        editable: true,
        valueFormatter: (params) => moment(params.value).format('YYYY-MM-DD') === "1900-01-01" ? "-" : moment(params.value).format('MMM DD YYYY')
    },
    {
        field: 'delete',
        headerName: 'Delete',
        width: 70,
        renderCell: (cellValues) => {

            return (
                <>
                    <DeleteButtonField data={cellValues.row} type={'tactical'} />
                </>
            )
        }
    },
];

const operationalColumns = [
    {
        field: 'edit',
        headerName: 'Edit',
        renderCell: (cellValues) => {

            return (
                <>
                    <IconButton size='small'>
                        <EditIcon color='primary' />
                    </IconButton>
                </>
            )
        }
    },
    {
        field: 'assignee',
        headerName: 'Assignee',
        width: 220,
        renderCell: (cellValues) => {
            // console.log(cellValues)
            return (
                <>
                    <div className="m-1em">
                        <ListAndAssignStakeholder operational={cellValues.row} />
                    </div>
                </>
            )
        }
    },
    {
        field: 'task_desc',
        headerName: 'Strategic Tasks',
        width: 500,
        editable: true,
    },
    {
        field: 'task_status',
        headerName: 'Status',
        width: 220,
        description : format(tooltip),
        renderCell: (cellValues) => {

            return (
                <>
                    <TaskStatus data={cellValues.row} defaultValue={cellValues.row.task_status} options={statuses} taskType={'operational'} />
                </>
            )
        }
    },
    {
        field: 'planned_start_date',
        headerName: 'Plan Start Date',
        width: 160,
        editable: true,
        valueFormatter: (params) => moment(params.value).format('YYYY-MM-DD') === "1900-01-01" ? "-" : moment(params.value).format('MMM DD YYYY')
    },
    {
        field: 'planned_due_date',
        headerName: 'Plan Due Date',
        type: 'string',
        width: 160,
        editable: true,
        valueFormatter: (params) => moment(params.value).format('MMM DD YYYY')
    },
    {
        field: 'actual_start_date',
        headerName: 'Actual Start Date',
        width: 160,
        editable: true,
        valueFormatter: (params) => moment(params.value).format('YYYY-MM-DD') === "1900-01-01" ? "-" : moment(params.value).format('MMM DD YYYY')
    },
    {
        field: 'actual_due_date',
        headerName: 'Actual Due Date',
        width: 160,
        editable: true,
        valueFormatter: (params) => moment(params.value).format('YYYY-MM-DD') === "1900-01-01" ? "-" : moment(params.value).format('MMM DD YYYY')
    },
    {
        field: 'delete',
        headerName: 'Delete',
        width: 70,
        renderCell: (cellValues) => {

            return (
                <>
                    <DeleteButtonField data={cellValues.row} type={'operational'} />
                </>
            )
        }
    },
];

export const AddTasksAndViewDeliverableDetails = ({ deliverableId }) => {

    const [showTacticalTasksTable, setShowTacticalTasksTable] = useState(false)

    // const [files, setFiles] = useState([])
    const { client } = useParams()

    const clientDetails = clients.find(c => c.name == client)


    {/* hooks for modals */ }
    // const [showAddDeliverableModal, setShowAddDeliverableModal] = useState(false)
    // const [showAddTasksModal, setShowAddTasksModal] = useState(false)
    const [showSaveStrategicModal, setShowSaveStrategicModal] = useState(false)
    const [editStrategic, setEditStrategic] = useState(false)
    const [selectedStrategic, setSelectedStrategic] = useState(null)


    const [showSaveTacticModal, setShowSaveTacticModal] = useState(false)
    const [editTactical, setEditTactical] = useState(false)
    const [selectedTactical, setSelectedTactical] = useState(null)


    const [showAddAndViewOperationalModal, setShowAddAndViewOperationalModal] = useState(false)
    const [editOperational, setEditOperational] = useState(false)
    const [selectedOperational, setSelectedOperational] = useState(null)
    const [showSaveOperationalForm, setShowSaveOperationalForm] = useState(false)
    // const [showTacticalAndOperationalDetailModal, setShowTacticalAndOperationalDetailModal] = useState(false)
    

    {/** working with redux */ }
    const dispatch = useDispatch()
    const { isLoading, isError, isSuccess, strategics, showForm: strategicShowForm } = useSelector(state => state.strategic)
    const { isLoading: tacticalLoading, isError: tacticalError, isSuccess: tacticalSuccess, tacticals, showForm: tacticalShowFormModal } = useSelector(state => state.tactical)
    const { isLoading: operationalLoading, isError: operationalError, isSuccess: operationalSuccess, operationals, showForm: operationalShowFormModal } = useSelector(state => state.operational)

    useEffect(() => {
        dispatch(getAllStrategicsByDelivery({ deliverableId }))

        return () => {
            dispatch(resetAlertState())
            dispatch(tacticalResetAlertState())
        }
    }, [dispatch])

    return (
        <>

            <Box width={'100%'}>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    height: '100%'
                }}>
                    <Box width={showTacticalTasksTable ? '30%' : '100%'} height={'95%'} sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start'
                    }}>

                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            marginY: '0.5rem',
                            width: '100%'
                        }}>
                            <Typography variant='subtitle2' component={'small'} fontSize={'10px'} sx={{
                                marginRight: '1rem'
                            }}>STRATEGICS</Typography>
                            {JSON.parse(localStorage.getItem('auth')).role_name !== userRole.CLIENT_USER && <Tooltip title='ADD NEW STRATEGIC'>
                                <IconButton size='small' onClick={() => {
                                    setEditStrategic(false)
                                    setShowSaveStrategicModal(true)
                                    dispatch(showForm())
                                }}>
                                    <AddIcon fontSize='1px' />
                                </IconButton>
                            </Tooltip>}
                        </Box>
                        {/* .-. -- -... Data Grid */}
                        <div style={{ height: 500, width: '100%' }}>
                            <DataGrid
                                rows={strategics}
                                columns={strategicColumns}
                                // autoHeight
                                rowHeight={25}
                                getRowHeight={() => 'auto'}
                                initialState={{
                                    pagination: {
                                        paginationModel: {
                                            pageSize: 5
                                        }
                                    }
                                }}
                                pageSizeOptions={[5, 10, 25, 50]}
                                disableRowSelectionOnClick
                                onCellDoubleClick={(event) => {
                                // console.log(event.field);
                                if (event.field !== 'edit') {
                                    setShowTacticalTasksTable(true);
                                    // console.log(event.row['id'])
                                    setSelectedStrategic(event.row);
                                    dispatch(getAllTacticals({ strategicId: event.row['id'] }));
                                }
                                }}
                                onCellClick={(event) => {
                                if (event.field === 'edit') {
                                    if (JSON.parse(localStorage.getItem('auth')).role_name === userRole.CLIENT_USER) return;
                                    setEditStrategic(true);
                                    setSelectedStrategic(event.row);
                                    setShowSaveStrategicModal(true);
                                    dispatch(showForm());
                                }
                                // if (event.field === 'status') {
                                //     console.log(event.value);
                                // }
                                }}
                                isCellEditable={(params) => false}
                                sx={{
                                width: '100%',
                                padding: '10px',
                                }}/>
                        </div>

                        

                    </Box>
                    {showTacticalTasksTable && <Box width={'70%'} height={'95%'} sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        marginLeft: '1rem'
                    }}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginY: '0.5rem',
                            width: '100%',
                            height: '100%'
                        }}>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-end'
                            }}>
                                <Typography variant='subtitle2' component={'small'} fontSize={'10px'} sx={{ marginRight: '1rem' }}>TACTICALS</Typography>
                                {JSON.parse(localStorage.getItem('auth')).role_name !== userRole.CLIENT_USER && <Tooltip title='ADD NEW TACTICAL'>
                                    <IconButton size='small' onClick={() => {
                                        setEditTactical(false)
                                        setShowSaveTacticModal(true)
                                        dispatch(tacticalShowForm())
                                    }}>
                                        <AddIcon fontSize='1px' />
                                    </IconButton>
                                </Tooltip>}
                            </Box>

                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-end'
                            }}>
                                <Tooltip title={`Strategic: ${selectedStrategic?.task_desc}`} sx={{
                                    marginRight: '0.5rem'
                                }}>
                                    <HelpOutlineIcon fontSize='2px' color='info' />
                                </Tooltip>
                                <Tooltip title='Close'>
                                    <IconButton size='small' onClick={() => setShowTacticalTasksTable(false)}>
                                        <CloseIcon fontSize='1px' />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        </Box>
                        {tacticalLoading ? <>
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '2rem', width: '100%' }} >
                                <CircularProgress color='primary' size={70} />
                            </Box>
                            
                        </> :
                        <>
                         {/* .-. -- -... Data Grid  */}
                        <div style={{height: 500, width: '100%'}}>
                            <DataGrid
                                rows={tacticals}
                                columns={tacticalColumns}
                                // autoHeight={true}
                                rowHeight={25}
                                getRowHeight={() => 'auto'}
                                initialState={{
                                    pagination: {
                                        paginationModel: {
                                            pageSize: 5
                                        }
                                    }
                                }}
                                pageSizeOptions={[5, 10, 25, 50]}
                                disableRowSelectionOnClick
                                onCellDoubleClick={(event) => {
                                    if (event.field !== 'edit') {

                                    const user = JSON.parse(localStorage.getItem('auth'))
                                    const assigneeId = user.role_name === userRole.CYTECH_USER ? user.id : ''
                                    setShowAddAndViewOperationalModal(true)
                                    setSelectedTactical(event.row)
                                    dispatch(getAllOperationals({ tacticalId: event.row['id'], assigneeId }))
                                    dispatch(getAllStakeholder({ client : (user.role_name === userRole.CLIENT_ADMIN || user.role_name === userRole.CLIENT_USER) ? user.client_data.id : null}))
                                }
                            }}
                            onCellClick={(event) => {
                                if (event.field === 'edit') {
                                    if (JSON.parse(localStorage.getItem('auth')).role_name === userRole.CLIENT_USER) return

                                        setEditTactical(true)
                                        setSelectedTactical(event.row)
                                        setShowSaveTacticModal(true)
                                        dispatch(tacticalShowForm())
                                    }
                                }}
                                isCellEditable={(params) => false}
                                sx={{
                                    width: '100%',
                                    padding: '10px',
                                }}/>
                            </div>
                        </>}
                    </Box>}
                </Box>

                {/* modal for adding new strategic task */}
                <Modal open={showSaveStrategicModal && strategicShowForm} sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <SaveStrategicForm
                        inputConfig={{
                            taskDescDisabled: JSON.parse(localStorage.getItem('auth')).role_name === userRole.CYTECH_USER,
                            plannedDueDateDisabled: JSON.parse(localStorage.getItem('auth')).role_name === userRole.CYTECH_USER,
                            plannedStartDateDisabled: JSON.parse(localStorage.getItem('auth')).role_name === userRole.CYTECH_USER,
                            actualDueDateDisabled: JSON.parse(localStorage.getItem('auth')).role_name === userRole.CYTECH_USER,
                            actualStartDateDisabled: JSON.parse(localStorage.getItem('auth')).role_name === userRole.CYTECH_USER
                        }}
                        onSubmit={(values, toEdit) => {

                            const data = {
                                task_desc: values['name'],
                                delivery_id: deliverableId,
                                planned_due_date: values['plannedDueDate'],
                                planned_start_date: values['plannedStartDate'],
                                actual_due_date: values['actualDueDate'],
                                actual_start_date: values['actualStartDate'],
                                task_status: values['taskStatus']
                            }
                            if (!toEdit) {
                                dispatch(createStrategic({ data }))
                            } else {
                                const dataToUpdate = { ...data, id: values['id'] }
                                dispatch(updateStrategic({ data: dataToUpdate }))
                            }
                        }}
                        height={editStrategic ? '75%' : '67%'}
                        strategicData={selectedStrategic}
                        showHeader onClose={() => setShowSaveStrategicModal(false)}
                        toEdit={editStrategic}
                        submitBtnText={editStrategic ? 'UPDATE' : 'ADD'} />
                </Modal>


                {/* modal for adding new tactical task */}
                <Modal open={showSaveTacticModal && tacticalShowFormModal} sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <SaveTacticalForm
                        inputConfig={{
                            taskDescDisabled: JSON.parse(localStorage.getItem('auth')).role_name === userRole.CYTECH_USER,
                            plannedDueDateDisabled: JSON.parse(localStorage.getItem('auth')).role_name === userRole.CYTECH_USER,
                            plannedStartDateDisabled: JSON.parse(localStorage.getItem('auth')).role_name === userRole.CYTECH_USER,
                            actualDueDateDisabled: JSON.parse(localStorage.getItem('auth')).role_name === userRole.CYTECH_USER,
                            actualStartDateDisabled: JSON.parse(localStorage.getItem('auth')).role_name === userRole.CYTECH_USER
                        }}
                        height={editTactical ? '75%' : '67%'}
                        onSubmit={(values, toEdit) => {
                            const data = {
                                task_desc: values['name'],
                                strategic_task_id: selectedStrategic.id,
                                planned_due_date: values['plannedDueDate'],
                                planned_start_date: values['plannedStartDate'],
                                actual_due_date: values['actualDueDate'],
                                actual_start_date: values['actualStartDate'],
                                task_status: values['taskStatus']
                            }

                            if (!toEdit) {
                                dispatch(createTactical({ data }))
                            } else {
                                const dataToUpdate = { ...data, id: values['id'] }
                                dispatch(updateTactical({ data: dataToUpdate }))
                            }

                        }} tacticalData={selectedTactical} showHeader onClose={() => setShowSaveTacticModal(false)} toEdit={editTactical} submitBtnText={editTactical ? 'EDIT' : 'ADD NEW'} />
                </Modal>

                {/* modal for viewing and adding operational task/s */}
                <Modal open={showAddAndViewOperationalModal} sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                    <Paper elevation={2} sx={{
                        padding: '2rem',
                        width: '95%',
                        height: '85%',
                        paddingBottom: '7rem'
                    }}>
                        <Box sx={{
                            padding: '3px',
                            backgroundColor: '#67AD45',
                            borderRadius: '4px'
                        }}>
                            <Typography variant='subtitle2' component={'p'} fontSize={'11px'}>Tactical:</Typography>
                            <Typography variant='subtitle2' component={'p'} fontSize={'12px'}>{selectedTactical?.task_desc}</Typography>
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginBottom: '1rem'
                        }}>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center'
                            }}>
                                <Typography variant='subtitle2' component={'small'} sx={{ marginRight: '1rem' }}>OPERATIONALS</Typography>
                                {(!editOperational && JSON.parse(localStorage.getItem('auth')).role_name !== userRole.CLIENT_USER) && <Tooltip title={(operationalShowFormModal) ? 'CLOSE' : 'ADD NEW OPERATIONAL TASK'}>
                                    <IconButton size='small' onClick={() => {
                                        setEditOperational(false)
                                        // setShowSaveOperationalForm(!showSaveOperationalForm)
                                        dispatch(showFormToggle())
                                    }}>
                                        {(operationalShowFormModal) ? <CloseIcon fontSize='small' /> : <AddIcon fontSize='small' />}
                                    </IconButton>
                                </Tooltip>}
                            </Box>
                            <Tooltip title='CLOSE'>
                                <IconButton onClick={() => {
                                    setShowAddAndViewOperationalModal(false)
                                    dispatch(operationalResetFormState())
                                    setEditOperational(false)
                                    // setShowSaveOperationalForm(false)
                                }} size='small'>
                                    <CloseIcon fontSize='1px' />
                                </IconButton>
                            </Tooltip>
                        </Box>
                        {(operationalShowFormModal) ? <SaveOperationalForm
                            // inputConfig={{
                            //     taskDescDisabled : (JSON.parse(localStorage.getItem('auth')).role_name === userRole.CYTECH_USER && editOperational),
                            //     plannedDueDateDisabled : (JSON.parse(localStorage.getItem('auth')).role_name === userRole.CYTECH_USER && editOperational),
                            //     plannedStartDateDisabled : (JSON.parse(localStorage.getItem('auth')).role_name === userRole.CYTECH_USER && editOperational),
                            //     actualDueDateDisabled : (JSON.parse(localStorage.getItem('auth')).role_name === userRole.CYTECH_USER && editOperational),
                            //     actualStartDateDisabled : (JSON.parse(localStorage.getItem('auth')).role_name === userRole.CYTECH_USER && editOperational)
                            // }}
                            operationalData={selectedOperational}
                            submitBtnText={editOperational ? 'UPDATE' : 'ADD'}
                            showHeader={editOperational}
                            onClose={() => {
                                dispatch(operationalResetFormState())
                                setEditOperational(false)
                            }}
                            height={editOperational ? '100%' : '100%'}
                            toEdit={editOperational}
                            onSubmit={(values, toEdit) => {

                                const user = JSON.parse(localStorage.getItem('auth'))
                                const data = {
                                    task_desc: values['name'],
                                    tactical_task_id: selectedTactical.id,
                                    stakeholder_id: user.role_name === userRole.CYTECH_USER ? user.id : 0,
                                    planned_due_date: values['plannedDueDate'],
                                    planned_start_date: values['plannedStartDate'],
                                    actual_due_date: values['actualDueDate'],
                                    actual_start_date: values['actualStartDate'],
                                    task_status: values['taskStatus']
                                }

                                if (!toEdit) {
                                    dispatch(createOperational({ data }))
                                } else {
                                    const dataToUpdate = { ...data, id: values['id'] }
                                    // console.log(dataToUpdate)
                                    dispatch(updateOperational({ data: dataToUpdate }))
                                }
                            }} /> : <Box width={'100%'} height={'450'}>
                                {/* .-. -- -... Data Grid */}
                                <div style={{height: 500, width: '100%'}}>
                                    <DataGrid
                                        loading={operationalLoading}
                                        rows={operationals}
                                        rowHeight={25}
                                        columns={operationalColumns}
                                        // autoHeight={true}
                                        // height = {'50vh'}
                                        getRowHeight={() => 'auto'}
                                        initialState={{
                                            pagination: {
                                                paginationModel: {
                                                    pageSize: 5
                                                }
                                            }
                                        }}
                                        pageSizeOptions={[5, 10, 25, 50]}
                                        disableRowSelectionOnClick
                                        onCellClick={(event) => {
                                            if (event.field === 'edit') {
                                                if (JSON.parse(localStorage.getItem('auth')).role_name === userRole.CLIENT_USER) return

                                                setEditOperational(true)
                                                setSelectedOperational(event.row)
                                                // setShowSaveOperationalForm(true)
                                                dispatch(operationalShowForm())
                                            }
                                        }}
                                        isCellEditable={(params) => false}
                                        sx={{
                                            width: '100%'
                                        }}
                                        components={{
                                            NoRowsOverlay: () => {

                                                const user = JSON.parse(localStorage.getItem('auth'))
                                                return <Box sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center'
                                                }}>
                                                    {user.role_name === userRole.CYTECH_USER ? 'No tasks assigned' : 'No tasks'}
                                                </Box>
                                            },
                                            LoadingOverlay: () => {

                                                return (
                                                    <Box sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center'
                                                    }}>
                                                        <CircularProgress size={30} />
                                                    </Box>
                                                )
                                            }
                                    }}/>
                                </div>
                        </Box>}

                    </Paper>
                </Modal>
            </Box>
        </>
    )
}
