import { Box, CircularProgress, IconButton, TextField, Typography } from '@mui/material'
import SendIcon from '@mui/icons-material/Send'
import React, { useState } from 'react'
import STATE_STATUS from '../../constants/status'

export const WriteComment = ({ onSubmit, status }) => {

    const [text, setText] = useState("")
  return (
    <>
        <Box sx={{ display : 'flex', flexDirection : 'column', alignItems : 'flex-start', width : '100%' }}>
            <Typography fontSize={'10px'} variant='subtitle2' component={'span'}>ADD COMMENT</Typography>
            <Box sx={{ display : 'flex', alignItems : 'flex-end', width : '100%', marginTop : '0.3rem', }}>
                <TextField label={'Comment'} value={text} size='small' fullWidth color='secondary' multiline onChange={(event) => setText(event.target.value)}/>
                <Box marginLeft={'0.3rem'}>
                    {status === STATE_STATUS.LOADING ? <CircularProgress size={20}/> :
                    <IconButton disabled={status === STATE_STATUS.LOADING} color='secondary' onClick={() => {
                        if(text.length < 1 || text.trim().length < 1){
                            return
                        }
                        setText('')
                        onSubmit(text)
                    }}>
                        <SendIcon/>
                    </IconButton> }
                     
                </Box>
            </Box>
        </Box>
    </>
  )
}
