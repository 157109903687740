import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import tasksService from '../../services/tasksService'
import STATE_STATUS from "../../constants/status";
import overallstatusService from "../../services/overallstatusService";
import deliveryriskService from "../../services/deliveryriskService";


const initialState = {
    overallStatusStatus : STATE_STATUS.IDLE,
    deliveryRiskStatus : STATE_STATUS.IDLE,
    tasksApproachingStatus : STATE_STATUS.IDLE,
    tasksOverdueStatus : STATE_STATUS.IDLE,
    overallStatus : null,
    deliveryRisk : null,
    approachingTasks : [],
    overdueTasks : []
}


export const fetchTasksApproaching = createAsyncThunk(
    'dashboard/fetchTasksApproaching',
    async (params, thunk) => {
        try {
            const res = await tasksService.getTasksApproaching14Days({ clientId : params['clientId'] })
            return res.data
        } catch (error) {
            return thunk.rejectWithValue(error.response.data)
        }
    }
)

export const fetchTasksOverdue = createAsyncThunk(
    'dashboard/fetchTasksOverdue',
    async (params, thunk) => {
        try {
            const res = await tasksService.getTasksOverdue({ clientId : params['clientId'] })
            return res.data
        } catch (error) {
            return thunk.rejectWithValue(error.response.data)
        }
    }
)

export const fetchOverallStatus = createAsyncThunk(
    'dashboard/fetchOverallStatus',
    async (params, thunk) => {
        try {
            const res = await overallstatusService.getOverallStatus({ clientId : params['clientId'] })
            return res.data
        } catch (error) {
            return thunk.rejectWithValue(error.response.data)
        }
    }
)

export const fetchDeliveryRisk = createAsyncThunk(
    'dashboard/fetchDeliveryRisk',
    async (params, thunk) => {
        try {
            const res = await deliveryriskService.getDeliveryRisk({ clientId : params['clientId'] })
            return res.data
        } catch (error) {
            return thunk.rejectWithValue(error.response.data)
        }
    }
)


const dashboardSlice = createSlice({
    name : 'dashboard',	
    initialState,
    reducers : {},
    extraReducers : (builder) => { 
        builder.addCase(fetchTasksApproaching.pending, (state) => {
            state.tasksApproachingStatus = STATE_STATUS.LOADING
        }).addCase(fetchTasksApproaching.fulfilled, (state, action) => {
            state.tasksApproachingStatus = STATE_STATUS.SUCCESS
            state.approachingTasks = action.payload
        }).addCase(fetchTasksApproaching.rejected, (state, action) => {
            state.tasksApproachingStatus = STATE_STATUS.FAILED
            state.approachingTasks = []
        }).addCase(fetchTasksOverdue.pending, (state) => {
            state.tasksOverdueStatus = STATE_STATUS.LOADING
        }).addCase(fetchTasksOverdue.fulfilled, (state, action) => {
            state.tasksOverdueStatus = STATE_STATUS.SUCCESS
            state.overdueTasks = action.payload
        }).addCase(fetchTasksOverdue.rejected, (state, action) => {
            state.tasksOverdueStatus = STATE_STATUS.FAILED
            state.overdueTasks = []
        }).addCase(fetchOverallStatus.pending, (state) => {
            state.overallStatusStatus = STATE_STATUS.LOADING
        }).addCase(fetchOverallStatus.fulfilled, (state, action) => {
            state.overallStatusStatus = STATE_STATUS.SUCCESS
            state.overallStatus = action.payload
        }).addCase(fetchOverallStatus.rejected, (state, action) => {
            state.overallStatusStatus = STATE_STATUS.FAILED
            state.overallStatus = null
        }).addCase(fetchDeliveryRisk.pending, (state) => {
            state.deliveryRiskStatus = STATE_STATUS.LOADING
        }).addCase(fetchDeliveryRisk.fulfilled, (state, action) => {
            state.deliveryRiskStatus = STATE_STATUS.SUCCESS
            state.deliveryRisk = action.payload
        }).addCase(fetchDeliveryRisk.rejected, (state, action) => {
            state.deliveryRiskStatus = STATE_STATUS.FAILED
            state.deliveryRisk = null
        })
    }
})


export default dashboardSlice.reducer