import { AppBar, Box, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack, Toolbar, Typography, Menu, MenuItem, ImageListItem, Tooltip, Badge } from '@mui/material'
import { DashboardOutlined } from '@mui/icons-material'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import DnsIcon from '@mui/icons-material/Dns';
import InfoIcon from '@mui/icons-material/Info';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import React, { useState } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { SelectInput } from './SelectInput';
import clientInformationService from '../../services/clientInformationService';
import { useDispatch, useSelector } from 'react-redux';
import { clear } from '../../features/auth/authSlice';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { NotificationList } from '../notification/NotificationList';
import { userRole } from '../../constants/userRole';
import queryParamHelper from '../../helpers/queryParamHelper'
import { fetchDeliveryRisk, fetchOverallStatus, fetchTasksApproaching, fetchTasksOverdue } from '../../features/dashboard/dashboardSlice';
import { resetClientState } from '../../features/clientinformation/clientInformationSlice';
// import Image from "material-ui-image";
import placeholder from '../../assets/images/placeholder.png';
// import CyTech_logo from '../../assets/images/CyTech-logo.png'
// import selina_logo from '../../assets/images/selina-logo.svg'

export const Navbar = () => {

    const { count, notifications } = useSelector(state => state.notification)
    const dispatch = useDispatch()
    const location = useLocation()
    const params = useParams()
    const navigate = useNavigate()
    const [anchorMenu, setAnchorMenu] = useState(null)
    const [anchorNotification, setAnchorNotification] = useState(null)
    const showMenu = Boolean(anchorMenu)
    const showNotification = Boolean(anchorNotification)
    const query = queryParamHelper.getQueryParam('selectedClient', location.search)
    const [clients, setClients] = useState([])

    React.useEffect(() => {
        clientInformationService.getAll(dispatch)
            .then((res) => {
                setClients(res.data)
            })
    }, [])


    let client = location.pathname === '/' ? (query ? query.value : 0) : params.id
  return <div>
    <AppBar position="static">
        <Toolbar variant="dense">
            <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} width={'100%'} height={'4rem'}>
            
                <Stack display={'flex'} justifyContent={'space-between'} flexDirection={'row'} width={'40%'} alignItems={'center'}>
                    <Link to={'/'}>
                        <ImageListItem sx={{
                            height : '2.5rem',
                            width : '2.5rem',
                            backgroundPosition : 'cover'
                        }}>
                            <img src="https://aws-n-virginia-cytech.s3.amazonaws.com/cockpit/assets/images/cockpit_logo.png" alt="" />
                        </ImageListItem>
                    </Link>
                    <div style={{height: '40px', paddingLeft: '27px'}}>
                        <img 
                        // width={'90%'}
                        height={'100%'}
                        src={(JSON.parse(localStorage.getItem('auth')).role_name === userRole.CLIENT_ADMIN 
                        || JSON.parse(localStorage.getItem('auth')).role_name === userRole.CLIENT_USER) ? JSON.parse(localStorage.getItem('auth')).img_url :
                        ((!query) ? "https://aws-n-virginia-cytech.s3.amazonaws.com/cytech-webpage/assets/images/CyTech.png" : clients.find(x => x.id == query.value)?.image)}
                        /* style={{ filter: 'brightness(0) invert(1)', height: '85%', width: '90%' }} */
                        alt="Placeholder Image" 
                        />
                    </div>
                    <Box sx={{
                        display : 'flex',
                        justifyContent : 'flex-start',
                        alignItems : 'center',
                        width : '100%',
                        marginLeft : '2rem'
                    }}>
                        <Link to={'/'} style={{ textDecoration : 'none', color : 'white' }}>
                            <List>
                                <ListItemButton>
                                    <ListItemIcon>
                                        <DashboardOutlined color={location.pathname === '/' ? 'secondary' : 'action'}/>
                                    </ListItemIcon>
                                    <ListItemText>DASHBOARD</ListItemText>
                                </ListItemButton>
                            </List>
                        </Link>
                        {(JSON.parse(localStorage.getItem('auth')).role_name !== userRole.CLIENT_USER && JSON.parse(localStorage.getItem('auth')).role_name !== userRole.CLIENT_ADMIN) && <List sx={{ width : '100%' }}>
                            <ListItemButton>
                                <ListItemIcon>
                                    <DnsIcon/>
                                </ListItemIcon>
                                <SelectInput 
                                    options={[...clients.map((client) => ({ keyVal : client.id, value : client.name })), { keyVal : 0, value : 'Overall' }].filter((c) => {
                                        const user = JSON.parse(localStorage.getItem('auth'))

                                        if(user.role_name == userRole.CLIENT_USER){
                                            
                                            if(c.keyVal === user.client || c.keyVal === 0){
                                                return true
                                            }else{
                                                return false
                                            }
                                        }
                                        return true
                                    })} 
                                    defaultVal={client ? client : 0} 
                                    label={'Clients'}
                                    onChange={(clientId) => {

                                        dispatch(resetClientState())
                                        if(location.pathname.startsWith('/client-details/') && clientId > 0){
                                            navigate(`/client-details/${clientId}/?selectedClient=${clientId}`)
                                            return
                                        }
                                    
                                        if (clientId > 0){
                                            navigate(`/?selectedClient=${clientId}`)
                                        }else{
                                            navigate('/')
                                        }

                                        let client = clientId > 0 ? clientId : ''
                                        dispatch(fetchTasksOverdue({ clientId : client }))
                                        dispatch(fetchDeliveryRisk({ clientId : client }))
                                        dispatch(fetchTasksApproaching({ clientId : client }))
                                        dispatch(fetchOverallStatus({ clientId : client }))
                                        
                                    }}/>
                            </ListItemButton>
                        </List>}
                        {(query || location.pathname.startsWith('/client-details/'))  && <Link to={`/client-details/${query ? query.value : params.id}/?selectedClient=${query?.value}`} style={{ textDecoration : 'none', color : 'white' }}>
                            <List>
                                <ListItemButton>
                                    <ListItemIcon>
                                        <PlaylistAddIcon color={location.pathname.startsWith('/client-details/') ? 'secondary' : 'action'}/>
                                    </ListItemIcon>
                                    <ListItemText>Tasks</ListItemText>
                                </ListItemButton>
                            </List>
                        </Link>}
                        {(JSON.parse(localStorage.getItem('auth')).role_name === userRole.CLIENT_USER || JSON.parse(localStorage.getItem('auth')).role_name === userRole.CLIENT_ADMIN) && <List sx={{ width : '100%' }}>
                            <ListItemButton 
                                onClick={() => {
                                    const user = JSON.parse(localStorage.getItem('auth'))
                                    navigate(`/client-details/${user.client}`)
                                }}>
                                <ListItemIcon>
                                    <InfoIcon color={location.pathname === `/client-details/${params.id}` ? 'secondary' : 'action'}/>
                                </ListItemIcon>
                                <ListItemText>Details</ListItemText>
                            </ListItemButton>
                        </List>}
                        {(JSON.parse(localStorage.getItem('auth')).role_name !== userRole.CLIENT_USER && JSON.parse(localStorage.getItem('auth')).role_name !== userRole.CLIENT_ADMIN) && <List sx={{ width : '100%' }}>
                            <ListItemButton onClick={() => {
                                navigate('/assigned-me')
                            }}>
                                <ListItemIcon>
                                    <FormatListBulletedIcon color={location.pathname === '/assigned-me' ? 'primary' : 'action'}/>
                                </ListItemIcon>
                                <ListItemText sx={{ whiteSpace : 'nowrap' }}>My Tasks</ListItemText>
                            </ListItemButton>
                        </List>}
                    </Box>
                    
                </Stack>
                
                <Box sx={{
                    display : 'flex',
                    alignItems : 'center',
                    justifyContent : 'flex-end'
                }}>
                    <Tooltip title='Today'>
                        <Typography sx={{ marginRight : '2rem' }} variant='subtitle2' component={'p'} color={'#11A0DB'}>{moment(new Date()).format('dddd, YYYY MMMM DD')}</Typography>
                    </Tooltip>
                    {/* .-. -- -... Notifications */}
                    <IconButton onClick={(event) => setAnchorNotification(event.currentTarget)}>
                        <Badge badgeContent={count} color='warning'>
                            <NotificationsIcon color='action'/>
                        </Badge>
                    </IconButton>
                    <IconButton onClick={(event) => setAnchorMenu(event.currentTarget)}>
                        <AccountCircleIcon/>
                    </IconButton>
                </Box>
                <Menu
                    onClose={() => setAnchorMenu(null)}
                    anchorEl={anchorMenu}
                    open={showMenu} >
                    <MenuItem onClick={() => {

                        dispatch(clear())
                        navigate('/login')
                        // localStorage.removeItem('auth')
                        // navigate('/')
                        // window.location.reload()
                    }}>LOGOUT</MenuItem>
                </Menu>
                <Menu 
                    anchorEl={anchorNotification}
                    onClose={() => setAnchorNotification(null)}
                    open={showNotification}>
                    <Box sx={{
                        display : 'flex',
                        flexDirection : 'column',
                        alignItems : 'flex-start',
                        position : 'relative',
                        overflow : 'auto',
                        overflowY : 'scroll',
                        height : '65vh',
                        padding : '1rem',
                        width : '55vh'
                    }}>
                        <Typography variant='subtitle1' component={'b'} fontSize={'23px'} fontWeight={'800'} sx={{
                        }}>Notifications</Typography>
                        
                        <NotificationList notifications={notifications}/>
                    </Box>
                </Menu>
            </Box>
        </Toolbar>
    </AppBar>
  </div>
}
