import authSlice from "./auth/authSlice"
import clientInformationSlice from "./clientinformation/clientInformationSlice"
import dashboardSlice from "./dashboard/dashboardSlice"
import deliverableSlice from "./deliverable/deliverableSlice"
import notificationSlice from "./notification/notificationSlice"
import operationalTaskCommentSlice from "./operational-task-comment/operationalTaskCommentSlice"
import operationalSlice from "./operational/operationalSlice"
import stakeholderSlice from "./stakeholder/stakeholderSlice"
import strategicSlice from "./strategic/strategicSlice"
import tacticalSlice from "./tactical/tacticalSlice"


const rootReducer = {
    deliverable : deliverableSlice,
    strategic : strategicSlice,
    tactical : tacticalSlice,
    operational : operationalSlice,
    stakeholder : stakeholderSlice,
    auth : authSlice,
    clientInfo : clientInformationSlice,
    operationalCommentSlice : operationalTaskCommentSlice,
    notification : notificationSlice,
    dashboard : dashboardSlice
}

export default rootReducer