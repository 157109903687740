import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import deliverableService from "../../services/deliverableService";
import strategicService from "../../services/strategicService";
import tacticalService from "../../services/tacticalService";



const initialState = {
    isLoading : false,
    isSuccess  : false,
    isError : false,
    tacticals : [],
    tactical : null,
    response : null,
    showForm : false,
    showAlert : false,
    message : '',
    isDeleteLoading : false,
    isSubmitting : false
}

export const getAllTacticals = createAsyncThunk(
    'tactical/getAll',
    async (params, thunk) => {
        try {
            const res = await tacticalService.getAll({ strategicId : params?.strategicId, dispatch : thunk.dispatch })
            return res.data
        } catch (error) {
            return thunk.rejectWithValue(error.response.data)
        }
    }
)

export const createTactical = createAsyncThunk(
    'tactical/create',
    async (params, thunk) => {
        try {
            const res = await tacticalService.create({data : params.data, dispatch : thunk.dispatch})
            return res.data
        } catch (error) {
            return thunk.rejectWithValue(error.response.data) 
        }
    }
)

export const updateTactical = createAsyncThunk(
    'tactical/update',
    async (params, thunk) => {
        try {
            const res = await tacticalService.update({updatedData : params.data, dispatch : thunk.dispatch})
            return res.data
        } catch (error) {
            return thunk.rejectWithValue(error.response.data) 
        }
    }
)

export const deleteTactical = createAsyncThunk(
    'tactical/delete',
    async  (params, thunk) => {
        try {
            const res = await tacticalService.deleteById({id : params.id})
            return res.data
        } catch (error) {
            return thunk.rejectWithValue(error.response.data)
        }
    }
)

const tacticalSlice = createSlice({
    name : 'tactical',
    initialState,
    reducers : {
        showForm : (state) => {
            state.showForm = true
        },
        resetFormState : (state) => {
            state.showForm = false
        },
        showAlert : (state) => {
            state.showAlert = true
        },
        resetAlertState : (state) => {
            state.showAlert = false
        },
        setTactical : (state, action) => {
            state.tactical = action.payload
        },
        setTacticalIsSubmitting : (state, action) => {
            state.isSubmitting = action.payload
        },
        alterTactical : (state, action) => {
            const index = state.tacticals.findIndex(t => t.id == action.payload.id)
            state.tacticals[index] = action.payload
        }
    },
    extraReducers : (builder) => {
        builder.addCase(getAllTacticals.pending, (state) => {
            state.isError = false
            state.isSuccess = false
            state.isLoading = true
            state.tacticals = []
        }).addCase(getAllTacticals.fulfilled, (state, action) => {
            state.isError = false
            state.isSuccess = true
            state.isLoading = false
            state.tacticals = action.payload
        }).addCase(getAllTacticals.rejected, (state, action) => {
            state.isError = true
            state.isLoading = false
            state.isSuccess = false
            state.tacticals = []
        }).addCase(createTactical.pending, (state) => {
            state.isError = false
            state.isSuccess = false
            state.isSubmitting = true
        }).addCase(createTactical.fulfilled, (state, action) => {
            state.isError = false
            state.isSuccess = true
            state.isSubmitting = false
            state.tacticals.push(action.payload)
            state.response = action.payload
            state.showAlert = true
            state.message = 'Tactical successfully added'
            state.showForm = false
        }).addCase(createTactical.rejected, (state, action) => {
            state.isError = true
            state.isLoading = false
            state.isSubmitting = false
            state.response = action.payload
            state.showAlert = true
            state.message = 'Failed to add tactical'
        }).addCase(updateTactical.pending, (state) => {
            state.isError = false
            state.isSuccess = false
            state.isSubmitting = true
        }).addCase(updateTactical.fulfilled, (state, action) => {
            state.isError = false
            state.isSuccess = true
            state.isSubmitting = false
            state.showAlert = true
            state.message = 'Tactical successfully modified'
            state.showForm = false
            const index = state.tacticals.findIndex(t => t.id == action.payload.id)
            state.tacticals[index] = action.payload
            state.response = action.payload
        }).addCase(updateTactical.rejected, (state, action) => {
            state.isError = true
            state.isSubmitting = false
            state.isSuccess = false
            state.response = action.payload
            state.showAlert = true
            state.message = 'Failed to modified tactical'
        }).addCase(deleteTactical.pending, (state) => {
            state.isError = false
            state.isSuccess = false
            state.isDeleteLoading = true
        }).addCase(deleteTactical.fulfilled, (state, action) => {
            state.isError = false
            state.isSuccess = true
            state.isDeleteLoading = false
            state.showAlert = true
            state.message = 'Tactical successfully deleted'
            state.showForm = false
            state.tacticals = state.tacticals.filter(t => t.id !== state.tactical.id)
        }).addCase(deleteTactical.rejected, (state, action) => {
            state.isError = true
            state.isDeleteLoading = false
            state.isSuccess = false
            state.response = action.payload
            state.showAlert = true
            state.message = 'Failed to delete tactical'
        })
    }
    
})

export const { showAlert, showForm, resetAlertState, resetFormState, setTactical, alterTactical, setTacticalIsSubmitting } = tacticalSlice.actions
export default tacticalSlice.reducer