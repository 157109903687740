import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Paper, TextField, Typography } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { FilePicker } from '../common/FilePicker'
import AttachFileIcon from '@mui/icons-material/AttachFile';
import fileUploadService from '../../services/fileUploadService';
import { Link } from 'react-router-dom';

export const AttachFile = ({ onAttach, isOnAttach = false, dir }) => {

    const [files, setFiles] = useState([])
    const [loading, setLoading] = useState(false)
    const [fileUrls, setFileUrls] = useState([])
    const [desc, setDesc] = useState("")

    const [isOnattachRef, setIsOnAttachRef] = useState(false)
    const [fileExists, setFileExists] = useState(false)
    const [hitBtn, setHitBtn] = useState({
        loading : false,
        btn : ''
    })

    const onUpload = async (files) => {
        for (let file of files) {
            console.log((await fileUploadService.checkFileExists(`${dir}/${file.name}`)))
            const res = await fileUploadService.uploadToS3V2(file, dir)
            setFileUrls((prev) => [...prev, res.Location])
        }
        setLoading(false)
    }

    const onUploadV2 = async (files) => {
        
        const file = files[0]
        const res = await fileUploadService.uploadToS3V2(file, dir)
        setFileUrls((prev) => [...prev, res.Location])
        setLoading(false)
    }

    const getCopy = async (files) => {
        let file = files[0]
        let fileName = String(file.name)
        let fileNameArr = fileName.split('.')
        let newfile = fileNameArr[fileNameArr.length - 2] + "_" + Date.parse(new Date()).toString()
        let finalFile = newfile + '.' + fileNameArr[fileNameArr.length - 1]
        // console.log(finalFile)
        const res = await fileUploadService.reuploadToS3(finalFile, file, dir)
        setFileUrls((prev) => [...prev, res.Location])
        setLoading(false)
    }

    const processUpload = async (files) => {
        // check if the file is already exist in the cloud storage
        console.log('file exist: ', (await fileUploadService.checkFileExists(`${dir}/${files[0].name}`)))
        if((await fileUploadService.checkFileExists(`${dir}/${files[0].name}`))){
            // set fileExist state to true
            // to show dialog/modal to choose either replace the file from s3 or skip/cancel it.
            setFileExists(true)
            return
        }
        setLoading(true)
        // upload file in s3
        await onUploadV2(files)
    }

    const ellipsis = (url = '') => {
        return (url.length > 70) ? `${url.substring(0, 70)}...` : url
    }

    useEffect(() => {
        if(!isOnAttach && isOnattachRef){
            setIsOnAttachRef(false)
            setFileUrls([])
        }else{
            setIsOnAttachRef(true)
        }
    }, [isOnAttach])
    // const
  return (

    <Paper elevation={10} sx={{
        display : 'flex',
        flexDirection : 'column',
        alignItems : 'flex-start',
        padding : '1rem'
    }}>
        <Box sx={{ display : 'flex', justifyContent : 'flex-start', alignItems : 'center', width : '100%' }}>
            <FilePicker getData={(data) => {                                           
                setFiles((prev) => [...prev, ...data])
                // onUpload(data)
                console.log(`${dir}/${data[0].name}`)
                processUpload(data)
            }}/>
            <Typography>Attach Files</Typography>
        </Box>

        {loading ? <>
            <Box><CircularProgress size={20}/></Box>
        </> : <Box sx={{ display : 'flex', flexDirection : 'column', alignItems : 'flex-start', marginBottom : '2px', }}>
        {fileUrls.map((url, i) => (
            // <Typography key={i} variant='subtitle2' component={'span'} fontSize={'9px'}>{file.name}</Typography>
            <Link to={url} target='_blank' key={i}>
                <Typography variant='subtitle2' component={'span'} sx={{ 
                    marginLeft : '2px', 
                    color : 'white',
                    textOverflow : 'ellipsis',
                    overflow : 'hidden',
                    width : '10vh',
                    whiteSpace : 'nowrap'}}>
                    {String(url).split('/')[String(url).split('/').length - 1]}
                </Typography>
            </Link>
        ))}
        </Box>}
        {fileUrls.length > 0 && <Box sx={{
            display : 'flex',
            justifyContent : 'flex-start',
            flexDirection : 'row-reverse',
            alignItems : 'flex-end',
            marginTop : '1rem'
        }}>
                <Button disabled={isOnAttach} sx={{ marginLeft : '1rem' }} size='small' variant='contained' startIcon={<AttachFileIcon/>} onClick={() => {
                    if(desc.length < 1) return;

                    onAttach({ desc, file : fileUrls[0] })
                    setDesc("")
                    
                }}>ATTACH</Button>
                <TextField size='small' required label='Description' value={desc} multiline onChange={(event) => setDesc(event.currentTarget.value)}/>
            </Box>}
        <Dialog open={fileExists} onClose={() => setFileExists(false)} sx={{ zIndex : 99999 }}>
            <DialogTitle>Message</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        The file you are trying to upload already exist.
                    </DialogContentText>
                </DialogContent>
            <DialogActions>
                <Button disabled={hitBtn.loading} size='small' color='primary' variant='contained' onClick={() => {
                    setHitBtn(prev => ({
                        loading : true,
                        btn : 'upload'
                    }))
                    getCopy(files)
                        .then((res) => {
                            setHitBtn((prev) => ({ loading : false, btn : '' }))
                            setFileExists(false)
                            setFiles([])
                        })
                }}>{(hitBtn.loading && hitBtn.btn === 'upload') && <CircularProgress size={12} sx={{ marginRight : '3px' }}/>}Upload</Button>
                <Button disabled={hitBtn.loading} size='small' color='primary' variant='contained' onClick={() => {
                    setHitBtn(prev => ({
                        loading : true,
                        btn : 'overwrite'
                    }))
                    onUploadV2(files)
                        .then((res) => {
                            setHitBtn((prev) => ({ loading : false, btn : '' }))
                            setFileExists(false)
                            setFiles([])
                        })
                }}>{(hitBtn.loading && hitBtn.btn === 'overwrite') && <CircularProgress size={12} sx={{ marginRight : '3px' }}/>}Overwrite</Button>
                <Button disabled={hitBtn.loading} size='small' color='warning' variant='contained' onClick={() => {
                    setHitBtn(prev => ({
                        loading : true,
                        btn : 'cancel'
                    }))
                }}>{(hitBtn.loading && hitBtn.btn === 'cancel') && <CircularProgress size={12} sx={{ marginRight : '3px' }}/>}Cancel</Button>
            </DialogActions>
        </Dialog>
    </Paper>
    
  )
}
