import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { BrowserRouter as Router } from 'react-router-dom'
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Provider } from 'react-redux/es';
import store from './features/store';

const theme = createTheme({
  typography : {
    "fontFamily" : '"Open Sans", sans-serif',
    "fontSize" : 14
  },
  palette: {
    mode: 'dark',
    warning : {
      light: '#ff7961',
      main: '#f44336',
      dark: '#f44336',
      contrastText: '#000',
    },
    secondary: {
      light: '#ff7961',
      main: '#11A0DB',
      dark: '#11A0DB',
      contrastText: '#000',
    },
    primary : {
      light: '#ff7961',
      main: '#F99D1B',
      dark: '#F99D1B',
      contrastText: '#000',
    },
    info : {
      light: '#ff7961',
      main: '#67AD45',
      dark: '#67AD45',
      contrastText: '#000',
    },
    
    
  },
})

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline/>
      <Router>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Provider store={store}>
            <App/>
          </Provider>
        </LocalizationProvider>
      </Router>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
