import { Box, CircularProgress, Divider, Drawer } from '@mui/material'
import React from 'react'

export const TaskDrawer = ({ taskComponent, open, onClose, zIndex = '9000', isLoading, components }) => {
  return (
    <>
        <Drawer 
            sx={{
                zIndex : zIndex
            }}
            anchor='right'
            open={open}
            onClose={() => {
                onClose()
            }}>
                <Box
                    sx={{
                        display : 'flex',
                        flexDirection : 'column',
                        alignItems : 'flex-start',
                        width : '40rem'
                    }}>
                    {/* <Divider sx={{ width : '100%', marginTop : '2rem' }}/> */}
                    {isLoading ? <>
                        <Box sx={{
                            display : 'flex',
                            justifyContent : 'center',
                            alignItems : 'center',
                            width : '100%'
                        }}>
                            <CircularProgress size={50}/>
                        </Box>
                    </> : <Box sx={{
                        width : '100%'
                    }}>
                        {taskComponent}
                        {components}
                        </Box>}
                </Box>
            </Drawer>
    </>
  )
}
