import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Avatar, AvatarGroup, Box, Button, CircularProgress, Collapse, Divider, Drawer, Grid, IconButton, ImageListItem, InputAdornment, List, ListItemButton, ListItemIcon, ListItemText, Modal, Paper, Stack, SwipeableDrawer, TextField, Tooltip, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import SendIcon from '@mui/icons-material/Send';
import AddIcon from '@mui/icons-material/Add';
import EditNoteIcon from '@mui/icons-material/EditNote';
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import React, { useState, useEffect } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import DeleteIcon from '@mui/icons-material/Delete';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { clients, strategicTasks } from '../../sampleData'
import { FilePicker } from '../../components/common/FilePicker';
import { DatePicker, DateTimeField } from '@mui/x-date-pickers';
import { DataGrid } from '@mui/x-data-grid';
import { KeyboardArrowDown, KeyboardArrowUp, SearchOutlined } from '@mui/icons-material';
import DeliverableDetails from '../../components/dashboard/clientdetails/DeliverableDetails';
import { AddTasksAndViewDeliverableDetails } from '../../components/dashboard/clientdetails/AddTasksAndViewDeliverableDetails';
import clientInformationService from '../../services/clientInformationService';
import { useDispatch, useSelector } from 'react-redux/es';
import { getAllDeliverablesByClient, createClientDeliverable, updateClientDeliverable, resetFormState, resetAlertState, showForm as deliverableShowForm, setDeliverableId, deleteClientDeliverable } from '../../features/deliverable/deliverableSlice';
import moment from 'moment';
import initialValues from '../../validators/deliverable/initialValues';
import saveDeliverableValidator from '../../validators/deliverable/saveDeliverableValidator';
import { InputField } from '../../components/common/InputField';
import saveDeliverable from '../../validators/deliverable/saveDeliverable';
import { SaveDeliverableForm } from '../../components/dashboard/clientdetails/forms/SaveDeliverableForm';
import { TaskSearchedResultContainer } from '../../components/dashboard/clientdetails/resultsearchedtask/TaskSearchedResultContainer';
import strategicService from '../../services/strategicService';
import queryParamHelper from '../../helpers/queryParamHelper';
import { SaveOperationalForm } from '../../components/dashboard/clientdetails/forms/SaveOperationalForm';
import operationalService from '../../services/operationalService';
import { updateOperational } from '../../features/operational/operationalSlice';
import { toast } from 'react-toastify';
import { TasksWrapper } from '../../components/dashboard/clientdetails/TasksWrapper';
import { SaveClientInfoForm } from '../../components/dashboard/clientdetails/forms/SaveClientInfoForm';
import { getClient, showFormToggle, showForm as clientInformationShowForm, resetAlertState as clientInforResetAlertState, updateClient } from '../../features/clientinformation/clientInformationSlice';
import { userRole } from '../../constants/userRole';
import { getReportingPeriod } from '../../helpers/taskStatusHelper';
import { Comment } from '../../components/task/Comment';
import { CommentsAndAttachmentsTab } from '../../components/task/CommentsAndAttachmentsTab';
import { createOperationalComment, getAllOperationalComments } from '../../features/operational-task-comment/operationalTaskCommentSlice';
import taskAttachmentService from '../../services/taskAttachmentService';
import { LinearProgressWithLabel } from '../../components/common/LinearProgressWithLabel';
import operationalCommentService from '../../services/operationalCommentService';
import { getAllStakeholder } from '../../features/stakeholder/stakeholderSlice';

export const ClientDetails = () => {

    const { formField : { name, plannedStartDate, plannedDueDate, actualStartDate, actualDueDate }, formId } = saveDeliverable
    
    const [search, setSearch] = useState("")
    const [taskSearchResults, setTaskSearchResults] = useState([])

    const [open, setOpen] = useState(false)
    const [expandedString, setExpandedString] = useState('detail1')
    const [expandedStringTwo, setExpandedStringTwo] = useState('detail2')
    const [expandedStringThree, setExpandedStringThree] = useState('detail3')
    const [taskDetailsShow, setTaskDetailShow] = useState(false)
    const [showAddAssigneeForm, setShowAddAssigneeForm] = useState(false)

    const [showTacticalTasksTable, setShowTacticalTasksTable] = useState(false)

    const [files, setFiles] = useState([])
    const { id } = useParams()
    const location = useLocation()
    const navigate = useNavigate()
    const [queryParams, setQueryParams] = useSearchParams()

    // const [clientDetails, setClientDetails] = useState(null)
    const [clientDetailsIsLoading, setClientDetailsIsLoading] = useState(false)

    {/* hooks for modals */}
    const [showSaveDeliverableModal, setShowSaveDeliverableModal] = useState(false)
    const [editDeliverable, setEditDeliverable] = useState(false)
    const [selectedDeliverable, setSelectedDeliverable] = useState(null)

    const [showAddTasksModal, setShowAddTasksModal] = useState(false)
    const [showAddStrategicModal, setShowAddStrategicModal] = useState(false)
    const [showAddTacticModal, setShowAddTacticModal] = useState(false)
    const [showAddAndViewOperationalModal, setShowAddAndViewOperationalModal] = useState(false)
    const [showSaveOperationalForm, setShowSaveOperationalForm] = useState(false)
    const [showTacticalAndOperationalDetailModal, setShowTacticalAndOperationalDetailModal] = useState(false)

    const [operationalTask, setEditOperationalTask] = useState(null)

    const [showSaveClientInfoModal, setShowSaveClientInfoModal] = useState(false)
    const [isOnAttaching, setIsOnAttaching] = useState(false)
    
    /* Delete confirmation state */
    const [isDeleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
    const [DeliverableIDData, setDeliverableIDData] = useState(null);

    {/* working with Redux */}
    const dispatch = useDispatch()
    const { isLoading, isSuccess, isError, deliverables, showForm, showAlert, alertMessage, deliverableId, isDeleteLoading } = useSelector(state => state.deliverable)
    const { isLoading : clientInfoLoading, client : clientDetails, showForm : clientInfoShowForm, showAlert : clientInfoShowAlert, isError : isClientInfoError } = useSelector(state => state.clientInfo)
    const { status, comments } = useSelector(state => state.operationalCommentSlice)
    const [attachments, setAttachments] = useState([])
    const operationalIdQueryString = queryParamHelper.getQueryParam('operationalId', location.search)

    useEffect(() => {
        setShowSaveOperationalForm(operationalIdQueryString != null)
        if(clientDetails == null){
            dispatch(getClient({ id : id }))
        }

        if (operationalIdQueryString != null){
            loadOperationalTask()
        }

        if(showAlert){
            showDeliverableAlert()
        }

        if(clientInfoShowAlert){
            showClientInfoAlert()
        }

        return () => { 
            dispatch(resetAlertState())
            dispatch(clientInforResetAlertState())
        }
        // console.log(queryParams)
    }, [dispatch, id, showAlert, clientInfoShowAlert, clientDetails])

    useEffect(() => {
        dispatch(getAllDeliverablesByClient({ clientInfoId : id }))
    }, [id])

    

    const showDeliverableAlert = () => {
        if(isSuccess){
            toast.success(alertMessage)
        }else{
            toast.error('Somethin went wrong')
        }
    }

    const showClientInfoAlert = () => {
        toast.success('Client information updated successfully!')
    }

    const loadOperationalTask = () => {
        operationalService.getOne({ id : operationalIdQueryString.value })
            .then((res) => {
                setEditOperationalTask(res.data)
            })

        dispatch(getAllOperationalComments({ operationalId : operationalIdQueryString.value }))

        taskAttachmentService.getAllOperationalAttachments({ operationalId : operationalIdQueryString.value })
            .then((res) => setAttachments(res.data))
            .catch()
    }

    console.log(showTacticalAndOperationalDetailModal)

  return ( clientInfoLoading ? <>
    <Box sx={{
        display : 'flex',
        justifyContent : 'center',
        alignItems : 'center',
    }}>
        <CircularProgress color='warning' size={50} sx={{
            marginTop : '20rem'
        }}/>
    </Box>
  </> :
    <Box height='100%'>
        <Box sx={{
            display : 'flex',
            justifyContent : 'space-between',
            alignItems : 'center'
        }}>
            <IconButton color='secondary' onClick={() => setOpen(true)} title='Show Menu'>
                <KeyboardArrowRightIcon/>
            </IconButton>
            <Box>

            </Box>
        </Box>
        <SwipeableDrawer variant='persistent' anchor='left' open={open} sx={{ zIndex : '-1' }} onClose={() => {}} onOpen={() => setOpen(true)}>
            <Box sx={{ minWidth : '15rem', display : 'flex', flexDirection : 'column', justifyContent : 'center' }}>
                <Box sx={{
                    display : 'flex',
                    justifyContent : 'space-between',
                    alignItems : 'center',
                    padding : '1rem'
                }}>
                    <ImageListItem sx={{
                        height : '2.5rem',
                        width : '2.5rem'
                    }}>
                        <img src="../../logos/cockpit_logo.png" />
                    </ImageListItem>
                    <IconButton onClick={() => setOpen(false)}>
                        <CloseIcon/>
                    </IconButton>
                </Box>
                <Paper sx={{margin : '1rem', borderRadius : '1rem'}}>
                    <Box sx={{
                        width : '100%',
                        display : 'flex',
                        justifyContent : 'center',
                        padding : '2rem',
                        flexDirection : 'column',
                        alignItems : 'center'
                    }}>
                        <Typography variant='p' fontSize={'27px'} fontWeight={'bold'} color={'secondary'}>{clientDetails?.name}</Typography>
                        <Typography variant='small' fontSize={'13px'} >{'Client'}</Typography>
                    </Box>
                </Paper>
                <Divider/>
                <Paper sx={{margin : '1rem', borderRadius : '1rem'}}>
                    <Box sx={{
                        width : '100%',
                        display : 'flex',
                        justifyContent : 'center',
                        flexDirection : 'column',
                        alignItems : 'center',
                        paddingX : '0.5rem'
                    }}>
                        <List sx={{width : '100%'}} defaultChecked>
                            <ListItemButton>
                                <ListItemText >Detail</ListItemText>
                                <ListItemIcon>
                                    <InfoIcon color='info'/>
                                </ListItemIcon>
                            </ListItemButton>
                        </List>
                    </Box>
                </Paper>
            </Box>
        </SwipeableDrawer>
        {/* Pages */}


        <Box sx={{
            paddingX : '8rem',
            paddingBottom : '5rem',
            display : 'flex',
            justifyContent : 'flex-start'
        }}>
            <Box width={'100%'}>
                <Accordion expanded={expandedString === 'detail1'} onChange={() => setExpandedString((expandedString === 'detail1' ? '' : 'detail1'))}>
                    <AccordionSummary expandIcon={<ArrowForwardIosSharpIcon/>}>
                        <Typography>Client Information</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {(JSON.parse(localStorage.getItem('auth')).role_name === userRole.CYTECH_ADMIN || JSON.parse(localStorage.getItem('auth')).role_name === userRole.CLIENT_ADMIN) && <Box sx={{
                            display : 'flex',
                            justifyContent : 'flex-end'
                        }}>
                            <Button startIcon={<EditNoteIcon/>} onClick={() => {
                                dispatch(clientInformationShowForm())
                                dispatch(getAllStakeholder({ client : Number(JSON.parse(localStorage.getItem('auth')).client_data.id) }))
                            }}>Edit</Button>
                        </Box>}
                        <Box sx={{
                            width : '100%',
                            display : 'flex',
                            justifyContent : 'center',
                            alignItems : 'center'
                        }}>
                            <Stack sx={{
                                paddingBottom : '1rem',
                                alignItems : 'center'
                            }}>
                                <Typography>Overall</Typography>
                                <Typography fontSize={40}>{deliverables?.length > 0 ? Number((deliverables.map(x => x.progress).reduce(((a, b) => a + b), 0) / deliverables?.length)).toPrecision(3) + '%' : '0'}</Typography>
                            </Stack>
                        </Box>
                        <TableContainer component={Paper} sx={{ marginleft : '1rem', marginTop : '1rem', borderRadius : '20px' }}>
                            <Table sx={{ width : '100%' }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Start Work Date</TableCell>
                                        <TableCell align="left">Mission Statement</TableCell>
                                        <TableCell>Account Manager</TableCell>
                                        <TableCell align="left">Security Champion</TableCell>
                                        <TableCell>Reporting Period</TableCell>
                                        <TableCell align="left">SecOps SME</TableCell>
                                        <TableCell>Customer Success Rep</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                <TableRow
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {moment(clientDetails?.start_date).format('MM/DD/YYYY')}
                                    </TableCell>
                                    <TableCell align="left">{clientDetails?.mission_statement}</TableCell>
                                    <TableCell align="left">{clientDetails?.account_manager}</TableCell>
                                    <TableCell align="left">{clientDetails?.security_champion}</TableCell>
                                    <TableCell align="left">{getReportingPeriod(clientDetails?.reporting_period)?.value}</TableCell>
                                    <TableCell align="left">{clientDetails?.sec_ops_sme ? clientDetails?.sec_ops_sme : '-'}</TableCell>
                                    <TableCell align="left">{clientDetails?.customer_success_rep ? clientDetails?.customer_success_rep : '-'}</TableCell>
                                </TableRow>
                                
                            </TableBody>
                            </Table>
                        </TableContainer>
                    </AccordionDetails>
                </Accordion>
                
                <Accordion expanded={expandedStringTwo === 'detail2'} onChange={() => setExpandedStringTwo((expandedStringTwo === 'detail2' ? '' : 'detail2'))}>
                    <AccordionSummary expandIcon={<ArrowForwardIosSharpIcon/>}>
                        <Typography>Deliverables</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {/* {JSON.parse(localStorage.getItem('auth')).role_name !== userRole.CLIENT_USER && <Box sx={{
                            display : 'flex',
                            justifyContent : 'flex-end',
                            alignItems : 'center'
                        }}>
                            <Tooltip title='ADD DELIVERABLE'>
                                <Button startIcon={<AddIcon/>} size='small' color='inherit' onClick={() => {
                                    setShowSaveDeliverableModal(true)
                                    // setShowSaveDeliverableModal(false)
                                    setShowTacticalAndOperationalDetailModal(false)
                                    setEditDeliverable(false)
                                    dispatch(deliverableShowForm())
                                }}>DELIVERABLE</Button>
                            </Tooltip>
                        </Box>} */}
                        <Box sx={{
                            display : 'flex',
                            justifyContent : 'flex-end',
                            alignItems : 'center'
                        }}>
                            <Tooltip title='ADD DELIVERABLE'>
                                <Button startIcon={<AddIcon/>} size='small' color='inherit' onClick={() => {
                                    setShowSaveDeliverableModal(true)
                                    // setShowSaveDeliverableModal(false)
                                    setShowTacticalAndOperationalDetailModal(false)
                                    setEditDeliverable(false)
                                    dispatch(deliverableShowForm())
                                }}>DELIVERABLE</Button>
                            </Tooltip>
                        </Box>
                        <TableContainer component={Paper} sx={{ marginleft : '1rem', marginTop : '1rem', borderRadius : '20px' }}>
                            <Table sx={{ width : '100%' }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Deliveries</TableCell>
                                        <TableCell>Progress</TableCell>
                                        <TableCell align="left">Planned Start Date</TableCell>
                                        <TableCell align="left">Planned Due Date</TableCell>
                                        <TableCell align="left">Actual Start Date</TableCell>
                                        <TableCell align="left">Actual Due Date</TableCell>
                                        <TableCell align="left" size='small'>ACTIONS</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                {deliverables?.map((deliverable, i) => (
                                    <TableRow  
                                    key={i}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 }, ":hover" : { cursor : 'pointer', backgroundColor : '#222831',
                                    transition : 'all 0.3s ease', } }}
                                    onClick={(event) => {
                                        if(event.clientX <= 1115){
                                            setShowTacticalAndOperationalDetailModal(true)
                                            setSelectedDeliverable(deliverable)
                                        }
                                    }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {deliverable?.name}
                                        </TableCell>
                                        <TableCell align='left' component={'th'} scope='row'>
                                            <LinearProgressWithLabel value={deliverable?.progress}/>
                                        </TableCell>
                                        <TableCell align="left">{moment(deliverable?.planned_start_date).format('YYYY-MM-DD') === "1900-01-01" ? "-" : moment(deliverable?.planned_start_date).format('YYYY-MM-DD')}</TableCell>
                                        <TableCell align="left">{moment(deliverable?.planned_due_date).format('yyyy-MM-DD')}</TableCell>
                                        <TableCell align="left">{moment(deliverable?.actual_start_date).format('YYYY-MM-DD') === "1900-01-01" ? "-" : moment(deliverable?.actual_start_date).format('YYYY-MM-DD')}</TableCell>
                                        <TableCell align="left">{moment(deliverable?.actual_due_date).format('YYYY-MM-DD') === "1900-01-01" ? "-" : moment(deliverable?.actual_due_date).format('YYYY-MM-DD')}</TableCell>
                                        <TableCell
                                        align="left"
                                        sx={{
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                        }}
                                        >
                                            <Tooltip title='VIEW TASKS'>
                                                <IconButton size='large' color='secondary' variant='contained' onClick={() => {
                                                    // console.log(deliverable.id)
                                                    setEditDeliverable(false)
                                                    setSelectedDeliverable(deliverable)
                                                    setShowAddTasksModal(true)
                                                }}>
                                                    <VisibilityIcon/>
                                                </IconButton>
                                            </Tooltip>
                                            {/* {(JSON.parse(localStorage.getItem('auth')).role_name === userRole.CYTECH_ADMIN) && <Tooltip title='EDIT' sx={{ marginLeft : '1rem' }}>
                                                <IconButton size='large' variant='contained' color='primary' onClick={() => {
                                                    // console.log(deliverable.id)
                                                    setSelectedDeliverable(deliverable)
                                                    // setShowAddTasksModal(true)
                                                    setEditDeliverable(true)
                                                    // setShowSaveDeliverableModal(true)
                                                    dispatch(deliverableShowForm())
                                                    setShowTacticalAndOperationalDetailModal(false)
                                                }}>
                                                    <EditNoteIcon/>
                                                </IconButton>
                                            </Tooltip>} */}
                                            <Tooltip title='EDIT' sx={{ marginLeft : '1rem' }}>
                                                <IconButton size='large' variant='contained' color='primary' onClick={() => {
                                                    // console.log(deliverable.id)
                                                    setSelectedDeliverable(deliverable)
                                                    // setShowAddTasksModal(true)
                                                    setEditDeliverable(true)
                                                    // setShowSaveDeliverableModal(true)
                                                    dispatch(deliverableShowForm())
                                                    setShowTacticalAndOperationalDetailModal(false)
                                                }}>
                                                    <EditNoteIcon/>
                                                </IconButton>
                                            </Tooltip>
                                            {/* {(JSON.parse(localStorage.getItem('auth')).role_name === userRole.CYTECH_ADMIN) && <Tooltip title='DELETE' sx={{ marginLeft : '1rem' }}>
                                                {(isDeleteLoading && deliverableId === deliverable.id) ? <CircularProgress size={20}/> : <IconButton size='large' variant='contained' color='error' onClick={() => {
                                                    // dispatch(setDeliverableId(deliverable.id))
                                                    // dispatch(deleteClientDeliverable({ id : deliverable.id }))
                                                    console.log("DeliverableId: ",deliverable.id)
                                                    setDeliverableIDData(deliverable.id)
                                                    setDeleteConfirmationOpen(true);
                                                }}>
                                                    <DeleteIcon/>
                                                    </IconButton>}
                                            </Tooltip>} */}
                                            <Tooltip title='DELETE' sx={{ marginLeft : '1rem' }}>
                                                {(isDeleteLoading && deliverableId === deliverable.id) ? <CircularProgress size={20}/> : <IconButton size='large' variant='contained' color='error' onClick={() => {
                                                    // dispatch(setDeliverableId(deliverable.id))
                                                    // dispatch(deleteClientDeliverable({ id : deliverable.id }))
                                                    console.log("DeliverableId: ",deliverable.id)
                                                    setDeliverableIDData(deliverable.id)
                                                    setDeleteConfirmationOpen(true);
                                                }}>
                                                    <DeleteIcon/>
                                                    </IconButton>}
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                ))}
                                
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </AccordionDetails>
                </Accordion>

            </Box>
            {taskDetailsShow && <Paper sx={{ width : '50%', height : '100%', padding : '1.5rem', marginLeft : '1rem', display : 'flex', flexDirection : 'column', alignItems : 'flex-start', borderRadius : '1rem' }}>
                <Box sx={{
                    display : 'flex',
                    justifyContent : 'flex-end',
                    alignItems : 'center',
                    width : '100%'
                }}>
                    
                    <IconButton onClick={() => setTaskDetailShow(false)} title='Close'>
                        <DoNotDisturbOnIcon color='warning'/>
                    </IconButton>
                </Box>
                <Divider flexItem/>
                {/* <Box sx={{ display  :'flex', flexDirection : 'column', alignItems : 'flex-start' }}>
                    <Box sx={{ display : 'flex', flexDirection  : 'column', paddingY : '0.3rem', border : 'GrayText' }}>
                        <Typography variant='h5'>Tactical Tasks</Typography>
                        <Box sx={{ display : 'flex', flexDirection : 'column', paddingLeft : '1.5rem' }}>
                            <Box marginBottom={'0.2rem'}>
                                <Typography fontSize={'12px'} variant='subtitle2' component={'span'} color={'#cfd8dc'}>{'Cyber Defense, Privacy, and Data Protection Map of Controls for prevention, detection'}</Typography>
                            </Box>
                            <Divider/>
                        </Box>
                    </Box>
                    <Box sx={{ display : 'flex', flexDirection  : 'column', paddingY : '0.3rem', border : 'GrayText' }}>
                        <Typography variant='h5'>Operational Tasks</Typography>
                        <Box sx={{ display : 'flex', flexDirection : 'column', paddingLeft : '1.5rem' }}>
                            <Box marginBottom={'0.2rem'}>
                                <Typography fontSize={'12px'} variant='subtitle2' component={'span'} color={'#cfd8dc'}>{'Interviews itzik and Tomer'}</Typography>
                            </Box>
                            <Divider/>
                            <Box marginBottom={'0.2rem'}>
                                <Typography fontSize={'12px'} variant='subtitle2' component={'span'} color={'#cfd8dc'}>{'Interviews itzik and Tomer'}</Typography>
                            </Box>
                            <Divider/>
                            <Box marginBottom={'0.2rem'}>
                                <Typography fontSize={'12px'} variant='subtitle2' component={'span'} color={'#cfd8dc'}>{'Interviews itzik and Tomer'}</Typography>
                            </Box>
                            <Divider/>
                        </Box>
                    </Box>
                </Box> */}
                {/* <Accordion sx={{ width : '100%' }} expanded={expandedStringThree === 'detail3'} onChange={() => setExpandedStringThree((expandedStringThree === 'detail3' ? '' : 'detail3'))}>
                    <AccordionSummary expandIcon={<ArrowForwardIosSharpIcon/>}>
                        <Typography>Tasks</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        
                    </AccordionDetails>
                </Accordion> */}
                
                <Box sx={{
                    display : 'flex',
                    flexDirection : 'column',
                    alignItems : 'flex-start',
                    width : '100%'
                }}>
                    <Box sx={{ display : 'flex', flexDirection : 'column', alignItems : 'flex-start', width : '100%', marginY : '0.6rem' }}>
                        
                        <Box sx={{ display : 'flex', alignItems : 'center', width : '100%', marginTop : '0.3rem', }}>
                            <Accordion sx={{ width : '100%' }}>
                                <AccordionSummary expandIcon={<ArrowForwardIosSharpIcon/>}>
                                    <Typography fontSize={'10px'} variant='subtitle2' component={'span'}>UPLOAD</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Box sx={{ display : 'flex', justifyContent : 'flex-start', alignItems : 'center' }}>
                                        <FilePicker getData={(data) => {                                           
                                            setFiles((prev) => [...prev, ...data])
                                            console.log(files)
                                        }}/>
                                        <Box sx={{ display : 'flex', flexDirection : 'column', alignItems : 'flex-start', marginLeft : '1.2rem' }}>
                                            {files.map((file, i) => (
                                                <Typography key={i} variant='subtitle2' component={'span'} fontSize={'9px'}>{file.name}</Typography>
                                            ))}
                                        </Box>
                                    </Box>
                                </AccordionDetails>
                            </Accordion>
                        </Box>
                    </Box>

                    <Box sx={{ display : 'flex', flexDirection : 'column', alignItems : 'flex-start', width : '100%', marginY : '0.8rem' }}>
                        <Box sx={{ display : 'flex', justifyContent : 'flex-start', alignItems : 'center' }}>
                            <Typography fontSize={'10px'} variant='subtitle2' component={'span'}>ASSIGNEE</Typography>
                            <Tooltip title={showAddAssigneeForm ? 'Close' : 'Add assignee'}>
                                <IconButton size='small' sx={{ marginLeft : '0.5rem' }} onClick={() => setShowAddAssigneeForm(!showAddAssigneeForm)}>
                                    {showAddAssigneeForm ? <CloseIcon fontSize='1px'/> : <AddIcon fontSize='1px'/>}
                                </IconButton>
                            </Tooltip>
                        </Box>
                        {showAddAssigneeForm && <Box width={'100%'} marginY={'1rem'} display={'flex'} alignItems={'center'}>
                            <Autocomplete
                                sx={{
                                    width : '50%',
                                    marginRight : '1rem'
                                }}
                                freeSolo
                                options={['John Doe', 'John Wick', 'Inday Suman']}
                                renderInput={(params) => <TextField {...params} label='Search' size='small' fullWidth color='info'/>}/>
                                <Button startIcon={<AddIcon/>} variant='contained' color='info'>ADD</Button>
                        </Box>}
                        <Box sx={{ display : 'flex', alignItems : 'center', width : '100%', marginTop : '0.3rem', }}>
                            <AvatarGroup max={3}>
                                <Avatar alt='A' sizes='0.1rem'/>
                                <Avatar alt='A'/>
                                <Avatar alt='A'/>
                                <Avatar alt='A'/>
                                <Avatar alt='A'/>
                            </AvatarGroup>
                        </Box>
                    </Box>
                    
                    <Box sx={{ display : 'flex', flexDirection : 'column', alignItems : 'flex-start', width : '100%' }}>
                        <Typography fontSize={'10px'} variant='subtitle2' component={'span'}>ADD COMMENT</Typography>
                        <Box sx={{ display : 'flex', alignItems : 'center', width : '100%', marginTop : '0.3rem', }}>
                            <TextField label={'Comment'} size='small' fullWidth color='secondary'/>
                            <Box marginLeft={'0.3rem'}>
                                <IconButton color='secondary'>
                                    <SendIcon/>
                                </IconButton>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                
            </Paper>}

            {/* MODALS */}

            

            {/* ADD DELIVERABLE MODAL */}

            <Modal open={(showForm)}  sx={{
                display : 'flex',
                justifyContent : 'center',
                width : '100%'
            }}>
                <Paper elevation={10} sx={{
                    width : '35%',
                    marginY : '10rem',
                    padding : '2rem'
                }}>
                    <Box sx={{
                        display : 'flex',
                        justifyContent : 'space-between',
                        alignItems : 'center'
                    }}>
                        <Typography variant='subtitle2' component={'p'}>{editDeliverable ? 'EDIT DELIVERABLE' : 'ADD DELIVERABLE'}</Typography>
                        <IconButton size='small' onClick={() => {
                            dispatch(resetFormState())
                            setShowSaveDeliverableModal(false)
                            setShowTacticalAndOperationalDetailModal(false)
                            setEditDeliverable(false)
                            setSelectedDeliverable(null)
                        }}>
                            <CloseIcon/>
                        </IconButton>
                    </Box>
                    <Box width={'100%'}>
                        <SaveDeliverableForm submitBtnText={editDeliverable ? 'UPDATE' : 'INSERT'} toEdit={editDeliverable} deliverableData={selectedDeliverable} clientInfoId={id} onSubmit={(values, toEdit) => {
                            if(toEdit){
                                dispatch(updateClientDeliverable({ data : values }))
                            }else{
                                dispatch(createClientDeliverable({ data : values }))
                            }
                        }}/>
                    </Box>
                </Paper>
            </Modal>

            {/* ADD TASKS MODAL */}
            <Modal open={showAddTasksModal}  sx={{
                display : 'flex',
                justifyContent : 'center',
                width : '100%'
            }}>
                <TasksWrapper deliverable={selectedDeliverable} onClose={() => {
                    setShowAddTasksModal(false)
                    setShowTacticalTasksTable(false)
                    setShowTacticalAndOperationalDetailModal(false)
                }}/>
            </Modal>

            {/* modal for viewing tactical and operational tasks */}
            <Modal open={(showTacticalAndOperationalDetailModal)} sx={{
                display : 'flex',
                justifyContent : 'center',
                alignItems : 'center'
            }}>
                <Paper sx={{ width : '90%', height : '90%', padding : '1rem' }}>
                    <Box sx={{
                        display : 'flex',
                        justifyContent : 'space-between',
                        alignItems : 'center',
                        paddingY : '2rem'
                    }}>
                        <Box>
                            <Typography variant='subtitle2' component={'p'} fontSize={'13px'} color={'GrayText'}>Deliverable:</Typography>
                            <Typography variant='subtitle2' component={'small'} fontSize={'14px'} color={'whitesmoke'}>{selectedDeliverable?.name}</Typography>
                        </Box>
                        <IconButton size='small' onClick={() => {
                            setShowTacticalAndOperationalDetailModal(false)
                            dispatch(resetFormState())
                        }}>
                            <CloseOutlinedIcon/>
                        </IconButton>
                    </Box>
                    <Divider/>
                    <DeliverableDetails deliverableId={selectedDeliverable?.id}/>
                </Paper>
            </Modal>

            {/* show operational form with modal when operationalId query string has a value */}
            <Modal open={showSaveOperationalForm && !!operationalTask}
                sx={{
                    display : 'flex',
                    justifyContent : 'center',
                    alignContent : 'center',
                    padding : '2rem',
                    height : 'auto'
                }}>
                    <Paper sx={{
                        width : '100%',
                        height : '100%',
                        paddingX : '2rem',
                        paddingTop : '3rem',
                        display : 'flex',
                        flexDirection : 'column',
                    }}>
                        <Stack sx={{
                            display : 'flex',
                            justifyContent : 'space-between',
                            alignItems : 'center',
                            flexDirection : 'row',
                            marginBottom : '1rem'
                        }}>
                            <Typography>TASK</Typography>
                            <Tooltip title='CLOSE'>
                                <IconButton onClick={() => {
                                    setShowSaveOperationalForm(false)
                                    if(queryParamHelper.getQueryParam('redirectTo', location.search)){
                                        navigate(`/${queryParamHelper.getQueryParam('redirectTo', location.search).value}`)
                                    }
                                }} size='small'>
                                    <CloseOutlinedIcon fontSize='1px'/>
                                </IconButton>
                            </Tooltip>
                        </Stack>
                        <Stack sx={{
                            width : '100%',
                            display : 'flex',
                            justifyContent : 'flex-start',
                            flexDirection : 'row',
                        }}>
                            <SaveOperationalForm
                                showHeader={false} 
                                toEdit={true} 
                                operationalData={operationalTask} 
                                submitBtnText={'SAVE'}
                                onSubmit={(values, toEdit) => {
                                    const data = { 
                                        id : operationalTask.id,
                                        task_desc : values['name'],
                                        tactical_task_id : operationalTask.tactical_task.id,
                                        stakeholder_id : !operationalTask.stakeholder?.id ? 0 : operationalTask.stakeholder?.id,
                                        planned_due_date : values['plannedDueDate'],
                                        planned_start_date : values['plannedStartDate'],
                                        actual_due_date : values['actualDueDate'],
                                        actual_start_date : values['actualStartDate'],
                                        task_status : values['taskStatus']
                                    }

                                    dispatch(updateOperational({ data }))
                                }}/>
                            <Stack sx={{
                                width : '100%',
                                marginLeft : '1rem',
                                height : '60%',
                            }}>
                                <CommentsAndAttachmentsTab 
                                    attachmentDir={`${operationalTask?.tactical_task.strategic_task.delivery.client_info.id}/${operationalTask?.tactical_task.strategic_task.delivery.id}/${operationalTask?.tactical_task.strategic_task.id}/${operationalTask?.tactical_task.id}/${operationalTask?.id}`}
                                    onAttach={(data) => {
                                        setIsOnAttaching(true)
                                        taskAttachmentService.createOperationalAttachment({ data : { operational_task : operationalIdQueryString.value, attachment_url : data['file'], attachment_desc : data['desc'] } })
                                            .then((res) => {
                                                setIsOnAttaching(false)
                                                setAttachments((prev) => [...prev, res.data])
                                            })
                                    }}
                                    isOnAttaching={isOnAttaching}
                                    attachments={attachments} 
                                    onDelete={(id) => {
                                        taskAttachmentService.deleteOperationalAttachment({ id })
                                            .then((res) => {
                                                setAttachments((prev) => prev.filter((attachment) => attachment.id !== id))
                                            })
                                            .catch((err)=> {})
                                    }}
                                    onDeleteComment={(comment) => {
                                        operationalCommentService.deleteComment({ id : comment.id })
                                            .then((res) => {
                                                dispatch(getAllOperationalComments({ operationalId : operationalIdQueryString.value }))
                                            })
                                    }}
                                    comments={comments?.map((comment) => ({ id : comment.id, stakeholder : (comment.operational_task.stakeholder == null) ? null : { name : comment.operational_task.stakeholder?.name,  }, text : comment.comment, user : comment.user, createdAt : comment.created_at }))} 
                                    status={status}
                                    onSubmitComment={(comment) => {
                                        dispatch(createOperationalComment({ data : { comment, operational_task_id : operationalIdQueryString.value } }))
                                    }}/>
                            </Stack>
                        </Stack>
                    </Paper>
            </Modal>

            <Modal open={clientInfoShowForm} onClose={() => dispatch(showFormToggle())}
                sx={{
                    display : 'flex',
                    justifyContent : 'center',
                    alignItems : 'center'
                }}>
                <Box 
                    sx={{
                        display : 'flex',
                        justifyContent : 'center',
                        alignItems : 'center',
                        width : '40%'
                    }}>
                    <Paper
                        sx={{
                            width : '100%',
                            padding : '3rem'
                        }}>
                        <SaveClientInfoForm clientInfoData={clientDetails} toEdit={true} onSubmit={(values) => {
                            dispatch(updateClient({ clientData : values }))
                        }}/>
                    </Paper>
                </Box>
            </Modal>
            <Modal
            open={isDeleteConfirmationOpen}
            onClose={() => { setDeleteConfirmationOpen(false) }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            closeAfterTransition
            >
                
                <Box sx={{position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '30vw',
                minHeight: '20vh',
                bgcolor: 'background.paper',
                /* border: '1px solid #582323', */
                /* borderRadius: '5%', */
                boxShadow: 24,
                p: 3,
                }}>
                    <h2 style={{ color: '#c71f1f',}}>Confirm Deletion</h2>
                    <p>Are you sure you want to delete this deliverable?</p>
                    <div>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Button
                                color="error"
                                style={{ 
                                flex: '1', 
                                maxWidth: '40%', 
                                margin: '0 2rem', 
                                whiteSpace: 'nowrap', 
                                overflow: 'hidden', 
                                textOverflow: 'ellipsis', 
                                border: '1px solid gray' 
                                }}
                                onClick={() => {
                                    dispatch(setDeliverableId(DeliverableIDData));
                                    dispatch(deleteClientDeliverable({ id: DeliverableIDData }));
                                    setDeleteConfirmationOpen(false);
                                }}
                            >
                                Delete
                            </Button>
                            <Button
                                style={{ 
                                flex: '1', 
                                maxWidth: '40%', 
                                margin: '0 2rem', 
                                whiteSpace: 'nowrap', 
                                overflow: 'hidden', 
                                textOverflow: 'ellipsis', 
                                border: '1px solid gray' 
                                }}
                                onClick={() => setDeleteConfirmationOpen(false)}
                            >
                                Cancel
                            </Button>
                        </div>
                    </div>
                </Box>
            </Modal>
        </Box>
    </Box>
  )
}


