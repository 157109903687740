
import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Avatar, AvatarGroup, Box, Button, CircularProgress, Collapse, Divider, Drawer, Grid, IconButton, ImageListItem, InputAdornment, List, ListItemButton, ListItemIcon, ListItemText, Modal, Paper, Stack, SwipeableDrawer, TextField, Tooltip, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import SendIcon from '@mui/icons-material/Send';
import AddIcon from '@mui/icons-material/Add';
import EditNoteIcon from '@mui/icons-material/EditNote';
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import React, { useState, useEffect } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import DeleteIcon from '@mui/icons-material/Delete';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { clients, strategicTasks } from '../../sampleData'
import { FilePicker } from '../../components/common/FilePicker';
import { DatePicker, DateTimeField } from '@mui/x-date-pickers';
import { DataGrid } from '@mui/x-data-grid';
import { KeyboardArrowDown, KeyboardArrowUp, SearchOutlined } from '@mui/icons-material';
import DeliverableDetails from '../../components/dashboard/clientdetails/DeliverableDetails';
import { AddTasksAndViewDeliverableDetails } from '../../components/dashboard/clientdetails/AddTasksAndViewDeliverableDetails';
import clientInformationService from '../../services/clientInformationService';
import { useDispatch, useSelector } from 'react-redux/es';
import { getAllDeliverablesByClient, createClientDeliverable, updateClientDeliverable, resetFormState, resetAlertState, showForm as deliverableShowForm, setDeliverableId, deleteClientDeliverable } from '../../features/deliverable/deliverableSlice';
import moment from 'moment';
import initialValues from '../../validators/deliverable/initialValues';
import saveDeliverableValidator from '../../validators/deliverable/saveDeliverableValidator';
import { InputField } from '../../components/common/InputField';
import saveDeliverable from '../../validators/deliverable/saveDeliverable';
import { SaveDeliverableForm } from '../../components/dashboard/clientdetails/forms/SaveDeliverableForm';
import { TaskSearchedResultContainer } from '../../components/dashboard/clientdetails/resultsearchedtask/TaskSearchedResultContainer';
import strategicService from '../../services/strategicService';
import queryParamHelper from '../../helpers/queryParamHelper';
import { SaveOperationalForm } from '../../components/dashboard/clientdetails/forms/SaveOperationalForm';
import operationalService from '../../services/operationalService';
import { updateOperational } from '../../features/operational/operationalSlice';
import { toast } from 'react-toastify';
import { TasksWrapper } from '../../components/dashboard/clientdetails/TasksWrapper';
import { SaveClientInfoForm } from '../../components/dashboard/clientdetails/forms/SaveClientInfoForm';
import { getClient, showFormToggle, showForm as clientInformationShowForm, resetAlertState as clientInforResetAlertState, updateClient } from '../../features/clientinformation/clientInformationSlice';
import { userRole } from '../../constants/userRole';
import { getReportingPeriod } from '../../helpers/taskStatusHelper';
import { Comment } from '../../components/task/Comment';
import { CommentsAndAttachmentsTab } from '../../components/task/CommentsAndAttachmentsTab';
import { createOperationalComment, getAllOperationalComments } from '../../features/operational-task-comment/operationalTaskCommentSlice';
import taskAttachmentService from '../../services/taskAttachmentService';
import operationalCommentService from '../../services/operationalCommentService';

export const OperationalTaskDetails = ({ operationalTask, panelHeight = '65vh' }) => {

    const { status, comments } = useSelector(state => state.operationalCommentSlice)
    const [attachments, setAttachments] = useState([])
    const [isOnAttaching, setIsOnAttaching] = useState(false)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getAllOperationalComments({ operationalId : operationalTask.id }))
        taskAttachmentService.getAllOperationalAttachments({ operationalId : operationalTask.id })
            .then((res) => setAttachments(res.data))
    }, [dispatch])
    return (
        <>
            <Stack sx={{
                width : '100%',
                display : 'flex',
                justifyContent : 'flex-start',
                flexDirection : 'row',
                alignItems : 'flex-start'
            }}>
                <SaveOperationalForm
                    showHeader={false} 
                    toEdit={true} 
                    operationalData={operationalTask} 
                    submitBtnText={'SAVE'}
                    onSubmit={(values, toEdit) => {
                        const data = { 
                            id : operationalTask.id,
                            task_desc : values['name'],
                            tactical_task_id : operationalTask.tactical_task.id,
                            stakeholder_id : !operationalTask.stakeholder?.id ? 0 : operationalTask.stakeholder?.id,
                            planned_due_date : values['plannedDueDate'],
                            planned_start_date : values['plannedStartDate'],
                            actual_due_date : values['actualDueDate'],
                            actual_start_date : values['actualStartDate'],
                            task_status : values['taskStatus']
                        }

                        console.log(values)
                        dispatch(updateOperational({ data }))
                    }}/>
                <Stack sx={{
                    width : '100%',
                    marginLeft : '1rem',
                }}>
                    <CommentsAndAttachmentsTab 
                        attachmentDir={`${operationalTask?.tactical_task.strategic_task.delivery.client_info.id}/${operationalTask?.tactical_task.strategic_task.delivery.id}/${operationalTask?.tactical_task.strategic_task.id}/${operationalTask?.tactical_task.id}/${operationalTask?.id}`}
                        panelHeight={panelHeight}
                        onAttach={(data) => {
                            setIsOnAttaching(true)
                            taskAttachmentService.createOperationalAttachment({ data : { operational_task : operationalTask.id, attachment_url : data['file'], attachment_desc : data['desc'] } })
                                .then((res) => {
                                    setIsOnAttaching(false)
                                    setAttachments((prev) => [...prev, res.data])
                                })
                        }}
                        isOnAttaching={isOnAttaching}
                        attachments={attachments} 
                        onDelete={(id) => {
                            taskAttachmentService.deleteOperationalAttachment({ id })
                                .then((res) => {
                                    setAttachments((prev) => prev.filter((attachment) => attachment.id !== id))
                                })
                                .catch((err)=> {})
                        }}
                        onDeleteComment={(comment) => {
                            operationalCommentService.deleteComment({ id : comment.id })
                                .then((res) => {
                                    dispatch(getAllOperationalComments({ operationalId : operationalTask.id }))
                                })
                        }}
                        comments={comments?.map((comment) => ({ id : comment.id, stakeholder : (comment.operational_task.stakeholder == null) ? null : { name : comment.operational_task.stakeholder?.name,  }, text : comment.comment, user : comment.user, createdAt : comment.created_at }))} 
                        status={status}
                        onSubmitComment={(comment) => {
                            dispatch(createOperationalComment({ data : { comment, operational_task_id : operationalTask.id } }))
                        }}/>
                </Stack>
            </Stack>
        </>
    )
}
