export const parseDate = (value, input) => {
    try {
        if(value === null || value['$d'] === null) return new Date("1900-01-01").toISOString()
        if(value !== "" && value['$d'] === undefined){
            return value
        }
        if(value === "" || value['$d'] === undefined){
            return new Date("1900-01-01").toISOString()
        }
        return value['$d']
    } catch (error) {
        return new Date("1900-01-01").toISOString()
    }
}