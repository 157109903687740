import axios from "axios"
import { apiUrl } from "../constants/urls"

const getDeliveryRisk = async ({ clientId = null }) => {
    try {
        const res = await axios.get(`${apiUrl}deliveryrisk${clientId ? `?client_id=${clientId}` : ''}`)
        return res
    } catch (error) {
        return error
    }
}

const deliveryriskService = {
    getDeliveryRisk
}

export default deliveryriskService