import axios from "axios"
import { apiUrl } from "../constants/urls"


const getOverallStatus = async ({ clientId = null }) => {
    try {
        const res = await axios.get(`${apiUrl}overallstatus${clientId ? `?client_id=${clientId}` : ''}`)
        return res
    } catch (error) {
        return error
    }
}

const overallstatusService = {
    getOverallStatus
}

export default overallstatusService