import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Box, Tab } from '@mui/material'
import React,{ useState } from 'react'
import { CommentList } from './CommentList'
import { WriteComment } from './WriteComment'
import { AttachmentList } from './AttachmentList'
import { AttachFile } from './AttachFile'
import fileUploadService from '../../services/fileUploadService'

export const CommentsAndAttachmentsTab = ({ comments, attachments, onSubmitComment, status, onAttach, isOnAttaching = false, onDelete, panelHeight = '65vh', onDeleteComment, attachmentDir }) => {

    const [value, setValue] = useState('1')
  return (
    <>
        <TabContext value={value} >
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList aria-label="lab API tabs example"  onChange={(event, val) => {
                    setValue(val)
                }}>
                    <Tab label="Comments" value="1" />
                    <Tab label="Attachments" value="2" />
                </TabList>
            </Box>
            <TabPanel value="1" sx={{
                height : panelHeight
            }}>
                <Box sx={{
                    height : '100%',
                    display : 'flex',
                    justifyContent : 'space-between',
                    flexDirection : 'column'
                }}>
                    <Box sx={{
                        height : '50vh',
                        overflow : 'scroll',
                        overflow: 'auto',
                        overflowY : 'auto',
                    }}>
                        <CommentList comments={comments} onDelete={onDeleteComment}/>
                    </Box>
                    <Box>
                        <WriteComment onSubmit={onSubmitComment} status={status}/>
                    </Box>
                </Box>
            </TabPanel>
            <TabPanel value="2" sx={{
                height : panelHeight
            }}>
                <Box sx={{
                    height : '100%',
                    display : 'flex',
                    justifyContent : 'space-between',
                    flexDirection : 'column-reverse'
                }}>
                    <Box sx={{
                        height : '50vh',
                        // overflow : 'auto',
                        // overflowX : 'hidden',
                        // overflowY : 'scroll',
                        marginTop : '1rem'
                    }}>
                        <AttachmentList attachments={attachments} onDelete={onDelete}/>
                    </Box>
                    <Box>
                        <AttachFile dir={attachmentDir} isOnAttach={isOnAttaching} onAttach={(data) => {
                            onAttach(data)
                        }}/>
                    </Box>
                </Box>
            </TabPanel>
        </TabContext>
    </>
  )
}
