import httpClient from "../config/axiosConfig"


const getAllComments = async ({ tacticalId }) => {
    try {
        const token = JSON.parse(localStorage.getItem('auth'))?.access_token
        const res = await httpClient.get(`tacticaltaskcomments/?tactical_id=${tacticalId}`, {
            headers : {
                Authorization : `Bearer ${token}`
            }
        })
        return res
    } catch (error) {
        throw new Error('Something went wrong')
    }
}

const createComment = async ({ data }) => {
    try {
        const token = JSON.parse(localStorage.getItem('auth'))?.access_token
        const res = httpClient.post('tacticaltaskcomments/', data, {
            headers : {
                Authorization : `Bearer ${token}`
            }
        })
        return data
    } catch (error) {
        throw new Error('Something went wrong')
    }
}

const deleteComment = async ({ id }) => {
    try {
        const token = JSON.parse(localStorage.getItem('auth'))?.access_token
        const res = httpClient.delete(`tacticaltaskcomments/${id}/`, {
            headers : {
                Authorization : `Bearer ${token}`
            }
        })
        return res
    } catch (error) {
        throw new Error('Something went wrong')
    }
}

const tacticaltaskCommentService = {
    getAllComments,
    createComment,
    deleteComment
}

export default tacticaltaskCommentService