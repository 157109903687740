import { Box, CircularProgress } from '@mui/material'
import React, { useState } from 'react'
import tasksService from '../../../services/tasksService'
import { SelectInput } from '../../common/SelectInput'

export const TaskStatus = ( { options, defaultValue, taskType, data, disabled = false } ) => {
    const [defaultStatus, setDefaultStatus] = useState(defaultValue)
    const [isLoading, setIsLoading] = useState(false)

    const updateTaskStatus = (status) => {
        setIsLoading(true)
        tasksService.updateStatus({
            data : {
                task_type : taskType,
                task_status : status,
                task_id : data.id
            }
        })
        .then((res) => {
            setIsLoading(false)
            setDefaultStatus(status)
        })
        .catch((err) => setIsLoading(false))
    }

  return (
    <Box sx={{
        display : 'flex',
        justifyContent : 'center',
        alignItems : 'center',
        width : '100%'
    }}>
        {(isLoading) ? <>
            <CircularProgress color='info' size={20}/>
        </> : <SelectInput 
            disbaled={disabled}
            options={options} 
            label={'Status'}
            defaultVal={defaultStatus}
            onChange={(value) => {
                // if(value === 0) return
                updateTaskStatus(value)
            }}
            />}
    </Box>
  )
}


