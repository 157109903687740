import axios from "axios"
import { apiUrl } from "../constants/urls"
import { handleUnauthorized } from "../features/auth/authSlice"
import httpClient from "../config/axiosConfig"



const getAll = async ({ tacticalId = null, assigneeId = null, dispatch }) => {
    try {
        const token = JSON.parse(localStorage.getItem('auth'))?.access_token
        const res = await axios.get(
            `${apiUrl}operationaltask${tacticalId ? `?tactical_task_id=${tacticalId}${assigneeId ? `&assignee_id=${assigneeId}` : ''}` : ''}`,
            { 
                headers : {
                    Authorization : `Bearer ${token}`
                }
            })
        return res
    } catch (error) {
        if (Number(error.response.status) === 401) {
            dispatch(handleUnauthorized())
        }
        return error
    }
}

const getAllOngoing = async ({ client }) => {
    try {
        const token = JSON.parse(localStorage.getItem('auth'))?.access_token
        const res = await httpClient.get(`operationaltask/ongoing?project=${client}`, {
            headers : {
                Authorization : `Bearer ${token}`
            }
        })
        return res
    } catch (error) {
        return error
    }
}

const getAllAssignedToMe = async ({ assigneeId = null, dispatch }) => {
    try {
        const token = JSON.parse(localStorage.getItem('auth'))?.access_token
        const res = await axios.get(
            `${apiUrl}operationaltask/assigned-to-me${assigneeId ? `?assignee_id=${assigneeId}` : ''}`,
            { 
                headers : {
                    Authorization : `Bearer ${token}`
                }
            })
        return res
    } catch (error) {

        if (Number(error.response.status) === 401) {
            dispatch(handleUnauthorized())
        }
        return error
    }
}

const create = async ({ data, dispatch }) => {
    try {
        const token = JSON.parse(localStorage.getItem('auth'))?.access_token
        const res = await axios.post(`${apiUrl}operationaltask/`, data, {
            headers : {
                Authorization : `Bearer ${token}`
            }
        })
        return res
    } catch (error) {
        if (Number(error.response.status) === 401) {
            dispatch(handleUnauthorized())
        }
        return error
    }
} 

const update = async ({ updatedData, dispatch }) => {
    try {
        const token = JSON.parse(localStorage.getItem('auth'))?.access_token
        const res = await axios.put(`${apiUrl}operationaltask/update/`, updatedData, {
            headers : {
                Authorization : `Bearer ${token}`
            }
        })
        return res
    } catch (error) {

        if (Number(error.response.status) === 401) {
            dispatch(handleUnauthorized())
        }
        return error
    }
}

const assignStakeholder = async ({ data, dispatch }) => {
    try {
        const token = JSON.parse(localStorage.getItem('auth'))?.access_token
        const res = await axios.put(
            `${apiUrl}operationaltask/assign/`, 
            data,
            {
                headers : {
                    Authorization : `Bearer ${token}`
                }
            })
        return res
    } catch (error) {

        if (Number(error.response.status) === 401) {
            dispatch(handleUnauthorized())
        }
        return error
    }
}

const getOne = async ({ id, dispatch }) => {
    try {
        const token = JSON.parse(localStorage.getItem('auth'))?.access_token
        const res = await axios.get(`${apiUrl}operationaltask/${id}`,
        {
            headers : {
                Authorization : `Bearer ${token}`
            }
        })
        return res
    } catch (error) {

        if (Number(error.response.status) === 401) {
            dispatch(handleUnauthorized())
        }
        
        return error
    }
}

const deleteOne = async ({ id }) => {
    try {
        const token = JSON.parse(localStorage.getItem('auth'))?.access_token
        const res = await httpClient.delete(`operationaltask/delete/${id}/`,{
            headers : {
                Authorization : `Bearer ${token}`
            }
        })

        return res
    } catch (error) {
        return error
    }
}

const operationalService = {
    getAll,
    create,
    update,
    assignStakeholder,
    getOne,
    getAllAssignedToMe,
    deleteOne,
    getAllOngoing
}

export default operationalService;