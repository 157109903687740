import saveClientInformation from "./saveClientInformation";

const {
    formField : {
        name,
        accountManager,
        missionStatement,
        premium,
        reportingPeriod,
        securityChampion,
        startDate,
        secopsSME,
        customerSuccessRep
    }
} = saveClientInformation


export default {
    [name.name] : '',
    [missionStatement.name] : '',
    [accountManager.name] : '',
    [premium.name] : false,
    [reportingPeriod.name] : '',
    [securityChampion.name] : '',
    [startDate.name] : '',
    [secopsSME.name] : '',
    [customerSuccessRep.name] : ''
}