import { Avatar, Box, Chip, InputAdornment, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAssignedToMe } from '../../features/operational/operationalSlice'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { getTaskStatus } from '../../helpers/taskStatusHelper'
import { Search } from '@mui/icons-material'
import { useState } from 'react'

export const OperationalTaskTable = ({ operationals, onClick, showSearchBar = true }) => {

    const [source, setSource] = useState([])
    const [search, setSearch] = useState('')
    useEffect(() => {
        if(search.length < 1){
            setSource(operationals)
            console.log('re-rendered')   
        }
    }, [search])
  return (
    <>
        {showSearchBar && <TextField
            color='info' 
            placeholder='Search task...' 
            size='small' 
            variant='standard' 
            sx={{ width : '30vh' }}
            InputProps={{
                startAdornment : (
                    <InputAdornment position='start'>
                        <Search color='info'/>
                    </InputAdornment>
                )
            }}
            onChange={(event) => {
                setSearch(event.currentTarget.value)
                setSource([...operationals.filter(x => String(x.task_desc).toLowerCase().includes(String(event.currentTarget.value).toLowerCase()))])
            }}/>}
        <TableContainer>
            <Table>
            <TableHead>
                <TableRow>
                <TableCell>Description</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Planned Start Date</TableCell>
                <TableCell>Planned Due Date</TableCell>
                <TableCell>Actual Start Date</TableCell>
                <TableCell>Actual Due Date</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {source.map((operational) => (
                <TableRow key={operational.id} sx={{
                    ":hover" : {
                    cursor : 'pointer',
                    backgroundColor : '#222831',
                    transition : 'all 0.3s ease',
                    borderRadius : '0.5rem',
                    fontWeight : '500'
                    }
                }}
                onClick={() => {
                    onClick(operational)
                    // navigate(`/client-details/${operational.tactical_task.strategic_task.delivery.client_info.id}?operationalId=${operational.id}&redirectTo=assigned-me`)
                }}>
                    <TableCell>
                    <Stack direction={'row'} spacing={3} alignItems={'center'}>
                        <Typography fontSize={'14px'}>{operational.task_desc}</Typography>
                        <Tooltip title='Client'>
                        <Chip color='info' size='small' avatar={<Avatar>{String(operational.tactical_task.strategic_task.delivery.client_info.name).charAt(0)}</Avatar>} label={operational.tactical_task.strategic_task.delivery.client_info.name}/>
                        </Tooltip>
                    </Stack>
                    </TableCell>
                    <TableCell>{getTaskStatus(operational.task_status)}</TableCell>
                    <TableCell>{moment(operational.planned_start_date).format('DD-MM-YYYY') === "01-01-1900" ? "-" : moment(operational.planned_start_date).format('MMMM DD YYYY')}</TableCell>
                    <TableCell>{moment(operational.planned_due_date).format('MMMM DD YYYY')}</TableCell>
                    <TableCell>{moment(operational.actual_start_date).format('DD-MM-YYYY') === "01-01-1900" ? "-" : moment(operational.actual_start_date).format('MMMM DD YYYY')}</TableCell>
                    <TableCell>{moment(operational.actual_due_date).format('DD-MM-YYYY') === "01-01-1900" ? "-" : moment(operational.actual_due_date).format('MMMM DD YYYY')}</TableCell>
                </TableRow>
                ))}
            </TableBody>
            </Table>
        </TableContainer>
    </>
  )
}
