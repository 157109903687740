import { Avatar, Divider, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material'
import React from 'react'
import { Comment } from './Comment'
import STATE_STATUS from '../../constants/status'

export const CommentList = ({ comments, status, onDelete }) => {
  return (
    <>
        <List sx={{ width: '100%'}}>
            {status === STATE_STATUS.LOADING ? <></> :
            <>
                {comments?.length > 0 ? <>
                    {comments.map((comment) => (
                        <Comment key={comment.id} comment={comment} onDelete={onDelete}/>
                    ))}
                </> : <>No data</>}
            </>}
        </List>
    </>
  )
}

