import { Close } from '@mui/icons-material'
import { IconButton, Paper, Stack, Typography } from '@mui/material'
import React from 'react'

export const TaskformWrapper = ({ onClose, title, children }) => {
  return (
    <>
        <Paper elevation={2} sx={{
            padding : '1rem',
        }}>
            <Stack sx={{
                display : 'flex',
                flexDirection : 'row',
                alignItems : 'center',
                justifyContent : 'space-between',
                marginBottom : '1rem'
            }}>
                <Typography>{title}</Typography>
                <IconButton size='small' onClick={onClose}>
                    <Close fontSize='small'/>
                </IconButton>
            </Stack>
            <Stack>
                {children}
            </Stack>
        </Paper>
    </>
  )
}
