import React from 'react'
import { Box, Card, CardContent, CardHeader, CircularProgress, IconButton, Typography } from '@mui/material'
import ViewHeadlineIcon from '@mui/icons-material/ViewHeadline';
import ApexCharts from 'react-apexcharts'
import moment from 'moment';

var options = {
  series: [
  {
    data: [
      {
        x: 'PCB',
        y: [
          new Date('2019-02-27').getTime(),
          new Date('2019-03-04').getTime()
        ],
        fillColor: '#008FFB'
      },
      {
        x: 'Selina',
        y: [
          new Date('2019-03-04').getTime(),
          new Date('2019-03-08').getTime()
        ],
        fillColor: '#00E396'
      },
      {
        x: 'Zoominfo',
        y: [
          new Date('2019-03-07').getTime(),
          new Date('2019-03-10').getTime()
        ],
        fillColor: '#775DD0'
      },
      // {
      //   x: 'Testing',
      //   y: [
      //     new Date('2019-03-08').getTime(),
      //     new Date('2019-03-12').getTime()
      //   ],
      //   fillColor: '#FEB019'
      // },
      // {
      //   x: 'Deployment',
      //   y: [
      //     new Date('2019-03-12').getTime(),
      //     new Date('2019-03-17').getTime()
      //   ],
      //   fillColor: '#FF4560'
      // }
    ]
  }
],
  chart: {
    height: 350,
    type: 'rangeBar',
    toolbar: {

      show: false,

      },
  },
  plotOptions: {
    bar: {
      horizontal: true,
      distributed: true,
      dataLabels: {
        hideOverflowingLabels: false
      }
    }
  },
  dataLabels: {
    enabled: true,
    formatter: function(val, opts) {
      var label = opts.w.globals.labels[opts.dataPointIndex]
      var a = moment(val[0])
      var b = moment(val[1])
      var diff = b.diff(a, 'days')
      return label + ': ' + diff + (diff > 1 ? ' days' : ' day')
    },
    style: {
      colors: ['#f3f4f5', '#fff']
    }
  },
  xaxis: {
    type: 'datetime'
  },
  yaxis: {
    show: false
  },
  grid: {
    row: {
      colors: ['#f3f4f5', '#fff'],
      opacity: 1
    }
  }
};
export const Timeline = ({ onShowClients }) => {
  return (
    <Box display={'flex'} width={'100%'} marginLeft={'1rem'}>
                
        <Card sx={{
            borderRadius: '1rem',
            width : '100%',
            minHeight : '20rem',
        }}>
            <CardHeader 
                title={'TIMELINE'}
                action={
                <IconButton onClick={() => onShowClients()}>
                    <ViewHeadlineIcon/>
                </IconButton>
                }
                />
            <CardContent sx={{
                display : 'flex',
                justifyContent : 'center',
                alignItems : 'center',
            }}>
                {/* <LineChart width={880} height={260} data={data} >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey="pv" stroke="#8884d8" />
                    <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
                </LineChart> */}
                <Box width={'100%'}>
                    <ApexCharts series={options.series} options={options} width={1160} height={330} type='rangeBar'/>
                </Box>
            </CardContent>
        </Card>
    </Box>
  )
}
