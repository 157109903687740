export default {
    formId : 'saveDeliverable',
    formField : {
        name : {
            name : 'name',
            label : 'Name',
            requiredErrorMsg : 'Client name is required'
        },
        startDate : {
            name : 'startDate',
            label : 'Start Date',
        },
        missionStatement : {
            name : 'missionStatement',
            label : 'Mission Statement',
            requiredErrorMsg : 'Mission statement is required'
        },
        accountManager : {
            name : 'accountManager',
            label : 'Account Manager',
        },
        securityChampion : {
            name : 'securityChampion',
            label : 'Security Champion',
        },
        premium : {
            name : 'premium',
            label : 'Premium',
        },
        reportingPeriod : {
            name : 'reportingPeriod',
            label : 'Reporting Period',
        },
        secopsSME : {
            name : 'secopsSME'
        },
        customerSuccessRep : {
            name : 'customerSuccessRep'
        }
    }
}