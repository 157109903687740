
const taskStatuses = [
    {
        key : 'NT',
        value : 'Not Yet Started',
        percent : 0
    },
    {
        key : 'ST',
        value : 'Started',
        percent : 0
    },
    {
        key : 'IP',
        value : 'In Progress',
        percent : 50
    },
    {
        key : 'SP',
        value : 'Stopped',
        percent : 0
    },
    {
        key : 'RS',
        value : 'Restarted',
        percent : 0
    },
    {
        key : 'DN',
        value : 'Done',
        percent : 100
    }
]


export const getTaskStatus = (key) => {
    const taskStatus = taskStatuses.find((taskStatus) => taskStatus.key === key)
    return taskStatus.value
}

const reportingPeriods = [
    {
        key : 'DY',
        value : 'Daily'
    },
    {
        key : 'WY',
        value : 'Weekly'
    },
    {
        key : '2M',
        value : '2x Monthly'
    },
    {
        key : 'MY',
        value : 'Monthly'
    },
    {
        key : 'QY',
        value : 'Quarterly'
    },
    {
        key : 'SA',
        value : 'Semi-Annual'
    },
    {
        key : 'YY',
        value : 'Yearly'
    }
]


export const getReportingPeriod = (key) => {
    const reportingPeriod = reportingPeriods.find((reportingPeriod) => reportingPeriod.key === key)
    console.log(reportingPeriod)
    return reportingPeriod
}

export const tooltip = 'Not Yet Started: The task has not been started yet.\nIn Progress: The task is currently active and being worked on.\nStopped: The task has been halted before completion.\nDone: The task has been completed successfully.'