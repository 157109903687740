
const IDLE = 'idle'
const LOADING = 'loading'
const SUCCESS = 'success'
const FAILED = 'failed'
const STATE_STATUS = {
    IDLE,
    FAILED,
    LOADING,
    LOADING,
    SUCCESS
}

export default STATE_STATUS