import axios from "axios";
import { apiUrl } from "../constants/urls";
import { handleUnauthorized } from "../features/auth/authSlice";
import httpClient from "../config/axiosConfig";



const getAllByClientId = async ({ clientId = 0, dispatch }) => {
    try {
        const token = JSON.parse(localStorage.getItem('auth'))?.access_token
        const res = await axios.get(`${apiUrl}delivery${clientId > 0 ? `?client_info_id=${Number(clientId)}` : ''}`, {
            headers : {
                Authorization : `Bearer ${token}`,
            }
        })
        return res
    } catch (error) {

        if (Number(error.response.status) === 401) {
            dispatch(handleUnauthorized())
        }
        return error
    }
}

const create = async ({ data, dispatch }) => {
    try {
        const token = JSON.parse(localStorage.getItem('auth'))?.access_token
        const res = await axios.post(`${apiUrl}delivery/`, data, {
            headers : {
                Authorization : `Bearer ${token}`,
            }
        })
        return res
    } catch (error) {
        if (Number(error.response.status) === 401) {
            dispatch(handleUnauthorized())
        }
        return error
    }
}

const update = async ({ updatedData, dispatch }) => {
    try {
        const token = JSON.parse(localStorage.getItem('auth'))?.access_token
        const res = await axios.put(`${apiUrl}delivery/update/`, updatedData, {
            headers : {
                Authorization : `Bearer ${token}`,
            }
        })
        return res
    } catch (error) {

        if (Number(error.response.status) === 401) {
            dispatch(handleUnauthorized())
        }
        return error
    }
}

const deleteById = async ({ id }) => {
    try {
        const token = JSON.parse(localStorage.getItem('auth'))?.access_token
        const res = await httpClient.delete(`delivery/delete/${id}/`, {
            headers : {
                Authorization : `Bearer ${token}`,
            }
        })
        return res
    } catch (error) {
        return error
    }
}


const deliverableService = {
    getAllByClientId,
    create,
    update,
    deleteById
}


export default deliverableService