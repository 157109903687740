import { Form, Formik } from 'formik'
import React from 'react'
import initialValues from '../../../../validators/deliverable/initialValues'
import saveDeliverableValidator from '../../../../validators/deliverable/saveDeliverableValidator'
import { Box, Button, CircularProgress, Typography } from '@mui/material'
import { InputField } from '../../../common/InputField'
import { DatePickerField } from '../../../common/DatePickerField'
import saveDeliverable from '../../../../validators/deliverable/saveDeliverable'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { parseDate } from '../../../../helpers/dateformatter'

export const SaveDeliverableForm = ({ showHeader = true, onClose, toEdit = false, deliverableData, submitBtnText = 'INSERT', onSubmit, clientInfoId }) => {

    const { isLoading, isSuccess, isError, deliverables } = useSelector(state => state.deliverable)
    const { formId, formField : { name, plannedDueDate, plannedStartDate, actualDueDate, actualStartDate } } = saveDeliverable

  return (
    <Formik
        initialValues={toEdit ? {
            [name.name] : deliverableData.name,
            [plannedStartDate.name] : String(deliverableData.planned_start_date).includes('1900') ? "" : deliverableData.planned_start_date,
            [plannedDueDate.name] : deliverableData.planned_due_date,
            [actualStartDate.name] : String(deliverableData.actual_start_date).includes('1900') ? "" : deliverableData.actual_start_date,
            [actualDueDate.name] : String(deliverableData.actual_due_date).includes('1900') ? "" : deliverableData.actual_due_date
        } : initialValues}
        validationSchema={saveDeliverableValidator}
        onSubmit={(event) => {
            // console.log(moment(event.plannedDueDate['$d']).format('YYYY-MM-DD'))
            // console.log(moment(dateFormatter(event['plannedStartDate'])).format('YYYY-MM-DD'))
            const { id } = deliverableData ? deliverableData : { id : 0 }
            // const data = {
            //     id,
            //     client_info_id : Number(clientInfoId),
            //     name : event.name,
            //     planned_start_date : toEdit ? moment((event.plannedStartDate['$d'] === undefined) ? event[plannedStartDate.name] : (event.plannedStartDate['$d'] === "" ? new Date("1900-01-01").toISOString() : event.plannedStartDate['$d'])).format('YYYY-MM-DD') : moment((event[plannedStartDate.name] === '') ? new Date("1900-01-01").toISOString() : event.plannedStartDate['$d']).format('YYYY-MM-DD'),
            //     planned_due_date : toEdit ? moment((event.plannedDueDate['$d'] === undefined) ? event[plannedDueDate.name] : event[plannedDueDate.name]['$d']).format('YYYY-MM-DD') : moment((event[plannedDueDate.name] === '') ? new Date().toISOString() : event[plannedDueDate.name]['$d']).format('YYYY-MM-DD'),
            //     actual_start_date : toEdit ? moment((event.actualStartDate['$d'] === undefined) ? event[actualStartDate.name] : event[actualStartDate.name]['$d']).format('YYYY-MM-DD') : moment((event.actualStartDate === '') ? new Date("1900-01-01").toISOString() : event[actualStartDate.name]['$d']).format('YYYY-MM-DD'),
            //     actual_due_date : toEdit ? moment((event.actualDueDate['$d'] === undefined) ? event[actualDueDate.name] : event.actualDueDate['$d']).format('YYYY-MM-DD') : moment((event.actualDueDate === '') ? new Date("1900-01-01").toISOString() : event.actualDueDate['$d']).format('YYYY-MM-DD')
            // }
            const data = {
                id,
                client_info_id : Number(clientInfoId),
                name : event.name,
                planned_start_date : moment(parseDate(event['plannedStartDate'])).format('YYYY-MM-DD'),
                planned_due_date : moment(parseDate(event[plannedDueDate.name], plannedDueDate.name)).format('YYYY-MM-DD'),
                actual_start_date : moment(parseDate(event[actualStartDate.name])).format('YYYY-MM-DD'),
                actual_due_date : moment(parseDate(event[actualDueDate.name])).format('YYYY-MM-DD')
            }
            onSubmit(data, toEdit)
            // dispatch(createClientDeliverable({ data }))
        }}
        >
            {({ isSubmitting }) => (
                <Form id={formId}>
                    <Box sx={{
                        display : 'flex',
                        flexDirection : 'column',
                        alignItems : 'center',
                        marginTop : '2rem',
                        width : '100%'
                    }}>
                        <Box width={'100%'}>
                            <InputField name={name.name} label='Name' color='info' fullWidth/>
                        </Box>
                        <Box width={'100%'} marginTop={'1rem'}>
                            <DatePickerField name={plannedStartDate.name} label='Plan Start Date'/>
                        </Box>
                        <Box width={'100%'} marginTop={'1rem'}>
                            <DatePickerField name={plannedDueDate.name} label={'Plan Due Date'}/>
                        </Box>
                        <Box width={'100%'} marginTop={'1rem'}>
                            <DatePickerField name={actualStartDate.name}  label='Actual Start Date' />
                        </Box>
                        <Box width={'100%'} marginTop={'1rem'}>
                            <DatePickerField name={actualDueDate.name} label='Actual Due Date'/>
                        </Box>
                        <Box width={'100%'} marginTop={'1rem'}>
                            <Button type='submit' color='info' fullWidth variant='outlined' size='large' sx={{
                                display : 'flex',
                                justifyContent : 'center',
                                alignItems : 'center'
                            }}
                            disabled={isLoading}
                            >
                                { (isLoading) && <CircularProgress color='info' size={15} sx={{ marginRight : '0.5rem' }}/> }
                                <Typography variant='subtitle2' component={'p'}>{submitBtnText}</Typography>
                            </Button>
                        </Box>
                    </Box>
                </Form>
            )}
    </Formik>
  )
}
