import axios from "axios";
import { apiUrl } from '../constants/urls'
import { handleUnauthorized } from "../features/auth/authSlice";
import httpClient from "../config/axiosConfig";


const create = async ( { data, dispatch } ) => {
    try{
        const token = JSON.parse(localStorage.getItem('auth'))?.access_token
        const res = axios.post(`${apiUrl}strategictask/`, data, {
            headers : {
                Authorization : `Bearer ${token}`
            }
        })
        return res
    }catch(error){
        if (Number(error.response.status) === 401) {
            dispatch(handleUnauthorized())
        }
        return error
    }
}

const getAllByDeliverableId = async ( { deliverableId  = null, dispatch } ) => {
    try{
        const token = JSON.parse(localStorage.getItem('auth'))?.access_token
        const res = await axios.get(`${apiUrl}strategictask?deliverable_id=${deliverableId}`, {
            headers : {
                Authorization : `Bearer ${token}`
            }
        })
        return res
    }catch(error){
        if (Number(error.response.status) === 401) {
            dispatch(handleUnauthorized())
        }
        return error
    }
}

const update = async ({ updatedData, dispatch }) => {
    try {
        const token = JSON.parse(localStorage.getItem('auth'))?.access_token
        const res = await axios.put(`${apiUrl}strategictask/update/`, updatedData, {
            headers : {
                Authorization : `Bearer ${token}`
            }
        })
        console.log(res)
        return res
    } catch (error) {

        if (Number(error.response.status) === 401) {
            dispatch(handleUnauthorized())
        }

        return error
    }
}

const searchTasks = async ({ query, dispatch, client = null }) => {
    try {
        // to do: check client parameter if undifined.
        // if client is undifined or null this should not be inlcuded to request parameter
        const token = JSON.parse(localStorage.getItem('auth'))?.access_token
        const res = await httpClient.get(`strategictask/search?query_search=${query}${client ? '&client=' + client : ''}`, {
            headers : {
                Authorization : `Bearer ${token}`
            }
        })
        return res
    } catch (error) {

        if (Number(error.response.status) === 401) {
            dispatch(handleUnauthorized())
        }

        return error
    }
}

const deleteById = async ({ id }) => {
    try {
        const token = JSON.parse(localStorage.getItem('auth'))?.access_token
        const res = await httpClient.delete(`strategictask/delete/${id}/`, {
            headers : {
                Authorization : `Bearer ${token}`
            }
        })
        return res
    } catch (error) {
        return error
    }
}


const strategicService = {
    create,
    getAllByDeliverableId,
    update,
    searchTasks,
    deleteById
}



export default strategicService